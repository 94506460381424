import { FC, useEffect, useMemo, useState } from 'react';
import { Box, ButtonBase, CircularProgress, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloudFileView from '@/views/Projects/components/ProjectFormDialog/components/CloudFileView';
import nextTick from '@/services/nextTick';
import Dialog from '@/components/Dialog/Dialog';
import {
  AutodeskFile,
  useGetAutodeskFiles,
  useGetAutodeskHubs,
  useGetAutodeskProjects,
  useGetMicrosoftFiles,
  useGetSharepointSites,
} from '@/api/generated';
import { DriveNode, DriveFileType, OneDriveFile } from '@/types';
import AutodeskIcon from '@/assets/autodesk.svg?react';
import Icon from '@/components/Icon/Icon';
import { CloudFilesDialogErrorCallback } from '@/contexts/CloudFilesDialogContext';
import { useMicrosoftPicker } from '@/views/Projects/components/ProjectFormDialog/hooks/useMicrosoftPicker';
import DialogHeader from '@/components/Dialog/DialogHeader';
import DialogCloseButton from '@/components/Dialog/DialogCloseButton';

export type DefaultParams = {
  folderId?: string;
  sharePointSiteId?: string;
  hubId?: string;
  projectId?: string;
};

interface CloudFilesDialogProps {
  defaultParams?: DefaultParams;
  driveFilesType: 'none' | DriveFileType;
  onNodesSelect: (node: DriveNode[]) => void;
  onClose: () => void;
  onError: CloudFilesDialogErrorCallback;
}

const STALE_TIME = 60_1000;
const ROOT_FOLDER = 'None';

const CloudFilesDialog: FC<CloudFilesDialogProps> = ({ defaultParams, driveFilesType, onNodesSelect, onClose, onError }) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();
  const isOpened = driveFilesType !== 'none';
  const {
    folderId: defaultFolderId = ROOT_FOLDER,
    sharePointSiteId: defaultSharePointSiteId,
    hubId: defaultHubId,
    projectId: defaultProjectId,
  } = defaultParams ?? {};
  const [sharePointSiteId, setSharePointSiteId] = useState<string | undefined>(defaultSharePointSiteId);
  const [hubId, setHubId] = useState<string | undefined>(defaultHubId);
  const [projectId, setProjectId] = useState<string | undefined>(defaultProjectId);
  const [folderId, setFolderId] = useState(defaultFolderId);
  const [breadcrumbs, setBreadcrumbs] = useState<DriveNode[]>([]);

  const { openPicker: openMicrosoftPicker } = useMicrosoftPicker({
    onFilesSelect: files => onNodesSelect(files.map(file => ({ id: file.id, name: file.name, isFolder: false, native: file }))),
    onError,
  });

  const {
    data: sharePointSites,
    isLoading: isSharePointSitesLoading,
    error: sharepointSitesError,
  } = useGetSharepointSites({
    query: { enabled: driveFilesType === 'sharepoint', staleTime: STALE_TIME },
  });

  useEffect(() => {
    if (sharePointSites?.length === 1) {
      setSharePointSiteId(sharePointSites[0].id);
    }
  }, [sharePointSites]);

  const {
    data: oneDriveFiles,
    isLoading: isOneDriveFilesLoading,
    error: microsoftError,
  } = useGetMicrosoftFiles<OneDriveFile[] | undefined>(
    { folder_id: folderId === ROOT_FOLDER ? undefined : folderId },
    { query: { enabled: driveFilesType === 'onedrive', staleTime: STALE_TIME } },
  );

  const {
    data: autodeskHubs,
    isLoading: isHubsLoading,
    error: hubsError,
  } = useGetAutodeskHubs<AutodeskFile[]>({ query: { enabled: driveFilesType === 'autodesk', staleTime: STALE_TIME } });

  useEffect(() => {
    if (autodeskHubs?.length === 1) {
      setHubId(autodeskHubs[0].id);
    }
  }, [autodeskHubs]);

  const {
    data: autodeskProjects,
    isLoading: isProjectsLoading,
    error: projectsError,
  } = useGetAutodeskProjects<AutodeskFile[]>(
    { hub_id: hubId! },
    { query: { enabled: driveFilesType === 'autodesk' && !!hubId, staleTime: STALE_TIME } },
  );

  useEffect(() => {
    if (autodeskProjects?.length === 1) {
      setProjectId(autodeskProjects[0].id);
    }
  }, [autodeskProjects]);

  const {
    data: autodeskFiles,
    isLoading: isAutodeskFilesLoading,
    error: autodeskError,
  } = useGetAutodeskFiles<AutodeskFile[] | undefined>(
    { hub_id: hubId!, project_id: projectId!, folder_id: folderId === ROOT_FOLDER ? undefined : folderId },
    { query: { enabled: driveFilesType === 'autodesk' && !!hubId && !!projectId, staleTime: STALE_TIME } },
  );

  useEffect(() => {
    if (!isOpened) {
      nextTick(() => {
        setFolderId(ROOT_FOLDER);
        setBreadcrumbs([]);
      });
    }
  }, [isOpened]);

  useEffect(() => {
    const error = microsoftError || hubsError || projectsError || autodeskError || sharepointSitesError;
    if (error && driveFilesType !== 'none') onError({ error, type: driveFilesType, folderId, hubId, projectId });
  }, [driveFilesType, microsoftError, hubsError, projectsError, autodeskError, sharepointSitesError]);

  const autodeskDriveFiles =
    !hubId && autodeskHubs ? autodeskHubs : !projectId && autodeskProjects ? autodeskProjects : autodeskFiles;

  const nodes = useMemo(() => {
    if (driveFilesType === 'none' || driveFilesType === 'google') return [];

    const filesMap = {
      onedrive: oneDriveFiles,
      autodesk: autodeskDriveFiles,
    };
    const transformerMap = {
      onedrive: (native: OneDriveFile) => ({ id: native.id, name: native.name, isFolder: !!native.folder, native }),
      autodesk: (native: AutodeskFile) => ({
        id: native.id,
        name: native.name,
        isFolder: native.type === 'folders',
        native: { ...native, project_id: projectId! },
      }),
    };
    // @ts-expect-error
    return (filesMap[driveFilesType] ?? []).map(file => transformerMap[driveFilesType](file));
  }, [driveFilesType, oneDriveFiles, autodeskDriveFiles]) as DriveNode[];

  const isLoading = useMemo(() => {
    if (driveFilesType === 'onedrive') return isOneDriveFilesLoading;
    return isHubsLoading || isProjectsLoading || isAutodeskFilesLoading;
  }, [driveFilesType, isHubsLoading, isProjectsLoading, isAutodeskFilesLoading, isOneDriveFilesLoading]);

  const onBreadcrumbRootClick = () => {
    setFolderId(ROOT_FOLDER);
    setBreadcrumbs([]);
    setHubId(undefined);
    setProjectId(undefined);
  };

  const onBreadcrumbClick = (index: number) => {
    if (index === 0 && driveFilesType === 'sharepoint') {
      setFolderId(ROOT_FOLDER);
    } else if (index === 0 && driveFilesType === 'autodesk') {
      setFolderId(ROOT_FOLDER);
      setHubId(undefined);
      setProjectId(undefined);
    } else if (index === 1 && driveFilesType === 'autodesk') {
      setFolderId(ROOT_FOLDER);
      setProjectId(undefined);
    } else {
      const nextFolder = breadcrumbs[index];
      setFolderId(nextFolder.id);
    }

    setBreadcrumbs(breadcrumbs.slice(0, index + 1));
  };

  const onNodeClick = (node: DriveNode) => {
    if (!node.isFolder) return onNodesSelect([node]);

    if (driveFilesType === 'sharepoint' && !sharePointSiteId) {
      setSharePointSiteId(node.id);
    } else if (driveFilesType === 'autodesk' && !hubId) {
      setHubId(node.id);
    } else if (driveFilesType === 'autodesk' && !projectId) {
      setProjectId(node.id);
    } else {
      setFolderId(node.id);
    }

    setBreadcrumbs(prevBreadcrumbs => [...prevBreadcrumbs, node]);
  };

  if (driveFilesType === 'sharepoint') {
    return (
      <Dialog open={isOpened} width={250}>
        <DialogHeader title={t('uploadFiles.selectSiteDialog.title')} />
        <DialogCloseButton positioned onClick={() => onClose()} />
        {isSharePointSitesLoading ? (
          <CircularProgress sx={{ mt: 3, mx: 'auto' }} />
        ) : (
          <Stack sx={{ alignItems: 'flex-start' }}>
            {sharePointSites?.map(site => (
              <ButtonBase
                key={site.id}
                sx={{ p: 0.5, borderRadius: 1 }}
                onClick={() => openMicrosoftPicker('sharepoint', { site })}
              >
                {site.display_name}
              </ButtonBase>
            ))}
          </Stack>
        )}
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <Stack sx={{ gap: 1, height: 300 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 'bold' }}>
          {driveFilesType === 'autodesk' && <AutodeskIcon style={{ width: 50 }} />}
          {driveFilesType === 'autodesk' && t('uploadFiles.cloudsUpload.autodesk')}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center',
            fontSize: 'body3.fontSize',
            lineHeight: 'body3.lineHeight',
          }}
        >
          <ButtonBase
            sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontSize: 'inherit', lineHeight: 'inherit' }}
            disabled={breadcrumbs.length === 0}
            onClick={() => onBreadcrumbRootClick()}
          >
            <Icon name="folderOpen" htmlColor={breadcrumbs.length === 0 ? palette.primary.contrastText : palette.grey[700]} />
            Root
          </ButtonBase>

          {breadcrumbs.map((breadcrumb, index) => (
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
              <Box>/</Box>
              <Icon
                name="folderOpen"
                htmlColor={index === breadcrumbs.length - 1 ? palette.primary.contrastText : palette.grey[700]}
              />
              <ButtonBase
                sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}
                disabled={index === breadcrumbs.length - 1}
                onClick={() => onBreadcrumbClick(index)}
              >
                {breadcrumb.name}
              </ButtonBase>
            </Box>
          ))}
        </Box>

        {isLoading ? (
          <CircularProgress sx={{ mt: 3 }} />
        ) : (
          <Stack gap={0.5} sx={{ mt: 1, pb: 3 }}>
            {nodes.map(node => (
              <CloudFileView
                key={node.id}
                type={node.isFolder ? 'folder' : 'file'}
                name={node.name}
                onClick={() => onNodeClick(node)}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
};

export default CloudFilesDialog;
