import { useEffect } from 'react';
import { Key } from 'ts-key-enum';
import { Instance } from '@nutrient-sdk/viewer';

type Params = {
  instance?: Instance;
  setInitialMode: () => void;
};

export const useModeResetOnEsc = ({ instance, setInitialMode }: Params) => {
  useEffect(() => {
    if (!instance) return;

    const handleEvent = (event: KeyboardEvent) => {
      if (event.key === Key.Escape) {
        setInitialMode();
        instance.setViewState(viewState => viewState.set('interactionMode', null));
      }
    };
    document.addEventListener('keydown', handleEvent);

    return () => {
      document.removeEventListener('keydown', handleEvent);
    };
  }, [instance]);
};
