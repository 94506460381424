import { FC, ReactElement } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { toProjects } from '@/services/linker';
import AppIcon from '@/components/AppIcon';
import { Link } from 'react-router-dom';

interface AppHeaderProps {
  backLink?: string;
  icon: ReactElement<typeof Icon>;
  text: string;
}

const AppHeader: FC<AppHeaderProps> = ({ icon, text, backLink = toProjects() }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        zIndex: 3,
        display: 'flex',
        alignItems: 'center',
        px: 0.5,
        py: 1,
        borderBottom: `1px solid ${palette.custom.separationLine}`,
        boxShadow: 2,
        backgroundColor: palette.background.default,
      }}
    >
      <IconButton component={Link} to={backLink} size="small">
        <Icon name="backArrow" fontSize="large" />
      </IconButton>

      <AppIcon sx={{ ml: -1 }}>{icon}</AppIcon>

      <Typography sx={{ ml: 1, fontWeight: 700, fontSize: 'h3.fontSize', color: palette.accent.contrastText }}>{text}</Typography>
    </Box>
  );
};

export default AppHeader;
