import { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';
import Icon from '@/components/Icon/Icon';

interface DialogCloseButtonProps extends ButtonProps {
  positioned?: boolean;
  onClick: () => void;
}

const DialogCloseButton: FC<DialogCloseButtonProps> = ({ positioned, onClick }) => (
  <Button
    color="secondary"
    variant="text"
    size="2xsmall"
    sx={{
      minWidth: 0,
      p: 0.5,
      '&, &:hover': positioned
        ? {
            position: 'absolute',
            top: 10,
            right: 10,
            p: 0.5,
            boxShadow: 'none',
          }
        : undefined,
    }}
    onClick={onClick}
  >
    <Icon name="x" fontSize="small" />
  </Button>
);

export default DialogCloseButton;
