import { Box, Paper, SxProps, useTheme } from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import {
  EDITOR_CONTENT_LEFT_PADDING,
  EDITOR_SHEET_LEFT_PADDING_SPACING,
  EDITOR_SHEET_RIGHT_PADDING_SPACING,
} from '@/containers/PagesEditor/constants';
import { useTemplateContext } from '@/containers/PagesEditor/context/TemplateContext';

interface LexicalContentEditableProps {
  sx?: SxProps;
  children: ReactNode;
}

const LexicalContentEditable = forwardRef<HTMLDivElement, LexicalContentEditableProps>(({ sx, children }, ref) => {
  const { spacing } = useTheme();
  const { processStatus } = useTemplateContext();

  return (
    <Paper
      sx={{
        position: 'relative',
        zIndex: 0,
        minHeight: 150,
        height: `calc(100% - ${spacing(6)})`,
        pt: 1,
        pl: EDITOR_SHEET_LEFT_PADDING_SPACING,
        pr: EDITOR_SHEET_RIGHT_PADDING_SPACING,
        pb: 3,
        border: 0,
        outline: 0,
        boxShadow: 2,
        overflow: 'auto',
        scrollbarWidth: 'thin',
        '.InlineAiContentNode, .AiContentNode': { display: processStatus === 'ready' ? 'inline' : 'none' },
        '.InlineAiContainerNode, .InlineAiNode, .VariableNode': { display: 'inline-block' },
        '.VariableNode': { px: 1 },
        ...sx,
      }}
    >
      {children}
      <Box
        ref={ref}
        sx={{
          height: '100%',
          flex: 'auto',
          position: 'relative',
          resize: 'vertical',
        }}
      >
        <ContentEditable
          style={{
            display: 'block',
            position: 'relative',
            outline: 0,
            border: 0,
            paddingLeft: EDITOR_CONTENT_LEFT_PADDING,
            minHeight: '100%',
          }}
        />
      </Box>
    </Paper>
  );
});

export default LexicalContentEditable;
