import { Checkbox, CheckboxProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import Icon from '../Icon/Icon';

interface CircleCheckboxProps extends Omit<CheckboxProps, 'size'> {
  size?: number;
}

const CircleCheckbox = forwardRef<HTMLButtonElement, CircleCheckboxProps>(({ size = 24, sx, ...props }, ref) => {
  const { palette } = useTheme();

  return (
    <Checkbox
      {...props}
      ref={ref}
      sx={{ p: 0, color: palette.grey[700], ...sx }}
      icon={<Icon name="uncheckedRing" sx={{ height: size, width: size, fontSize: size, color: '#222222' }} />}
      checkedIcon={<Icon name="checkedRing" sx={{ height: size, width: size, fontSize: size, color: '#5209E6' }} />}
    />
  );
});

export default CircleCheckbox;
