import { $applyNodeReplacement, DecoratorNode, LexicalNode, NodeKey, type SerializedLexicalNode, Spread } from 'lexical';
import VariableBlock from '@/containers/PagesEditor/components/VariableBlock';
import { DateVariableType, VariableType } from '@/containers/PagesEditor/types';

type BlockSettings = { uuid?: string; type: VariableType; dateType?: DateVariableType };

export type SerializedVariableNode = Spread<
  { uuid: string; variableType: VariableType; dateType?: DateVariableType },
  SerializedLexicalNode
>;

export class VariableNode extends DecoratorNode<JSX.Element> {
  /** @internal */
  __uuid: string;

  /** @internal */
  __variableType: VariableType;

  /** @internal */
  __dateType: DateVariableType;

  static getType() {
    return 'variable';
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode({ uuid: node.getUuid(), type: node.getVariableType(), dateType: node.getDateType() }, node.__key);
  }

  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    return $createVariableNode({
      uuid: serializedNode.uuid,
      type: serializedNode.variableType,
      dateType: serializedNode.dateType,
    });
  }

  constructor(settings: BlockSettings, key?: NodeKey) {
    super(key);
    this.__uuid = settings.uuid ?? crypto.randomUUID();
    this.__variableType = settings.type;
    this.__dateType = settings.dateType ?? 'project_last_modified';
  }

  getUuid(): string {
    return this.__uuid;
  }

  setUuid(uuid: string) {
    const writable = this.getWritable();
    writable.__uuid = uuid;
  }

  getVariableType(): VariableType {
    return this.__variableType;
  }

  setVariableType(variableType: VariableType) {
    const writable = this.getWritable();
    writable.__variableType = variableType;
  }

  getDateType(): DateVariableType {
    return this.__dateType;
  }

  setDateType(dateType: DateVariableType) {
    const writable = this.getWritable();
    writable.__dateType = dateType;
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.classList.add('VariableNode');
    return element;
  }

  updateDOM() {
    return false;
  }

  exportJSON(): SerializedVariableNode {
    return {
      type: 'variable',
      version: 1,
      uuid: this.getUuid(),
      variableType: this.getVariableType(),
      dateType: this.getDateType(),
    };
  }

  decorate(): JSX.Element {
    return (
      <VariableBlock
        uuid={this.getUuid()}
        nodeKey={this.getKey()}
        type={this.getVariableType()}
        initialDateType={this.getDateType()}
      />
    );
  }
}

export function $createVariableNode(settings: BlockSettings) {
  return $applyNodeReplacement(new VariableNode(settings));
}

export function $isVariableNode(node: LexicalNode | null | undefined): node is VariableNode {
  return node instanceof VariableNode;
}
