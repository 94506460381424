import React, { FC, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import useRouteId from '@/hooks/useRouteId';
import { useProject } from '@/views/Project/hooks/useProject';
import { useSearchParams } from 'react-router-dom';
import { useStorageState } from 'react-storage-hooks';
import {
  Query,
  useGetProjectsIdQueries,
  usePostProjectsIdQueriesNewQuestion,
  usePullAndExecuteChecklistById,
  useBatchDeleteProjectQuery,
  useGetQueryById,
} from '@/api/generated';
import { ROUTER_IDS } from '@/services/linker';
import { QueriesContext, QueriesContextValue, QueryId, SelectedQueryIds } from '@/views/Project/contexts/QueriesContext';

interface QueriesProviderProps {
  children: React.ReactNode;
}

const QUERY_REFETCH_INTERVAL = 2_000;

const QueriesProvider: FC<QueriesProviderProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const routeId = useRouteId();
  const { projectSlug } = useProject();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedQueryIds, setSelectedQueryIdsState] = useStorageState<SelectedQueryIds | undefined>(
    sessionStorage,
    `persistedQueryIds:${projectSlug}`,
  );
  const queryId = searchParams.get('queryId');
  const matchId = searchParams.get('matchId');

  const { data: fullQuery, isLoading: isMatchesLoading } = useGetQueryById(projectSlug!, selectedQueryIds?.queryId ?? 'never', {
    query: { enabled: !!selectedQueryIds?.queryId },
  });

  const setSelectedQueryIds = (ids: SelectedQueryIds | undefined) => {
    setSearchParams(prevParams => {
      const nextParams = new URLSearchParams(prevParams);

      if (ids?.queryId) {
        nextParams.set('queryId', ids.queryId);
      } else {
        nextParams.delete('queryId');
        nextParams.delete('matchId');
      }

      if (ids?.queryId && ids?.matchId) {
        nextParams.set('matchId', ids.matchId);
      } else {
        nextParams.delete('matchId');
      }

      return nextParams;
    });
    setSelectedQueryIdsState(ids);
  };

  const toggleSelectedQueryIds = (id: QueryId) => {
    const isQuerySelected = selectedQueryIds?.queryId === id;
    setSelectedQueryIds(isQuerySelected ? undefined : { queryId: id });
  };

  const { mutateAsync: getChecklistQuestions, isPending: isChecklistLoading } = usePullAndExecuteChecklistById();
  const {
    data: queries = [],
    isLoading,
    isRefetching,
    queryKey,
  } = useGetProjectsIdQueries(projectSlug, {
    query: {
      enabled: routeId === ROUTER_IDS.PROJECT_QUERIES && !!projectSlug,
      refetchInterval: query => {
        const isAllReady = query.state.data?.some(({ isReady }) => !isReady);
        return isAllReady && QUERY_REFETCH_INTERVAL;
      },
    },
  });

  const isLoadingChecklist = isChecklistLoading || isRefetching;

  const fetchChecklists = async (ids?: string[]) => {
    await getChecklistQuestions({ slug: projectSlug, data: ids ? ids : null });
    queryClient.invalidateQueries({ queryKey });
  };

  const onQueryCreated = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  const onQueriesDelete = (queryIds: QueryId[]) => {
    queryClient.setQueryData(queryKey, (cacheQueries: Query[] = []) =>
      cacheQueries.filter(query => !queryIds.includes(query._id!)),
    );
    if (selectedQueryIds?.queryId && queryIds.includes(selectedQueryIds.queryId)) {
      setSelectedQueryIds(undefined);
    }
  };

  // TODO Move mutations to exact place where we use them, provider should only provide data.
  const { mutateAsync: createQueryMutation } = usePostProjectsIdQueriesNewQuestion({
    mutation: { onSuccess: onQueryCreated },
  });
  const { mutateAsync: deleteQueriesMutation } = useBatchDeleteProjectQuery({
    mutation: { onSuccess: (_, { data: queryIds }) => onQueriesDelete(queryIds) },
  });

  useEffect(() => {
    if (!queryId || !matchId) return;

    setSelectedQueryIdsState({ queryId, matchId });
  }, [matchId]);

  const selectedMatch = useMemo(() => {
    if (isMatchesLoading || !fullQuery || !selectedQueryIds) return;
    return fullQuery.matches?.find(({ id }) => id === selectedQueryIds.matchId);
  }, [fullQuery, isMatchesLoading, selectedQueryIds]);

  const createQuery: QueriesContextValue['createQuery'] = (data, params) =>
    createQueryMutation({ slug: projectSlug, data, params });

  const deleteQueries = (queryIds: QueryId[]) => deleteQueriesMutation({ slug: projectSlug, data: queryIds });

  const value: QueriesContextValue = {
    queries,
    selectedQuery: fullQuery,
    selectedMatch,
    isMatchesLoading,
    selectedQueryIds,
    setSelectedQueryIds,
    toggleSelectedQueryIds,
    isLoadingChecklist,
    isLoading,
    createQuery,
    deleteQueries,
    fetchChecklists,
  };

  return <QueriesContext.Provider value={value}>{children}</QueriesContext.Provider>;
};

export default QueriesProvider;
