import Icon from '@/components/Icon/Icon';
import { Box, ButtonBase, Typography, Menu, MenuItem, useTheme } from '@mui/material';
import { type FC, type MouseEvent, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheck } from 'react-icons/bs';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

type DropdownMenuItemData = {
  label: string;
  icon?: ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => Promise<void> | void;
  disabled?: boolean;
  isConnected?: boolean;
};

type DropdownMenuProps = {
  items: DropdownMenuItemData[];
};

const DropdownMenu: FC<DropdownMenuProps> = ({ items }) => {
  const { palette } = useTheme();
  const { t } = useTranslation('projectUpdate');
  const popupState = usePopupState({ variant: 'popover', popupId: 'dropdownMenu-menu' });

  const onHandleMenuItemClick = (menuItem: DropdownMenuItemData) => (event: MouseEvent<HTMLElement>) => {
    popupState.close();
    menuItem.onClick && menuItem.onClick(event);
  };

  return (
    <>
      <ButtonBase
        sx={{
          display: 'flex',
          gap: 1,
          px: 2,
          py: 1,
          mx: 0.5,
          mt: 1,
          mb: 0.5,
          justifyContent: 'center',
          backgroundColor: palette.primary.main,
          borderRadius: 2,
          color: palette.primary.dark,
          fontSize: 'body3.fontSize',
          fontWeight: 500,
        }}
        {...bindTrigger(popupState)}
      >
        {t('uploadFiles.uploadButtonLabel')}
        <Icon
          name="arrowDown"
          fontSize="medium"
          htmlColor={palette.primary.dark}
          sx={{
            transform: `rotate(${popupState.isOpen ? '180deg' : '0deg'})`,
            transition: 'transform 0.2s ease-in-out',
            lineHeight: 0,
          }}
        />
      </ButtonBase>
      <Menu
        id="dropdownMenu-menu"
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          mt: 2,
          color: palette.grey[800],
          '& .MuiPaper-root': {
            border: `1px solid ${palette.grey[200]}`,
            backgroundColor: palette.background.default,
            borderRadius: 1,
            boxShadow: 1,
            color: palette.grey[800],
          },
          '& .MuiList-root': {
            p: 0,
          },
          '& .MuiMenuItem-root:not(:last-child)': {
            borderBottom: `1px solid ${palette.grey[200]}`,
          },
          '& .MuiMenuItem-root': {
            pl: 3,
            pr: 2,
            minHeight: '2.75rem',
            gap: 1.5,
          },
          '& .Mui-disabled': {
            opacity: 1,
          },
          '& .Mui-disabled > *': {
            opacity: 0.32,
          },
        }}
      >
        {items.map((menuItem, i) => (
          <MenuItem key={i} onClick={onHandleMenuItemClick(menuItem)} disabled={menuItem.disabled}>
            {menuItem.icon}
            <Typography flex={1} fontSize="body3.fontSize" fontWeight={500} pr={2} color={palette.grey[800]}>
              {menuItem.label}
            </Typography>
            {menuItem.isConnected && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  backgroundColor: palette.success.main,
                  borderRadius: '50%',
                  border: `1px solid ${palette.success.dark}`,
                  width: '1rem',
                  height: '1rem',
                }}
              >
                <BsCheck fill={palette.success.dark} />
              </Box>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownMenu;
