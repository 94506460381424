import { Box, Button, Checkbox, Grid, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Control, FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import { FC, useMemo } from 'react';
import { omit } from 'lodash';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ChecklistFormValue } from '@/views/Project/components/ChecklistsManager/ChecklistsManagerDialog';
import { Tag } from '@/api/generated';
import Icon from '@/components/Icon/Icon';
import QuestionRow from '@/views/Project/components/ChecklistsManager/QuestionRow';

interface QuestionsProps extends FieldValues {
  control: Control<ChecklistFormValue>;
  onSaveField: (data?: ChecklistFormValue) => Promise<void>;
  selectedIds: Set<string>;
  onQuestionToggle: (id?: string) => void;
  onToggleAll: () => void;
  selectedAllIds: boolean;
  onUpdateQueryTags: (newTag: Tag, queryToUpdateId: string) => Promise<void>;
  onDeleteTag: (tag: Tag, id?: string) => Promise<void>;
  canEdit?: boolean;
}

const HEADER_KEYS = ['question', 'reference', 'tags'];

const Questions: FC<QuestionsProps> = ({
  control,
  onSaveField,
  selectedIds,
  onQuestionToggle,
  onToggleAll,
  selectedAllIds,
  onUpdateQueryTags,
  onDeleteTag,
  canEdit,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation('project');
  const sensors = useSensors(useSensor(PointerSensor));
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: 'checklistQuestions',
  });
  const { watch, getValues, setFocus } = useFormContext<ChecklistFormValue>();
  const searchQuestionValue = watch('searchQuestionValue');
  const allValues = getValues('checklistQuestions');

  const isAllFieldsValid = allValues?.every(field => field.question !== '');

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    move(active.data.current!.sortable.index, over.data.current!.sortable.index);
    onSaveField();
  };

  const fieldsWithoutId = fields.map(field => omit(field, ['id']));

  const handleBlur = () => {
    const currentValues = getValues();
    const isEqualArrays = JSON.stringify(fieldsWithoutId) === JSON.stringify(currentValues.checklistQuestions);
    !isEqualArrays && onSaveField(currentValues);
  };

  const handleDelete = (index: number) => {
    remove(index);
    onSaveField();
  };

  const addNewQuestion = () => append({ question: '', reference: '', _id: crypto.randomUUID(), tags: [] });

  const filteredFields = useMemo(
    () => fields?.filter(field => field.question.toLowerCase().includes(searchQuestionValue?.toLowerCase() ?? '')),
    [fields, searchQuestionValue],
  );

  const handleEnterOnLastField = (index: number) => {
    const isLastRow = index === filteredFields.length - 1;
    if (!isLastRow) {
      const nextField = filteredFields[index + 1];
      setFocus(`checklistQuestions.${fields.indexOf(nextField)}.question`);
    } else if (isAllFieldsValid) {
      append({ question: '', reference: '', _id: crypto.randomUUID(), tags: [] });
    }
  };

  return (
    <>
      <Stack sx={{ maxHeight: '80%', boxShadow: 2, borderRadius: 3, overflow: 'auto' }}>
        <Grid
          container
          item
          xs={12}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            px: 3,
            py: 2,
            borderBottom: `1px solid ${palette.custom.separationLine}`,
            backgroundColor: palette.background.default,
          }}
        >
          <Grid
            item
            xs={0.5}
            sx={{
              px: 0,
            }}
          >
            <Checkbox size="large" disableRipple sx={{ p: 0 }} onChange={onToggleAll} checked={selectedAllIds} />
          </Grid>
          {HEADER_KEYS.map((key, index) => (
            <Grid
              item
              xs={index === 0 ? 3.5 : HEADER_KEYS.length - 2 === index ? 5 : HEADER_KEYS.length - 1 === index ? 2.5 : 4}
              key={key}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pr: HEADER_KEYS.length - 1 === index ? 0 : 1,
                pl: 1.5,
                textTransform: 'uppercase',
                fontSize: 'body2.fontSize',
                color: palette.grey[400],
              }}
            >
              <Box sx={{ color: palette.grey[700], fontWeight: 'fontWeightBold' }}>
                {t(`queries.checklistsEditDialog.questions.${key}`)}
              </Box>
            </Grid>
          ))}
        </Grid>

        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={filteredFields} strategy={verticalListSortingStrategy}>
            {(filteredFields || []).map((field, index) => (
              <QuestionRow
                key={field.id}
                id={field.id}
                control={control}
                canEdit={canEdit}
                fieldKey={`checklistQuestions.${fields.indexOf(field)}`}
                isSelected={selectedIds.has(field?._id ?? '')}
                onQuestionToggle={onQuestionToggle}
                onUpdateQueryTags={onUpdateQueryTags}
                onDeleteTag={onDeleteTag}
                onEnterOnLastField={() => handleEnterOnLastField(index)}
                onDelete={() => handleDelete(fields.indexOf(field))}
                onBlur={handleBlur}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Stack>
      {canEdit && (
        <Button
          disabled={!isAllFieldsValid}
          sx={{
            mt: 2.5,
            p: 0,
            color: palette.grey[700],
            '& .MuiButton-startIcon': { mr: 1 },
            width: 'fit-content',
            ml: 3.25,
            fontWeight: 'fontWeightRegular',
            '&:hover': {
              color: palette.primary.dark,
            },
          }}
          onClick={addNewQuestion}
          startIcon={<Icon name="plus" />}
        >
          {t('queries.checklistsEditDialog.addNewQuestion')}
        </Button>
      )}
    </>
  );
};

export default Questions;
