import { Components, Theme } from '@mui/material/styles';

const MuiPaginationItem: Components<Theme>['MuiPaginationItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: 24,
      height: 24,
      marginLeft: 4,
      marginRight: 4,
      paddingLeft: 4,
      paddingRight: 4,
      boxSizing: 'border-box',
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.primary.dark,
      '&.Mui-selected': {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    }),
    previousNext: ({ theme }) => ({
      paddingLeft: 0,
      paddingRight: 0,
      margin: 0,
      border: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.light,
      },
    }),
  },
};
export default MuiPaginationItem;
