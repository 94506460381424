import { FC, ReactNode, useRef, useState } from 'react';
import { TemplatePage } from '@/api/generated';
import { PageFromTemplateDialogContext, PageFromTemplateDialogContextValue } from '@/contexts/PageFromTemplateDialogContext';
import PageFromTemplateDialog from '@/components/PageFromTemplateDialog';

interface PageFromTemplateDialogProviderProps {
  children: ReactNode;
}

type Result = false | TemplatePage;

export const PageFromTemplateDialogProvider: FC<PageFromTemplateDialogProviderProps> = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const slugRef = useRef('');
  const preselectedTemplateIdRef = useRef<string | undefined>(undefined);
  const resolveRef = useRef<(result: Result) => void>(() => false);

  const contextValue: PageFromTemplateDialogContextValue = {
    showPageFromTemplateDialog: async (slug: string, preselectedTemplateId?: string) => {
      slugRef.current = slug;
      preselectedTemplateIdRef.current = preselectedTemplateId;
      setIsOpened(true);
      return new Promise<Result>(resolve => (resolveRef.current = resolve));
    },
  };

  const onDialogClose = (result: Result) => {
    resolveRef.current(result);
    slugRef.current = '';
    preselectedTemplateIdRef.current = undefined;
    setIsOpened(false);
  };

  return (
    <PageFromTemplateDialogContext.Provider value={contextValue}>
      <PageFromTemplateDialog
        isOpened={isOpened}
        slug={slugRef.current}
        preselectedTemplateId={preselectedTemplateIdRef.current}
        onClose={onDialogClose}
      />
      {children}
    </PageFromTemplateDialogContext.Provider>
  );
};
