import { FC, ReactElement } from 'react';
import { Stack, StackProps, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';

interface AppIconProps extends Omit<StackProps, 'children'> {
  children: ReactElement<typeof Icon>;
}

const AppIcon: FC<AppIconProps> = ({ children }) => {
  const { palette } = useTheme();

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        p: 1,
        boxShadow: 19,
        borderRadius: 2,
        backgroundColor: palette.background.default,
        color: palette.accent.contrastText,
      }}
    >
      {children}
    </Stack>
  );
};

export default AppIcon;
