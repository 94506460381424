const palette = {
  grey: {
    25: '#F5F5F5',
    50: '#ECEBEB',
    100: '#D8D8D7',
    200: '#C5C4C3',
    300: '#B1B0AF',
    400: '#9E9C9B',
    500: '#8B8986',
    600: '#777572',
    700: '#64615E',
    800: '#504E4A',
    900: '#3D3A36',
  },
  gradient: {
    pelles: 'linear-gradient(90deg, #872B97 0%, #c33480 36%, #FF7130 100%)',
  },
  primary: {
    main: '#EEE6FC',
    dark: '#5209E6',
    light: '#DCCEFA',
    contrastText: '#5209E6',
  },
  secondary: {
    main: '#ECEBEB',
    light: '#8B8986',
    dark: '#777572',
    contrastText: '#000',
  },
  success: {
    main: '#BFDAA4AA',
    dark: '#051F20',
  },
  accent: {
    main: '#fea622',
    light: '#FFDBB3',
    dark: '#EB9130',
    contrastText: '#290473',
  },
  error: {
    light: '#ef5350',
    main: '#d32f2f',
    dark: '#c62828',
  },
  text: {
    primary: '#3D3A36',
    secondary: '#000000',
    dark: '#504E4A',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FCFAFE',
    selection: 'rgba(35, 131, 226, 0.28)',
  },
  controls: {
    label: '#3A3A3A',
    text: '#3D3A36',
    hover: '#EEE6FC',
    selected: '#5209E6',
    background: '#F8FAFC',
    border: '#D8D8D7',
    placeholder: '#8B8986',
  },
  custom: {
    separationLine: '#E1E1E1',
    readyStatus: '#61C423',
    notReadyStatus: '#FF9E34',
    like: '#409e3d',
    dislike: '#b80e0e',
  },
  tags: [
    '#49758F',
    '#AC6F81',
    '#D57BB6',
    '#FDC2CD',
    '#FF00AA',
    '#ED0404',
    '#570BB7',
    '#BCABC6',
    '#808000',
    '#A4C578',
    '#0ACB10',
    '#B8F331',
    '#DA6E0F',
    '#FF9E34',
    '#FFEA00',
    '#FFE4B5',
    '#17DEEE',
    '#1EE8B6',
    '#0088FF',
    '#26A1D5',
  ],
};

export default palette;
