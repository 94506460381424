import { IInteractionMode, MeasurementPrecision, MeasurementScaleUnitFrom, MeasurementScaleUnitTo } from '@nutrient-sdk/viewer';

export const MEASUREMENT_MODES: IInteractionMode[] = ['DISTANCE', 'PERIMETER', 'RECTANGLE_AREA', 'ELLIPSE_AREA', 'POLYGON_AREA'];

const precisionValueMap = {
  [MeasurementPrecision.WHOLE]: '1',
  [MeasurementPrecision.ONE]: '0.1',
  [MeasurementPrecision.TWO]: '0.01',
  [MeasurementPrecision.THREE]: '0.001',
  [MeasurementPrecision.FOUR]: '0.0001',
  [MeasurementPrecision.HALVES]: '1/2',
  [MeasurementPrecision.QUARTERS]: '1/4',
  [MeasurementPrecision.EIGHTHS]: '1/8',
  [MeasurementPrecision.SIXTEENTHS]: '1/16',
};

export const intPrecisions = [
  MeasurementPrecision.WHOLE,
  MeasurementPrecision.ONE,
  MeasurementPrecision.TWO,
  MeasurementPrecision.THREE,
  MeasurementPrecision.FOUR,
];

export const intPrecisionUnits: string[] = [
  MeasurementScaleUnitTo.CENTIMETERS,
  MeasurementScaleUnitTo.KILOMETERS,
  MeasurementScaleUnitTo.METERS,
  MeasurementScaleUnitTo.MILLIMETERS,
];

export const unitFromOptions = Object.values(MeasurementScaleUnitFrom).map(unit => ({ title: unit, value: unit }));
export const unitToOptions = Object.values(MeasurementScaleUnitTo).map(unit => ({ title: unit, value: unit }));
export const allPrecisionOptions = Object.values(MeasurementPrecision).map(precision => ({
  title: precisionValueMap[precision],
  value: precision,
}));

export const intPrecisionOptions = intPrecisions.map(precision => ({
  title: precisionValueMap[precision],
  value: precision,
}));
