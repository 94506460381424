import { createTheme } from '@mui/material';
import components from './components';
import palette from './palette';
import typography from './typography';
import mixins from './mixins';
import shadows from './shadows';

// TODO Make types from the created theme, remove duplication inside palette itself.

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    accent: true;
  }

  interface ButtonPropsSizeOverrides {
    xsmall: true;
    '2xsmall': true;
    '3xsmall': true;
  }

  interface SwitchPropsSizeOverrides {
    xsmall: true;
  }

  interface ChipPropsColorOverrides {
    accent: true;
  }

  interface SvgIconPropsSizeOverrides {
    xsmall: true;
  }

  interface TypographyPropsVariantOverrides {
    body4: true;
    body3: true;
  }

  interface IconButtonPropsColorOverrides {
    accent: true;
    secondary: true;
  }

  interface IconButtonPropsSizeOverrides {
    xsmall: true;
    '2xsmall': true;
    '3xsmall': true;
  }

  interface Color {
    25: string;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    selection: string;
  }

  interface CustomColors {
    separationLine: string;
    readyStatus: string;
    notReadyStatus: string;
    like: string;
    dislike: string;
  }

  interface Gradients {
    pelles: string;
  }

  interface Controls {
    label: string;
    text: string;
    hover: string;
    selected: string;
    background: string;
    border: string;
    placeholder: string;
  }

  interface Palette {
    tags: string[];
    gradient: Gradients;
    controls: Controls;
    custom: CustomColors;
    accent: Palette['primary'];
  }

  interface PaletteOptions {
    tags: string[];
    gradient: Gradients;
    controls: Controls;
    custom: CustomColors;
    accent: PaletteOptions['primary'];
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    base: Typography;
    body3: Typography;
    body4: Typography;
    icons: Typography;
  }

  interface TypographyOptions {
    base: TypographyOptions['body1'];
    body3: TypographyOptions['body1'];
    body4: TypographyOptions['body1'];
    icons: TypographyOptions['h3'];
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    muiSwitchTrack: CSSProperties;
  }
  interface MixinsOptions {
    muiSwitchTrack?: CSSProperties;
  }
}

export default createTheme({
  components,
  typography,
  palette,
  shadows,
  shape: {
    borderRadius: 4,
  },
  mixins,
});
