import { createContext } from 'react';
import { PopupDocumentsInProgressProps } from './PopupDocumentsInProgress';

export type PopupDocumentsInProgressOptions = {
  onProceed: PopupDocumentsInProgressProps['onProceed'];
  action: PopupDocumentsInProgressProps['action'];
};

export type PopupDocumentsInProgressContextValue = {
  showPopupDocumentsInProgress(options: PopupDocumentsInProgressOptions): Promise<boolean>;
};

export const PopupDocumentsInProgressContext = createContext<PopupDocumentsInProgressContextValue | null>(null);
