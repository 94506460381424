import { Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import Icon from '@/components/Icon/Icon';

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const CustomCheckbox: FC<CustomCheckboxProps> = ({ checked, onChange, label }) => {
  const { palette } = useTheme();

  return (
    <FormControlLabel
      sx={{
        backgroundColor: palette.primary.main,
        pr: 1,
        mx: 0,
        py: 0.25,
        borderRadius: '999px',
        maxHeight: 24,
        color: checked ? palette.primary.dark : palette.secondary.light,
        '& .MuiFormControlLabel-label': {
          fontSize: 'body4.fontSize',
          fontWeight: 'fontWeightBold',
          fontFamily: palette.text.primary,
        },
      }}
      control={
        <Checkbox
          defaultChecked={checked}
          onChange={onChange}
          sx={{
            color: 'transparent',
            p: 0,
            mr: 0.75,
            ml: 0.25,
            my: 0.25,
            boxShadow: 9,
            border: `1px solid ${palette.primary.dark}`,
            background: `linear-gradient(${palette.background.default}, ${palette.grey[100]})`,
            maxWidth: 18,
            maxHeight: 18,
            '&.Mui-checked': { p: 0.25 },
          }}
          checkedIcon={<Icon fontSize="xsmall" sx={{ color: palette.primary.dark }} name="checked" />}
        />
      }
      label={label}
    />
  );
};

export default CustomCheckbox;
