import {
  CHECK_LIST,
  ELEMENT_TRANSFORMERS,
  MULTILINE_ELEMENT_TRANSFORMERS,
  TEXT_FORMAT_TRANSFORMERS,
  TEXT_MATCH_TRANSFORMERS,
} from '@lexical/markdown';
import { TWEET_TRANSFORMER } from '@/containers/PagesEditor/transformers/tweet.transformer';
import { HR_TRANSFORMER } from '@/containers/PagesEditor/transformers/hr.transformer';
import { TABLE_TRANSFORMER } from '@/containers/PagesEditor/transformers/table.transformer';
import { PAGE_BREAK_TRANSFORMER } from '@/containers/PagesEditor/transformers/pageBreak.transformer';
import { COLLAPSIBLE_TRANSFORMER } from '@/containers/PagesEditor/transformers/collapsible.transformer';
import { EXCALIDRAW_TRANSFORMER } from '@/containers/PagesEditor/transformers/excalidraw.transformer';
import { LAYOUT_TRANSFORMER } from '@/containers/PagesEditor/transformers/layout.transformer';
import { TEXT_MATCH_TRANSFORMER } from '@/containers/PagesEditor/transformers/textMatch.transformer';
import { AI_TRANSFORMER } from '@/containers/PagesEditor/transformers/ai.transformer';
import { VARIABLE_TRANSFORMER } from '@/containers/PagesEditor/transformers/variable.transformer';
import { INLINE_AI_TRANSFORMER } from '@/containers/PagesEditor/transformers/inline-ai.transformer';

export const PAGES_TRANSFORMERS = [
  TEXT_MATCH_TRANSFORMER,
  LAYOUT_TRANSFORMER,
  EXCALIDRAW_TRANSFORMER,
  COLLAPSIBLE_TRANSFORMER,
  PAGE_BREAK_TRANSFORMER,
  TABLE_TRANSFORMER,
  HR_TRANSFORMER,
  TWEET_TRANSFORMER,
  CHECK_LIST,
  AI_TRANSFORMER,
  INLINE_AI_TRANSFORMER,
  VARIABLE_TRANSFORMER,
  ...ELEMENT_TRANSFORMERS,
  ...MULTILINE_ELEMENT_TRANSFORMERS,
  ...TEXT_FORMAT_TRANSFORMERS,
  ...TEXT_MATCH_TRANSFORMERS,
];
