import { Dispatch, useCallback, useEffect, useState } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import {
  $createCodeNode,
  $isCodeNode,
  CODE_LANGUAGE_FRIENDLY_NAME_MAP,
  CODE_LANGUAGE_MAP,
  getLanguageFriendlyName,
} from '@lexical/code';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { $createHeadingNode, $createQuoteNode, $isHeadingNode, HeadingTagType } from '@lexical/rich-text';
import { $getSelectionStyleValueForProperty, $isParentElementRTL, $patchStyleText, $setBlocksType } from '@lexical/selection';
import { $isTableNode, $isTableSelection } from '@lexical/table';
import { $findMatchingParent, $getNearestNodeOfType, $isEditorIsNestedEditor, mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  KEY_MODIFIER_COMMAND,
  LexicalEditor,
  NodeKey,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import { IS_APPLE } from 'shared/environment';

import useModal from '@lexical/playground/hooks/useModal';
import { getSelectedNode } from '@lexical/playground/utils/getSelectedNode';
import { sanitizeUrl } from '@lexical/playground/utils/url';
import { INSERT_COLLAPSIBLE_COMMAND } from '@lexical/playground/plugins/CollapsiblePlugin';
import { INSERT_EXCALIDRAW_COMMAND } from '@lexical/playground/plugins/ExcalidrawPlugin';
import { InsertImageDialog } from '@lexical/playground/plugins/ImagesPlugin';
import { InsertInlineImageDialog } from '@lexical/playground/plugins/InlineImagePlugin';
import InsertLayoutDialog from '@lexical/playground/plugins/LayoutPlugin/InsertLayoutDialog';
import { INSERT_PAGE_BREAK } from '@lexical/playground/plugins/PageBreakPlugin';
import { InsertTableDialog } from '@lexical/playground/plugins/TablePlugin';
import FontSize from './fontSize';
import ToolbarButton from '@/containers/PagesEditor/components/ToolbarButton';
import Icon, { IconNames } from '@/components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import ActionsSeparator from '@/components/ActionsSeparator';
import {
  backgroundColors,
  blockTypes,
  defaultBgColor,
  defaultTextColor,
  textColors,
} from '@/containers/PagesEditor/plugins/FloatingTextFormatToolbarPlugin/constants';
import ToolbarMenuItem from '@/containers/PagesEditor/components/ToolbarMenuItem';
import PageEditorIcon from '@/containers/PagesEditor/ui/PageEditorIcon';
import ToolbarMenu from '@/containers/PagesEditor/components/ToolbarMenu';
import ToolbarColorMenuItem from '@/containers/PagesEditor/components/ToolbarColorMenuItem';
import AskAiButton from '@/containers/PagesEditor/components/AskAiButton';
import { ASK_AI_COMMAND } from '@/containers/PagesEditor/plugins/AskAiPlugin/AskAiPlugin';

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
};

function getCodeLanguageOptions(): [string, string][] {
  const options: [string, string][] = [];

  for (const [lang, friendlyName] of Object.entries(CODE_LANGUAGE_FRIENDLY_NAME_MAP)) {
    options.push([lang, friendlyName]);
  }

  return options;
}

const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions();

function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  rootType: keyof typeof rootTypeToRootName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element {
  const { t } = useTranslation('lexicalEditor');

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatCheckList = () => {
    if (blockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createQuoteNode());
      });
    }
  };

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        let selection = $getSelection();

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode());
          } else {
            const textContent = selection.getTextContent();
            const codeNode = $createCodeNode();
            selection.insertNodes([codeNode]);
            selection = $getSelection();
            if ($isRangeSelection(selection)) {
              selection.insertRawText(textContent);
            }
          }
        }
      });
    }
  };

  const blockTypeChangeHandlers: Record<(typeof blockTypes)[number], () => void> = {
    paragraph: formatParagraph,
    h1: () => formatHeading('h1'),
    h2: () => formatHeading('h2'),
    h3: () => formatHeading('h3'),
    number: formatNumberedList,
    bullet: formatBulletList,
    check: formatCheckList,
    quote: formatQuote,
    code: formatCode,
  };

  return (
    <ToolbarMenu
      id="node-type"
      disabled={disabled}
      selectedValue={
        blockType && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PageEditorIcon blockType={blockType} fontSize="small" />
            {t(`editor.toolbar.${blockType}`)}
          </Box>
        )
      }
    >
      {blockTypes.map(iterationBlock => (
        <ToolbarMenuItem isSelected={blockType === iterationBlock} onClick={blockTypeChangeHandlers[iterationBlock]}>
          <PageEditorIcon blockType={iterationBlock} fontSize="small" /> {t(`editor.toolbar.${iterationBlock}`)}
        </ToolbarMenuItem>
      ))}
    </ToolbarMenu>
  );
}

function ElementFormatDropdown({
  editor,
  value: currentValue = 'left',
  disabled = false,
}: {
  editor: LexicalEditor;
  value: ElementFormatType;
  isRTL: boolean;
  disabled: boolean;
}) {
  const { t } = useTranslation('lexicalEditor');

  const options: Partial<Record<ElementFormatType, { icon: IconNames; label: string }>> = {
    left: { icon: 'alignLeft', label: t('editor.format.left') },
    center: { icon: 'alignCenter', label: t('editor.format.center') },
    right: { icon: 'alignRight', label: t('editor.format.right') },
    justify: { icon: 'alignJustify', label: t('editor.format.justify') },
  };

  if (!options[currentValue]) return null;

  return (
    <>
      <ActionsSeparator />
      <ToolbarMenu
        id="element-format"
        disabled={disabled}
        selectedValue={<Icon name={options[currentValue]!.icon} fontSize="small" />}
      >
        {Object.entries(options).map(([value, { icon, label }]) => (
          <ToolbarMenuItem
            key={value}
            isSelected={currentValue === value}
            onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, value as ElementFormatType)}
          >
            <Icon name={icon} fontSize="small" />
            {label}
          </ToolbarMenuItem>
        ))}
      </ToolbarMenu>
    </>
  );
}

export default function ToolbarPlugin({ setIsLinkEditMode }: { setIsLinkEditMode: Dispatch<boolean> }): JSX.Element {
  const { t } = useTranslation('lexicalEditor');
  const { palette, spacing } = useTheme();

  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [rootType, setRootType] = useState<keyof typeof rootTypeToRootName>('root');
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(null);
  const [fontSize, setFontSize] = useState<string>('15px');
  const [textColor, setTextColor] = useState<string>(defaultTextColor);
  const [bgColor, setBgColor] = useState<string>(defaultBgColor);
  const [elementFormat, setElementFormat] = useState<ElementFormatType>('left');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [modal, showModal] = useModal();
  const [isRTL, setIsRTL] = useState(false);
  const [codeLanguage, setCodeLanguage] = useState<string>('');
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [isImageCaption, setIsImageCaption] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      if (activeEditor !== editor && $isEditorIsNestedEditor(activeEditor)) {
        const rootElement = activeEditor.getRootElement();
        setIsImageCaption(!!rootElement?.parentElement?.classList.contains('image-caption-container'));
      } else {
        setIsImageCaption(false);
      }

      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      const tableNode = $findMatchingParent(node, $isTableNode);
      if ($isTableNode(tableNode)) {
        setRootType('table');
      } else {
        setRootType('root');
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
          if ($isCodeNode(element)) {
            const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
            setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : '');
            return;
          }
        }
      }
      // Handle buttons
      setTextColor($getSelectionStyleValueForProperty(selection, 'color', '#000'));
      setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'));
      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(node, parentNode => $isElementNode(parentNode) && !parentNode.isInline());
      }

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
            ? node.getFormatType()
            : parent?.getFormatType() || 'left',
      );
    }
    if ($isRangeSelection(selection) || $isTableSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '15px'));
    }
  }, [activeEditor, editor]);

  useEffect(
    () =>
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          setActiveEditor(newEditor);
          $updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    [editor, $updateToolbar],
  );

  useEffect(() => {
    activeEditor.getEditorState().read(() => {
      $updateToolbar();
    });
  }, [activeEditor, $updateToolbar]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerEditableListener(editable => {
          setIsEditable(editable);
        }),
        activeEditor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            $updateToolbar();
          });
        }),
        activeEditor.registerCommand<boolean>(
          CAN_UNDO_COMMAND,
          payload => {
            setCanUndo(payload);
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        ),
        activeEditor.registerCommand<boolean>(
          CAN_REDO_COMMAND,
          payload => {
            setCanRedo(payload);
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        ),
      ),
    [$updateToolbar, activeEditor, editor],
  );

  useEffect(
    () =>
      activeEditor.registerCommand(
        KEY_MODIFIER_COMMAND,
        payload => {
          const event: KeyboardEvent = payload;
          const { code, ctrlKey, metaKey } = event;

          if (code === 'KeyK' && (ctrlKey || metaKey)) {
            event.preventDefault();
            let url: string | null;
            if (!isLink) {
              setIsLinkEditMode(true);
              url = sanitizeUrl('https://');
            } else {
              setIsLinkEditMode(false);
              url = null;
            }
            return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
          }
          return false;
        },
        COMMAND_PRIORITY_NORMAL,
      ),
    [activeEditor, isLink, setIsLinkEditMode],
  );

  const applyStyleText = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      activeEditor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: 'historic' } : {},
      );
    },
    [activeEditor],
  );

  const onTextColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({ color: value }, skipHistoryStack);
      applyStyleText({ 'background-color': defaultBgColor }, skipHistoryStack);
      setBgColor(defaultBgColor);
    },
    [applyStyleText],
  );

  const onBgColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({ 'background-color': value }, skipHistoryStack);
      applyStyleText({ color: defaultTextColor }, skipHistoryStack);
      setTextColor(defaultTextColor);
    },
    [applyStyleText],
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true);
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
    } else {
      setIsLinkEditMode(false);
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [activeEditor, isLink, setIsLinkEditMode]);

  const onCodeLanguageSelect = useCallback(
    (value: string) => {
      activeEditor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(value);
          }
        }
      });
    },
    [activeEditor, selectedElementKey],
  );

  const onAskAi = () => {
    editor.dispatchCommand(ASK_AI_COMMAND, { isAskingWithSelection: true });
  };

  const canViewerSeeInsertDropdown = !isImageCaption;

  return (
    <Box
      className="toolbar"
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        width: `calc(100% - ${spacing(4)})`,
        minHeight: 42,
        mx: 2,
        px: 1,
        borderTopLeftRadius: spacing(1),
        borderTopRightRadius: spacing(1),
        boxShadow: '0 1px 4px 0px #EEE6FC',
        overflow: 'auto',
        overflowY: 'hidden',
        scrollbarWidth: 'thin',
        backgroundColor: palette.background.default,
      }}
    >
      <Stack direction="row" gap={0.5}>
        <ToolbarButton
          disabled={!canUndo || !isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
          }}
          aria-label={t('editor.undo')}
          title={t('editor.undoTitle', { key: IS_APPLE ? '(⌘Z)' : '(Ctrl+Z)' })}
        >
          <Icon name="undo" sx={{ transform: 'scaleX(-1)' }} />
        </ToolbarButton>
        <ToolbarButton
          disabled={!canRedo || !isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(REDO_COMMAND, undefined);
          }}
          aria-label={t('editor.undo')}
          title={t('editor.redoTitle', { key: IS_APPLE ? '(⇧⌘Z)' : '(Ctrl+Y)' })}
        >
          <Icon name="undo" />
        </ToolbarButton>
      </Stack>
      <ActionsSeparator />
      {blockType in blockTypeToBlockName && activeEditor === editor && (
        <>
          <BlockFormatDropDown disabled={!isEditable} blockType={blockType} rootType={rootType} editor={activeEditor} />
          <ActionsSeparator />
        </>
      )}
      {blockType === 'code' ? (
        <>
          <ToolbarMenu id="code-type" selectedValue={getLanguageFriendlyName(codeLanguage)}>
            {CODE_LANGUAGE_OPTIONS.map(([value, name]) => (
              <ToolbarMenuItem key={value} isSelected={value === codeLanguage} onClick={() => onCodeLanguageSelect(value)}>
                {name}
              </ToolbarMenuItem>
            ))}
          </ToolbarMenu>
          <ActionsSeparator />
        </>
      ) : (
        <>
          <FontSize selectionFontSize={fontSize.slice(0, -2)} editor={activeEditor} disabled={!isEditable} />
          <ActionsSeparator />
          <Stack direction="row" gap={0.5}>
            <ToolbarButton
              isActive={isBold}
              title={t('editor.toolbar.bold')}
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
              }}
            >
              <Icon name="bold" />
            </ToolbarButton>
            <ToolbarButton
              isActive={isItalic}
              title={t('editor.toolbar.italic')}
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
              }}
            >
              <Icon name="italic" />
            </ToolbarButton>
            <ToolbarButton
              isActive={isUnderline}
              title={t('editor.toolbar.underline')}
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
              }}
            >
              <Icon name="underline" />
            </ToolbarButton>
            <ToolbarButton isActive={isLink} title={t('editor.toolbar.link')} onClick={insertLink}>
              <Icon name="link" />
            </ToolbarButton>
            <ToolbarMenu id="background-color" selectedValue={<Icon name="color" fontSize="small" />}>
              {Object.entries(backgroundColors).map(([name, color]) => (
                <ToolbarColorMenuItem
                  key={color}
                  type="background"
                  isSelected={color === bgColor}
                  color={color}
                  onClick={() => onBgColorSelect(color, false)}
                >
                  {name}
                </ToolbarColorMenuItem>
              ))}
            </ToolbarMenu>
            <ToolbarMenu id="text-color" selectedValue={<Icon name="textColor" fontSize="small" />}>
              {Object.entries(textColors).map(([name, color]) => (
                <ToolbarColorMenuItem
                  key={color}
                  type="text"
                  isSelected={color === textColor}
                  color={color}
                  onClick={() => onTextColorSelect(color, false)}
                >
                  {name}
                </ToolbarColorMenuItem>
              ))}
            </ToolbarMenu>
          </Stack>

          <ElementFormatDropdown disabled={!isEditable} value={elementFormat} editor={activeEditor} isRTL={isRTL} />

          {canViewerSeeInsertDropdown && (
            <>
              <ActionsSeparator />
              <ToolbarMenu id="text-color" selectedValue={t('editor.insert.title')}>
                {!import.meta.env.PROD && (
                  <ToolbarMenuItem onClick={() => activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined)}>
                    {t('editor.insert.horizontalRule')}
                  </ToolbarMenuItem>
                )}
                <ToolbarMenuItem onClick={() => activeEditor.dispatchCommand(INSERT_PAGE_BREAK, undefined)}>
                  <Icon name="pageBreak" fontSize="small" />
                  {t('editor.insert.pageBreak')}
                </ToolbarMenuItem>
                {!import.meta.env.PROD && (
                  <ToolbarMenuItem
                    onClick={() =>
                      showModal('Insert Image', onClose => <InsertImageDialog activeEditor={activeEditor} onClose={onClose} />)
                    }
                  >
                    <Icon name="image" fontSize="small" />
                    {t('editor.insert.image')}
                  </ToolbarMenuItem>
                )}
                {!import.meta.env.PROD && (
                  <ToolbarMenuItem
                    onClick={() =>
                      showModal('Insert Inline Image', onClose => (
                        <InsertInlineImageDialog activeEditor={activeEditor} onClose={onClose} />
                      ))
                    }
                  >
                    <Icon name="image" fontSize="small" />
                    {t('editor.insert.inlineImage')}
                  </ToolbarMenuItem>
                )}
                <ToolbarMenuItem onClick={() => activeEditor.dispatchCommand(INSERT_EXCALIDRAW_COMMAND, undefined)}>
                  <Icon name="excalidraw" fontSize="small" />
                  {t('editor.insert.excalidraw')}
                </ToolbarMenuItem>
                <ToolbarMenuItem
                  onClick={() =>
                    showModal('Insert Table', onClose => <InsertTableDialog activeEditor={activeEditor} onClose={onClose} />)
                  }
                >
                  <Icon name="table" fontSize="small" />
                  {t('editor.insert.table')}
                </ToolbarMenuItem>
                <ToolbarMenuItem
                  onClick={() =>
                    showModal('Insert Columns Layout', onClose => (
                      <InsertLayoutDialog activeEditor={activeEditor} onClose={onClose} />
                    ))
                  }
                >
                  <Icon name="columnsLayout" fontSize="small" />
                  {t('editor.insert.columnsLayout')}
                </ToolbarMenuItem>
                <ToolbarMenuItem onClick={() => editor.dispatchCommand(INSERT_COLLAPSIBLE_COMMAND, undefined)}>
                  {t('editor.insert.collapsibleContainer')}
                </ToolbarMenuItem>
              </ToolbarMenu>
            </>
          )}
        </>
      )}

      <AskAiButton onClick={onAskAi} />
      {modal}
    </Box>
  );
}
