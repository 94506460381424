import { forwardRef } from 'react';
import { Box } from '@mui/material';

const DraggableHorizontalTargetLine = forwardRef((_, ref) => (
  <Box
    ref={ref}
    sx={{
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 1,
      pointerEvents: 'none',
      width: '3px',
      ml: '-1px',
      opacity: 0,
      backgroundColor: 'deepskyblue',
      willChange: 'transform',
    }}
  />
));

export default DraggableHorizontalTargetLine;
