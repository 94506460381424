import { forwardRef } from 'react';
import { Box } from '@mui/material';

const DraggableTargetLine = forwardRef((_, ref) => (
  <Box
    ref={ref}
    sx={{
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 1,
      pointerEvents: 'none',
      height: 4,
      opacity: 0,
      backgroundColor: 'deepskyblue',
      willChange: 'transform',
    }}
  />
));

export default DraggableTargetLine;
