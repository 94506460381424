import { FC, ReactNode } from 'react';
import { Box, ButtonBase, SxProps, useTheme } from '@mui/material';

interface SidebarGroupItemProps {
  sx?: SxProps;
  title: string;
  isActive: boolean;
  left?: ReactNode;
  actions?: ReactNode;
  onClick: () => void;
}

const SidebarGroupItem: FC<SidebarGroupItemProps> = ({ sx, title, isActive, onClick, left, actions }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
        pr: 0.25,
        pl: 1,
        borderRadius: 1,
        ...sx,
        '&:hover': { backgroundColor: palette.secondary.main, '.SidebarGroupItem__actions': { opacity: 1 } },
      }}
    >
      {left}
      <ButtonBase
        disableRipple
        onClick={onClick}
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          height: '100%',
          minHeight: 20,
          py: 1,
          fontSize: 'body2.fontSize',
          textAlign: 'left',
          fontWeight: isActive ? 700 : 400,
          color: isActive ? palette.primary.dark : palette.grey[900],
        }}
      >
        {title}
      </ButtonBase>
      <Box className="SidebarGroupItem__actions" sx={{ pr: 1, opacity: 0 }}>
        {actions}
      </Box>
    </Box>
  );
};

export default SidebarGroupItem;
