import { $applyNodeReplacement, DecoratorNode, LexicalNode, NodeKey, type SerializedLexicalNode, Spread } from 'lexical';
import InlineAiBlock from '@/containers/PagesEditor/components/InlineAiBlock';

type BlockSettings = { prompt: string };

export type SerializedInlineAiNode = Spread<{ prompt: string }, SerializedLexicalNode>;

export class InlineAiNode extends DecoratorNode<JSX.Element> {
  /** @internal */
  __prompt: string;

  static getType() {
    return 'inline-ai';
  }

  static clone(node: InlineAiNode): InlineAiNode {
    return new InlineAiNode({ prompt: node.getPrompt() }, node.__key);
  }

  static importJSON(serializedNode: SerializedInlineAiNode): InlineAiNode {
    return $createInlineAiNode({ prompt: serializedNode.prompt });
  }

  constructor(settings: BlockSettings, key?: NodeKey) {
    super(key);
    this.__prompt = settings.prompt;
  }

  getPrompt(): string {
    return this.__prompt;
  }

  setPrompt(prompt: string) {
    const writable = this.getWritable();
    writable.__prompt = prompt;
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.classList.add('InlineAiNode');
    return element;
  }

  updateDOM() {
    return false;
  }

  exportJSON(): SerializedInlineAiNode {
    return {
      type: 'inline-ai',
      version: 1,
      prompt: this.getPrompt(),
    };
  }

  remove(preserveEmptyParent?: boolean) {
    super.remove(preserveEmptyParent);
    this.getParent()?.remove();
  }

  isInline() {
    return true;
  }

  decorate(): JSX.Element {
    return <InlineAiBlock nodeKey={this.getKey()} initialPrompt={this.getPrompt()} />;
  }
}

export function $createInlineAiNode(settings: BlockSettings) {
  return $applyNodeReplacement(new InlineAiNode(settings));
}

export function $isInlineAiNode(node: LexicalNode | null | undefined): node is InlineAiNode {
  return node instanceof InlineAiNode;
}
