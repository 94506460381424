import { alpha, Box, CircularProgress, Stack, useTheme } from '@mui/material';
import { FC, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useGetProjectsId, useGetProjectsIdDocumentsDocumentId } from '@/api/generated';
import Dialog from '@/components/Dialog/Dialog';
import { useOrganization } from '@/hooks/useOrganization';
import { useFileSystemTree } from '@/views/Projects/components/ProjectFormDialog/hooks/useFileSystemTree';
import FilesTree from '@/views/Projects/components/ProjectFormDialog/components/FilesTree/FilesTree';
import { useFilesUpload } from '@/views/Projects/components/ProjectFormDialog/hooks/useDocumentsUpload';
import VersionsUpload from '@/views/Projects/components/ProjectFormDialog/components/VersionsUpload';
import DocumentUpload from '@/views/Projects/components/ProjectFormDialog/components/DocumentUpload';
import { TreeFileSystemNode } from '@/types';
import { NodeApi } from 'react-arborist';

interface OrganizationalDocumentsProps {
  nodesByIdRef: MutableRefObject<Record<string, TreeFileSystemNode>>;
}

const OrganizationalDocuments: FC<OrganizationalDocumentsProps> = ({ nodesByIdRef }) => {
  const { palette } = useTheme();
  const { organization } = useOrganization();

  const [documentId, setDocumentId] = useState<string | null>(null);

  const {
    loadFileSystem,
    selectedDocument,
    selectedDocumentVersions,
    fileSystemNodes,
    importDriveFiles,
    uploadFiles,
    uploadVersion,
    onRename,
    onMove,
    onFolderCreate,
    onDelete,
    onDocumentConvertToPage,
    onAddVersion,
    onVersionsClose,
    deleteDocumentVersion,
    setAsMainDocumentVersion,
  } = useFileSystemTree({
    getOrCreateProject: () => Promise.resolve(organization),
    getSlug: () => organization.slug,
    nodesByIdRef,
  });

  const { data: knowledgeProject, isLoading: isKnowledgeBaseLoading } = useGetProjectsId(organization.slug);
  const { data: knowledgeDocument, isLoading: knowledgeDocumentLoading } = useGetProjectsIdDocumentsDocumentId(
    organization.slug,
    { document_id: documentId! },
    { query: { enabled: !!documentId } },
  );

  useEffect(() => {
    if (isKnowledgeBaseLoading) return;
    loadFileSystem(knowledgeProject);
  }, [isKnowledgeBaseLoading]);

  const filesUploadProps = useFilesUpload({ uploadFiles });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClick, ...rootProps } = filesUploadProps.getRootProps();

  const onFileOpen = useCallback((id: string) => {
    setDocumentId(id);
  }, []);

  const onViewerClose = () => {
    setDocumentId(null);
  };

  const onNodeClick = useCallback((node: NodeApi<TreeFileSystemNode>) => onFileOpen(node.id), [onFileOpen]);

  if (isKnowledgeBaseLoading) return <CircularProgress sx={{ m: 2 }} />;

  return (
    <Stack data-folderid="root" sx={{ pb: 2 }}>
      <DocumentUpload {...filesUploadProps} importDriveFiles={importDriveFiles} />

      <Box sx={{ display: 'flex', gap: 1, minHeight: 0, height: 340 }}>
        <FilesTree
          treeNodesByIdRef={nodesByIdRef}
          sx={{ width: '100%' }}
          slug={organization.slug}
          dropzoneRootProps={rootProps}
          openFilesDialog={filesUploadProps.openWithId}
          fileSystemNodes={fileSystemNodes}
          onRename={onRename}
          onMove={onMove}
          onFolderCreate={onFolderCreate}
          onDelete={onDelete}
          onNodeClick={onNodeClick}
          onAddVersion={onAddVersion}
          onDocumentConvertToPage={onDocumentConvertToPage}
        />

        {selectedDocument && selectedDocumentVersions && (
          <VersionsUpload
            sx={{ width: '40%' }}
            document={selectedDocument}
            versionsNodes={selectedDocumentVersions}
            uploadVersion={uploadVersion}
            importDriveFiles={importDriveFiles}
            onVersionClick={onFileOpen}
            onClose={onVersionsClose}
            deleteDocumentVersion={deleteDocumentVersion}
            setAsMainDocumentVersion={setAsMainDocumentVersion}
          />
        )}
      </Box>

      <Dialog
        width={1000}
        open={!!documentId}
        onClose={onViewerClose}
        sx={{ backgroundColor: alpha(`${palette.text.secondary}`, 0.5) }}
        PaperProps={{ sx: { '&.MuiPaper-root': { p: 0, border: 'none', boxShadow: 1 } } }}
      >
        {knowledgeDocumentLoading ? (
          <Box sx={{ height: 700, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            width="100%"
            height={700}
            component="iframe"
            border="0"
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(knowledgeDocument?.url ?? '')}&embedded=true`}
          />
        )}
      </Dialog>
    </Stack>
  );
};

export default OrganizationalDocuments;
