import { ButtonBase, Checkbox, Grid, IconButton, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Control, useController, useFormContext } from 'react-hook-form';
import { FC, KeyboardEvent } from 'react';
import { Key } from 'ts-key-enum';
import Icon from '@/components/Icon/Icon';
import { ChecklistFormValue } from '@/views/Project/components/ChecklistsManager/ChecklistsManagerDialog';
import Cell from '@/views/Projects/components/Cell/Cell';
import TextInput from '@/components/TextInput/TextInput';
import { Tag } from '@/api/generated';
import { useOrganizationTags } from '@/views/Project/hooks/useOrganizationTags';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TagSelectSized from '@/components/TagSelect/TagSelectSized';

interface QuestionRowProps {
  control: Control<ChecklistFormValue>;
  fieldKey: `checklistQuestions.${number}`;
  id: string;
  isSelected: boolean;
  canEdit?: boolean;
  onQuestionToggle: (id?: string) => void;
  onUpdateQueryTags: (newTag: Tag, queryToUpdateId: string) => Promise<void>;
  onDeleteTag: (tag: Tag, id?: string) => Promise<void>;
  onEnterOnLastField: () => void;
  onDelete: () => void;
  onBlur: () => void;
}

const QuestionRow: FC<QuestionRowProps> = ({
  control,
  fieldKey,
  id,
  canEdit,
  isSelected,
  onQuestionToggle,
  onUpdateQueryTags,
  onDeleteTag,
  onEnterOnLastField,
  onDelete,
  onBlur,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation('project');
  const { field } = useController({ control, name: fieldKey });
  const { setFocus, getValues } = useFormContext<ChecklistFormValue>();
  const { queryTags: organizationTags, refetchQueryTags } = useOrganizationTags();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const commonActionsStyle = {
    color: palette.grey[900],
    '&:hover': { backgroundColor: 'transparent' },
  };

  const commonFieldsStyle = {
    color: palette.grey[900],
    border: '1px solid transparent',
    '& .MuiInputBase-input': { fontSize: 'body2.fontSize' },
    '&.Mui-focused': {
      border: `1px solid ${palette.grey[200]}`,
    },
  };

  const handleQuestionKeyDown = (event: KeyboardEvent) => {
    if (event.code !== Key.Enter) return;
    setFocus(`${fieldKey}.reference`);
  };

  const handleReferenceKeyDown = (event: KeyboardEvent) => {
    if (event.code !== Key.Enter) return;
    event.preventDefault();

    const questionValue = getValues(`${fieldKey}.question`);
    if (questionValue.trim()) {
      onEnterOnLastField();
    }
  };

  const onTagAdd = async (name: string) => {
    const existedTag = organizationTags?.find(tag => tag.name === name);
    if (!existedTag) return;

    const tags = [...(field.value.tags ?? []), existedTag];
    field.onChange({ ...field.value, tags });

    await onUpdateQueryTags(existedTag, field.value._id!);
    await refetchQueryTags();
  };

  const onTagDelete = async (name: string) => {
    const existedTag = organizationTags?.find(tag => tag.name === name);
    if (!existedTag) return;

    const tags = (field.value.tags ?? []).filter(tag => tag.name !== name);
    field.onChange({ ...field.value, tags });

    await onDeleteTag(existedTag, field.value._id!);
    await refetchQueryTags();
  };

  const onTagEnter = async (name: string) => {
    const newTag = { name, type: 'query' as const };
    const tags = [...(field.value.tags ?? []), newTag];
    field.onChange({ ...field.value, tags });

    await onUpdateQueryTags(newTag, field.value._id!);
    await refetchQueryTags();
  };

  return (
    <Grid
      key={`${fieldKey}.question`}
      container
      item
      xs={12}
      sx={{
        position: 'relative',
        borderBottom: `1px solid ${palette.custom.separationLine}`,
        color: palette.text.secondary,
        textDecoration: 'none',
        backgroundColor: palette.grey[25],
        '&:nth-child(odd)': { backgroundColor: palette.background.default },
        '&:last-child , &:last-child ': {
          border: 0,
        },
        '&:hover': {
          backgroundColor: palette.controls.hover,
          '.row-actions, .QuestionRow__dragHandle': { display: !canEdit ? 'none' : 'flex' },
        },
        px: 3,
        py: 0,
      }}
      alignItems="center"
      style={style}
      ref={setNodeRef}
    >
      <Cell xs={0.5}>
        <ButtonBase
          className="QuestionRow__dragHandle"
          {...attributes}
          {...listeners}
          sx={{ position: 'absolute', left: 4, top: 0, bottom: 0, margin: 'auto', display: 'none', height: 20 }}
        >
          <Icon name="moveDots" />
        </ButtonBase>
        <Checkbox
          checked={isSelected}
          size="large"
          sx={{ p: 0 }}
          disableRipple
          onChange={() => onQuestionToggle(field.value._id)}
        />
      </Cell>
      <Cell xs={3.5} allowEllipsis fontWeight="fontWeightBold" fontSize="h3.fontSize" py={1}>
        <TextInput
          placeholder={t('queries.checklistsEditDialog.questions.placeholders.question')}
          control={control}
          id={`${fieldKey}.question`}
          name={`${fieldKey}.question`}
          hideErrors
          translate="project"
          multiline
          InputProps={{
            sx: {
              backgroundColor: 'transparent',
              fontWeight: 'fontWeightBold',
              '&.Mui-error': {
                '& .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${palette.error.main}`,
                },
              },
              ...commonFieldsStyle,
            },
          }}
          onBlur={onBlur}
          onKeyDown={handleQuestionKeyDown}
          disabled={!canEdit}
        />
      </Cell>
      <Cell xs={5} allowEllipsis sx={{ textTransform: 'capitalize' }} py={1}>
        <TextInput
          placeholder={t('queries.checklistsEditDialog.questions.placeholders.reference')}
          control={control}
          id={`${fieldKey}.reference`}
          name={`${fieldKey}.reference`}
          translate="project"
          InputProps={{
            sx: {
              backgroundColor: 'transparent',
              ...commonFieldsStyle,
            },
          }}
          onBlur={onBlur}
          onKeyDown={handleReferenceKeyDown}
          disabled={!canEdit}
        />
      </Cell>

      <Cell xs={2.5}>
        <Stack flexDirection="row" gap={0.5} flexWrap="wrap" py={1} pl={1} alignItems="center">
          <TagSelectSized
            isDisabled={!field.value._id || !canEdit}
            visibleTagsLimit={3}
            isHighlighted
            tags={organizationTags}
            selectedTags={field.value.tags?.map(tag => tag.name) ?? []}
            onTagAdd={name => onTagAdd(name)}
            onTagDelete={name => onTagDelete(name)}
            onEnter={name => onTagEnter(name)}
          />
        </Stack>
      </Cell>
      <Grid item xs={0.5} className="actions">
        <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" className="row-actions" sx={{ display: 'none' }}>
          <IconButton sx={commonActionsStyle} size="small" onClick={onDelete}>
            <Icon name="bin" />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default QuestionRow;
