import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@/store';

export type HelpDialogState = {
  isOpened: boolean;
};

export const helpDialogSlice = createSlice({
  name: 'helpDialog',
  initialState: { isOpened: false } as HelpDialogState,
  reducers: {
    toggleHelpDialogVisible: state => ({
      ...state,
      isOpened: !state.isOpened,
    }),
  },
});

export const { toggleHelpDialogVisible } = helpDialogSlice.actions;

export const selectHelpDialog = (state: RootState) => state.helpDialog;
