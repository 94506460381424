import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Popover, Skeleton } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { bindPopover, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { $getNodeByKey, COMMAND_PRIORITY_LOW, KEY_BACKSPACE_COMMAND, KEY_DELETE_COMMAND } from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useTemplateContext } from '@/containers/PagesEditor/context/TemplateContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import RoundedMentionInput from '@/components/RoundedMentionInput/RoundedMentionInput';
import { $isInlineAiNode } from '@/containers/PagesEditor/nodes/InlineAiNode';
import nextTick from '@/services/nextTick';

interface InlineAiBlockProps {
  nodeKey: string;
  initialPrompt?: string;
}

const InlineAiBlock: FC<InlineAiBlockProps> = ({ nodeKey, initialPrompt = '' }) => {
  const { t } = useTranslation('lexicalEditor');
  const [editor] = useLexicalComposerContext();
  const [isSelected, setSelected] = useLexicalNodeSelection(nodeKey);
  const popupState = usePopupState({ variant: 'popover', popupId: `InlineAiBlock_${nodeKey}` });
  const { processStatus } = useTemplateContext();

  const [prompt, setPrompt] = useState(initialPrompt);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const handleRemove = useCallback(
    (event?: MouseEvent) => {
      event?.stopPropagation();
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if ($isInlineAiNode(node)) {
          node.remove();
        }
      });
    },
    [editor, nodeKey],
  );

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          KEY_DELETE_COMMAND,
          () => {
            isSelected && handleRemove();
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          KEY_BACKSPACE_COMMAND,
          () => {
            isSelected && handleRemove();
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [editor, isSelected, handleRemove, setSelected],
  );

  useEffect(() => {
    nextTick(() => {
      const textarea = inputRef.current;
      if (!textarea) return;

      textarea.focus();
      textarea.selectionStart = textarea.value.length;
    });
  }, [popupState.isOpen]);

  const handlePromptChange = (nextPrompt: string) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isInlineAiNode(node)) {
        node.setPrompt(nextPrompt);
      }
    });
    setPrompt(nextPrompt);
  };

  if (processStatus === 'ready') return null;
  if (processStatus === 'in-progress')
    return <Skeleton variant="rectangular" sx={{ display: 'inline-block', width: 100, height: 14, mx: 1, borderRadius: 1 }} />;

  return (
    <>
      <IconButton color="primary" size="small" {...bindToggle(popupState)}>
        <Icon name="ai" fontSize="small" />
      </IconButton>

      <Popover {...bindPopover(popupState)}>
        <RoundedMentionInput
          placeholder={t('editor.ai.promptPlaceholder')}
          ref={inputRef}
          ContainerProps={{ sx: { width: 300, boxShadow: 0 } }}
          value={prompt}
          featureName="create"
          onChange={(_, nextPrompt) => handlePromptChange(nextPrompt)}
        />
      </Popover>
    </>
  );
};

export default InlineAiBlock;
