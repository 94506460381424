import { Button, InputAdornment, Stack, SxProps, Tooltip, useTheme } from '@mui/material';
import { FC, useCallback, useRef, useState, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { DropzoneRootProps } from 'react-dropzone';
import {
  CreateHandler,
  DeleteHandler,
  MoveHandler,
  NodeApi,
  NodeRendererProps,
  RenameHandler,
  Tree,
  TreeApi,
} from 'react-arborist';
import { useResizeObserver } from 'usehooks-ts';
import TreeDocumentNode from '@/views/Projects/components/ProjectFormDialog/components/FilesTree/TreeDocumentNode';
import Icon from '@/components/Icon/Icon';
import { TreeFileSystemNode } from '@/types';
import RoundedInput from '@/components/RoundedInput';
import { FilesTreeHeader } from '@/views/Projects/components/ProjectFormDialog/components/FilesTree/FilesTreeHeader';
import { useAppSelector } from '@/store';
import { selectSelectedNodeIds } from '@/store/fileTreeSlice';
import ActionButton from '@/components/ActionButton';

interface FilesTreeProps {
  sx?: SxProps;
  slug?: string;
  dropzoneRootProps: DropzoneRootProps;
  fileSystemNodes: TreeFileSystemNode[];
  treeNodesByIdRef: MutableRefObject<Record<string, TreeFileSystemNode>>;
  openFilesDialog: (id: string) => void;
  onRename: RenameHandler<TreeFileSystemNode>;
  onMove: MoveHandler<TreeFileSystemNode>;
  onFolderCreate: CreateHandler<TreeFileSystemNode>;
  onDelete: DeleteHandler<TreeFileSystemNode>;
  onNodeClick: (node: NodeApi<TreeFileSystemNode>) => void;
  onAddVersion: (node: NodeApi<TreeFileSystemNode>) => void;
  onDocumentConvertToPage: (documentId: string) => void;
  onDeleteSelected?: () => void;
  isSidebarOpen?: boolean;
}

const FilesTree: FC<FilesTreeProps> = ({
  sx,
  slug,
  dropzoneRootProps,
  fileSystemNodes,
  treeNodesByIdRef,
  openFilesDialog,
  onRename,
  onMove,
  onFolderCreate,
  onDelete,
  onNodeClick,
  onAddVersion,
  onDocumentConvertToPage,
  onDeleteSelected,
  isSidebarOpen,
}) => {
  const { t } = useTranslation('projectUpdate');
  const { palette, spacing } = useTheme();

  const [searchTerm, setSearchTerm] = useState('');
  const treeRef = useRef<TreeApi<TreeFileSystemNode> | null>(null);
  const treeWrapRef = useRef<HTMLDivElement | null>(null);
  const { width = 0, height = 0 } = useResizeObserver({
    ref: treeWrapRef,
    box: 'border-box',
  });

  const selectedNodesIds = useAppSelector(state => selectSelectedNodeIds(state, slug));

  const onAddFile = useCallback(
    (node: NodeApi<TreeFileSystemNode>) => {
      openFilesDialog(node.id);
    },
    [openFilesDialog],
  );

  const TreeDocumentNodeWrap = useCallback(
    (nodeProps: NodeRendererProps<TreeFileSystemNode>) => (
      <TreeDocumentNode
        {...nodeProps}
        slug={slug}
        onAddFile={onAddFile}
        onNodeClick={onNodeClick}
        onAddVersion={onAddVersion}
        onDocumentConvertToPage={onDocumentConvertToPage}
      />
    ),
    [onDelete, onAddFile, onNodeClick, onAddVersion, onDocumentConvertToPage],
  );

  return (
    <Stack
      sx={{
        ...sx,
        minWidth: 400,
        minHeight: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        borderRadius: 4,
        boxShadow: 6,
      }}
      {...dropzoneRootProps}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={0.5}
        sx={{ width: '100%', py: 1.5, px: isSidebarOpen ? 4 : 0 }}
      >
        {!!selectedNodesIds.length && onDeleteSelected && (
          <Tooltip arrow title={t('uploadFiles.deleteSelected')}>
            <ActionButton
              sx={{
                minWidth: 24,
                mr: 1,
                color: palette.grey[700],
                '&:hover': {
                  boxShadow: 0,
                  backgroundColor: palette.grey[100],
                  color: palette.grey[700],
                },
              }}
              size="2xsmall"
              onClick={onDeleteSelected}
            >
              <Icon name="bin" fontSize="medium" />
            </ActionButton>
          </Tooltip>
        )}

        <RoundedInput
          sx={{
            width: 180,
            height: 24,
            '.MuiInputBase-root.MuiOutlinedInput-root': {
              px: 0.5,
              height: '100%',
              boxShadow: 'none',
              borderRadius: 999,
              backgroundColor: palette.background.default,
              border: `1px solid ${palette.primary.light}`,
            },
            '.MuiInputBase-input': { p: 0, fontSize: 'body2.fontSize' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ color: palette.grey[700] }} position="start">
                <Icon name="search" fontSize="small" sx={{ color: palette.primary.contrastText }} />
              </InputAdornment>
            ),
          }}
          placeholder={t('uploadFiles.searchPlaceholder')}
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
        />

        <Button
          title={t('uploadFiles.addFolder')}
          variant="contained"
          size="2xsmall"
          color="accent"
          sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center',
            px: 1.5,
            borderRadius: 999,
          }}
          onClick={() => treeRef.current?.create()}
        >
          <Icon name="folder" fontSize="small" />
          {t('uploadFiles.newFolder')}
        </Button>
      </Stack>

      <FilesTreeHeader slug={slug} fileSystemNodes={fileSystemNodes} treeNodesByIdRef={treeNodesByIdRef} />

      <Stack
        ref={treeWrapRef}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          minHeight: 0,
          '& > div > div': { scrollbarWidth: 'thin' },
          '& [role="treeitem"]': { outline: 'none' },
        }}
      >
        {!fileSystemNodes.length && (
          <Stack
            sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, justifyContent: 'center', textAlign: 'center' }}
          >
            {t('uploadFiles.noFiles')}
          </Stack>
        )}
        <Tree
          ref={treeRef}
          dndRootElement={dropzoneRootProps.ref.current}
          data={fileSystemNodes}
          width={width}
          height={height}
          openByDefault={false}
          paddingBottom={parseInt(spacing(2))}
          rowHeight={40}
          searchTerm={searchTerm}
          searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}
          idAccessor={node => node.id}
          disableDrop={({ parentNode }) => !parentNode.isRoot && parentNode.data.type !== 'folder'}
          childrenAccessor="children"
          onMove={onMove}
          onCreate={onFolderCreate}
          onRename={onRename}
          onDelete={onDelete}
        >
          {TreeDocumentNodeWrap}
        </Tree>
      </Stack>
    </Stack>
  );
};

export default FilesTree;
