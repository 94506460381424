import { Instance } from '@nutrient-sdk/viewer';
import { useEffect } from 'react';
import { CountingMode, MakeToolbarAction } from '../types';
import { toolbarItemsByKey } from '../utils/toolbarItems';

type Params = {
  instance?: Instance;
  mode: CountingMode;
  makeToolbarAction: MakeToolbarAction;
};

export function useKeyboardShortcuts({ instance, mode, makeToolbarAction }: Params) {
  useEffect(() => {
    if (!instance) return;

    const isInteractionDisabled = () =>
      instance.contentDocument.querySelector('.PSPDFKit-Container')?.classList.contains('interactions-disabled') ||
      (instance.viewState.interactionMode &&
        ['SEARCH', 'DOCUMENT_EDITOR', 'LINK', 'NOTE', 'CONTENT_EDITOR', 'MEASUREMENT_SETTINGS', 'STAMP_PICKER'].includes(
          instance.viewState.interactionMode,
        ));

    const keyDownHandler = (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;
      const isTypingInField =
        target.contentEditable === 'true' || ['INPUT', 'TEXTAREA', 'SELECT'].includes(target.tagName.toUpperCase());

      if (event.metaKey || event.ctrlKey || isInteractionDisabled() || mode !== CountingMode.REGULAR || isTypingInField) {
        return;
      }

      const toolbarActions = toolbarItemsByKey[event.code.replace('Key', '')];
      toolbarActions && event.preventDefault();
      if (!toolbarActions) return;

      const toolbarItem = toolbarActions.find(action => action.shiftKey === event.shiftKey);
      if (!toolbarItem?.itemKey || toolbarItem.disabled) return;

      makeToolbarAction(toolbarItem.itemKey);
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [instance, mode, makeToolbarAction]);
}
