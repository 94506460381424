import { useEffect } from 'react';
import { CountingMode } from '@/containers/DocumentEditor/types';
import { Instance, ViewState } from '@nutrient-sdk/viewer';
import isMeasurementMode from '@/containers/DocumentEditor/utils/isMeasurementMode';
import { EquipmentDescriptionSearch } from '@/api/generated';

type Params = {
  instance?: Instance;
  mode: CountingMode;
  toolbarCategory: EquipmentDescriptionSearch | null;
  toggleInteractions: (isEnabled: boolean) => void;
};

export const useNoInteractionsWithoutCategory = ({ instance, mode, toolbarCategory, toggleInteractions }: Params) => {
  useEffect(() => {
    if (!instance) return;

    const handleViewState = (viewState: ViewState) => {
      if (isMeasurementMode(viewState.interactionMode)) {
        toggleInteractions(!!toolbarCategory);
      } else {
        toggleInteractions(true);
      }
    };

    handleViewState(instance.viewState);
    instance.addEventListener('viewState.change', handleViewState);

    return () => {
      instance.removeEventListener('viewState.change', handleViewState);
    };
  }, [instance, mode, toolbarCategory]);
};
