import { FC, PropsWithChildren, useRef, useState } from 'react';
import {
  PopupDocumentsInProgressContext,
  PopupDocumentsInProgressContextValue,
  PopupDocumentsInProgressOptions,
} from './PopupDocumentsInProgressContext';
import PopupDocumentsInProgress from './PopupDocumentsInProgress';

export const PopupDocumentsInProgressProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const resolveRef = useRef<(result: boolean) => void>(() => false);
  const optionsRef = useRef<PopupDocumentsInProgressOptions>();

  const contextValue: PopupDocumentsInProgressContextValue = {
    showPopupDocumentsInProgress: options => {
      setIsOpened(true);
      optionsRef.current = options;
      return new Promise<boolean>(resolve => (resolveRef.current = resolve));
    },
  };

  const onDialogClose = (result: boolean) => {
    resolveRef.current(result);
    setIsOpened(false);
  };

  return (
    <PopupDocumentsInProgressContext.Provider value={contextValue}>
      {optionsRef.current && (
        <PopupDocumentsInProgress
          isOpened={isOpened}
          action={optionsRef.current.action}
          onClose={onDialogClose}
          onProceed={optionsRef.current.onProceed}
        />
      )}
      {children}
    </PopupDocumentsInProgressContext.Provider>
  );
};
