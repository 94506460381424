import { MultilineElementTransformer } from '@lexical/markdown';
import { LexicalNode } from 'lexical';
import { $createAiNode } from '@/containers/PagesEditor/nodes/AiNode';
import { $createAiContainerNode, $isAiContainerNode, AiContainerNode } from '@/containers/PagesEditor/nodes/AiContainerNode';
import { $createAiContentNode } from '@/containers/PagesEditor/nodes/AiContentNode';

const CONTAINER_REGEXP_START = /<block id="(.+?)">/;
const CONTAINER_REGEXP_END = /<\/block>/;

export const AI_TRANSFORMER: MultilineElementTransformer = {
  dependencies: [AiContainerNode],
  export: (node: LexicalNode) => {
    if (!$isAiContainerNode(node)) return null;
    return `<block id="${node.getUuid()}"></block>\n`;
  },
  regExpStart: CONTAINER_REGEXP_START,
  regExpEnd: CONTAINER_REGEXP_END,
  replace: parentNode => {
    // TODO this is not correct, in case we need the right one, need to ask backend to change its parsing.
    const container = $createAiContainerNode();
    container.append($createAiNode({ prompt: '', answerType: 'short_block' }), $createAiContentNode());
    parentNode.append(container);
  },
  type: 'multiline-element',
};
