import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Skeleton, SxProps, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { mergeRegister } from '@lexical/utils';
import { bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { bindMenu } from 'material-ui-popup-state';
import { $getNodeByKey, COMMAND_PRIORITY_LOW, KEY_BACKSPACE_COMMAND, KEY_DELETE_COMMAND, NodeKey } from 'lexical';
import { DateVariableType, VariableType } from '@/containers/PagesEditor/types';
import Icon from '@/components/Icon/Icon';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isVariableNode } from '@/containers/PagesEditor/nodes/VariableNode';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { useTemplateContext } from '@/containers/PagesEditor/context/TemplateContext';

interface VariableBlockProps {
  uuid: string;
  type: VariableType;
  initialDateType: DateVariableType;
  nodeKey: NodeKey;
}

const VariableBlock: FC<VariableBlockProps> = ({ type, initialDateType, nodeKey }) => {
  const { t } = useTranslation('templates');
  const { palette } = useTheme();
  const [editor] = useLexicalComposerContext();
  const { processStatus } = useTemplateContext();
  const [isSelected, setSelected] = useLexicalNodeSelection(nodeKey);
  const popupState = usePopupState({ variant: 'popover', popupId: `variable-block-${nodeKey}` });

  const [dateType, setDateType] = useState(initialDateType);

  const commonStyles = {
    position: 'relative',
    width: 220,
    my: 1,
    px: 1.5,
    py: 0.5,
    outline: '1px solid',
    outlineColor: isSelected ? palette.primary.light : 'transparent',
    border: '1px solid',
    borderColor: palette.primary.light,
    borderRadius: 2,
    fontSize: 'body2.fontSize',
    color: palette.grey[900],
    cursor: 'pointer',
    '.VariableBlock__remove': isSelected ? { display: 'inline-flex' } : undefined,
    '&:hover': { '.VariableBlock__remove': { display: 'inline-flex' } },
  } satisfies SxProps;
  const isDropdownType = type === 'date';

  const handleRemove = useCallback(
    (event?: MouseEvent) => {
      event?.stopPropagation();
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if ($isVariableNode(node)) {
          node.remove();
        }
      });
      return false;
    },
    [editor, nodeKey],
  );

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    setSelected(true);
  };

  const handleSelectDateType = (nextDateType: DateVariableType) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isVariableNode(node)) {
        node.setDateType(nextDateType);
      }
    });
    setDateType(nextDateType);
    popupState.close();
  };

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(KEY_DELETE_COMMAND, () => isSelected && handleRemove(), COMMAND_PRIORITY_LOW),
        editor.registerCommand(KEY_BACKSPACE_COMMAND, () => isSelected && handleRemove(), COMMAND_PRIORITY_LOW),
      ),
    [editor, isSelected, handleRemove, setSelected],
  );

  if (!type) return null;

  const renderMenu = () => {
    const options =
      type === 'date'
        ? ([
            { label: t('variableBlock.dateTypes.today'), value: 'today' },
            { label: t('variableBlock.dateTypes.project_creation_date'), value: 'project_creation_date' },
            { label: t('variableBlock.dateTypes.project_last_modified'), value: 'project_last_modified' },
          ] as const)
        : [];

    return (
      <Menu
        {...bindMenu(popupState)}
        slotProps={{
          paper: {
            sx: {
              mt: 1,
              borderRadius: 1,
              boxShadow: 2,
              backgroundColor: palette.background.default,
            },
          },
        }}
      >
        {options.map(option => (
          <MenuItem key={option.value} onClick={() => handleSelectDateType(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const renderCloseButton = () => (
    <IconButton
      className="VariableBlock__remove"
      sx={{
        position: 'absolute',
        right: -6,
        top: -6,
        display: 'none',
        p: 0,
        width: 17,
        height: 17,
        '&, &:hover': { backgroundColor: palette.background.default },
      }}
      onClick={handleRemove}
    >
      <Icon name="x" sx={{ fontSize: 12 }} />
    </IconButton>
  );

  const renderDropdown = () => {
    const dateTitleTranslations = {
      today: t('variableBlock.dateTypes.today'),
      project_creation_date: t('variableBlock.dateTypes.project_creation_date'),
      project_last_modified: t('variableBlock.dateTypes.project_last_modified'),
    };

    return (
      <>
        <Box sx={commonStyles} {...bindToggle(popupState)}>
          <Trans components={{ strong: <Box component="strong" sx={{ fontWeight: 700 }} /> }}>
            {t('variableBlock.date', { type: dateTitleTranslations[dateType] })}
          </Trans>
          <Icon name="arrowDown" fontSize="small" sx={{ position: 'absolute', top: 0, bottom: 0, right: 10, m: 'auto' }} />
          {renderCloseButton()}
        </Box>
        {renderMenu()}
      </>
    );
  };

  const renderBlock = () => {
    const titleTranslations = {
      name: t('variableBlock.projectName'),
      address: t('variableBlock.projectAddress'),
      date: t('variableBlock.date'),
    };

    return (
      <Box sx={commonStyles} onClick={handleClick}>
        <Trans components={{ strong: <Box component="strong" sx={{ fontWeight: 700 }} /> }}>{titleTranslations[type]}</Trans>
        {renderCloseButton()}
      </Box>
    );
  };

  if (processStatus === 'ready') return null;
  if (processStatus === 'in-progress')
    return <Skeleton variant="rectangular" sx={{ display: 'inline-block', width: 100, height: 14, mx: 1, borderRadius: 1 }} />;

  return isDropdownType ? renderDropdown() : renderBlock();
};

export default VariableBlock;
