import { toOrgPages, toProjectCompare, toProjectPages, toProjectQueries, toProjectTakeoff } from '@/services/linker';
import { ActionProps, ActionType, ProjectFullDefaultDocumentId, SuggestedActionFavoriteActionProps } from '@/api/generated';
import { NavigateFunction, SetURLSearchParams } from 'react-router-dom';
import { HomepageActions } from '@/views/Project/views/Header/types';

export const createActionTypeParams = (
  navigate: NavigateFunction,
  setSearchParams: SetURLSearchParams,
  organizationSlug: string,
  projectSlug: string,
  documentId?: ProjectFullDefaultDocumentId,
) => {
  const setUrlAction = (action: HomepageActions) => {
    setSearchParams(prevParams => {
      const nextSearchParams = new URLSearchParams(prevParams);
      nextSearchParams.set('action', action);
      return nextSearchParams;
    });
  };

  return {
    [ActionType.open_doublecheck]: () => {
      navigate(toProjectQueries({ projectSlug, documentId: documentId! }));
    },
    [ActionType.run_checklist]: () => {
      navigate(toProjectQueries({ projectSlug, documentId: documentId!, action: HomepageActions.OPEN_CHECKLIST_MANAGER_DIALOG }));
    },
    [ActionType.new_create_page]: () => {
      navigate(toProjectPages({ projectSlug, documentId: documentId! }));
    },
    [ActionType.open_create_page]: (actionProps?: ActionProps | SuggestedActionFavoriteActionProps) => {
      const { page_id } = actionProps ?? {};

      if (projectSlug === organizationSlug) {
        navigate(toOrgPages({ pageId: page_id! }));
      } else {
        navigate(toProjectPages({ projectSlug, documentId: documentId!, pageId: page_id! }));
      }
    },
    [ActionType.open_edit_project]: () => {
      setUrlAction(HomepageActions.OPEN_EDIT_PROJECT_DIALOG);
    },
    [ActionType.new_takeoff]: (actionProps?: ActionProps | SuggestedActionFavoriteActionProps) => {
      const { doc_id } = actionProps ?? {};
      navigate(toProjectTakeoff({ projectSlug, documentId: doc_id! }));
    },
    [ActionType.continue_takeoff]: (actionProps?: ActionProps | SuggestedActionFavoriteActionProps) => {
      const { doc_id, page_index } = actionProps ?? {};
      navigate(toProjectTakeoff({ projectSlug, documentId: doc_id!, page: ((page_index ?? 0) + 1)?.toString() }));
    },
    [ActionType.open_orgKB]: () => {
      setUrlAction(HomepageActions.OPEN_KB_DIALOG);
    },
    [ActionType.open_upload_files_modal]: () => {
      setUrlAction(HomepageActions.OPEN_EDIT_PROJECT_DIALOG);
    },
    [ActionType.share_project]: () => {
      setUrlAction(HomepageActions.OPEN_SHARE_DIALOG);
    },
    [ActionType.run_compare]: (actionProps?: ActionProps | SuggestedActionFavoriteActionProps) => {
      const { compare_id } = actionProps ?? {};
      navigate(toProjectCompare({ projectSlug, documentId: documentId!, compareId: compare_id! }));
    },
    [ActionType.view_document]: () => {},
  };
};
