import { Dispatch, forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { EditorState, LexicalEditor } from 'lexical';
import { Box, SxProps } from '@mui/material';
import { $convertToMarkdownString } from '@lexical/markdown';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import AutoEmbedPlugin from '@lexical/playground/plugins/AutoEmbedPlugin';
import AutoLinkPlugin from '@lexical/playground/plugins/AutoLinkPlugin';
import CodeHighlightPlugin from '@lexical/playground/plugins/CodeHighlightPlugin';
import CollapsiblePlugin from '@lexical/playground/plugins/CollapsiblePlugin';
import DraggableBlockPlugin from '@lexical/playground/plugins/DraggableBlockPlugin';
import FigmaPlugin from '@lexical/playground/plugins/FigmaPlugin';
import FloatingLinkEditorPlugin from '@lexical/playground/plugins/FloatingLinkEditorPlugin';
import KeywordsPlugin from '@lexical/playground/plugins/KeywordsPlugin';
import { LayoutPlugin } from '@lexical/playground/plugins/LayoutPlugin/LayoutPlugin';
import LinkPlugin from '@lexical/playground/plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from '@lexical/playground/plugins/ListMaxIndentLevelPlugin';
import MentionsPlugin from '@lexical/playground/plugins/MentionsPlugin';
import PageBreakPlugin from '@lexical/playground/plugins/PageBreakPlugin';
import TabFocusPlugin from '@lexical/playground/plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from '@lexical/playground/plugins/TableActionMenuPlugin';
import TableCellResizer from '@lexical/playground/plugins/TableCellResizer';
import TwitterPlugin from '@lexical/playground/plugins/TwitterPlugin';
import YouTubePlugin from '@lexical/playground/plugins/YouTubePlugin';
import { TableContext } from '@lexical/playground/plugins/TablePlugin';
import { SharedHistoryContext, useSharedHistoryContext } from '@lexical/playground/context/SharedHistoryContext';
import ContentEditable from '@/containers/PagesEditor/ui/ContentEditable';
import ExcalidrawPlugin from '@/containers/PagesEditor/plugins/ExcalidrawPlugin';
import ComponentPickerPlugin from '@/containers/PagesEditor/plugins/ComponentPickerPlugin';
import Nodes from '@/containers/PagesEditor/nodes/Nodes';
import EditorTheme from '@/containers/PagesEditor/themes/EditorTheme';
import { Block } from '@/containers/PagesEditor/types';
import PlaceholderPlugin from '@/containers/PagesEditor/plugins/PlaceholderPlugin/PlaceholderPlugin';
import { PAGES_TRANSFORMERS } from '@/containers/PagesEditor/transformers';
import { createRootNode } from '@/containers/PagesEditor/utils/createRootNode';

import './PageEditor.css';

interface PagesEditorProps {
  sx?: SxProps;
  shellSx?: SxProps;
  initialState?: Block[];
  placeholder?: string;
  children?: ReactNode;
  isReadOnly?: boolean;
  plugins?: (params: { setIsLinkEditMode: Dispatch<boolean> }) => ReactNode;
  pluginsWithAnchor?: (params: { anchorElem: HTMLDivElement; setIsLinkEditMode: Dispatch<boolean> }) => ReactNode;
  onChange: (content: Block[], markdown: string) => void;
}

const PagesEditor = forwardRef<LexicalEditor | null, PagesEditorProps>(
  ({ sx, shellSx, initialState, placeholder, children, isReadOnly, plugins, pluginsWithAnchor, onChange }, editorRef) => {
    const internalEditorRef = useRef<LexicalEditor | null>(null);

    const editorState = useMemo(() => JSON.stringify(createRootNode(initialState)), []);

    const initialConfig: InitialConfigType = {
      namespace: 'PellesEditor',
      nodes: [...Nodes],
      theme: EditorTheme,
      editable: !isReadOnly,
      editorState,
      onError: console.error,
    };

    const { historyState } = useSharedHistoryContext();
    const [anchorElem, setAnchorElem] = useState<HTMLDivElement | null>(null);
    const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

    useImperativeHandle(editorRef, () => internalEditorRef.current!);

    useEffect(() => {
      internalEditorRef.current?.setEditable(!isReadOnly);
    }, [isReadOnly, internalEditorRef]);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
      if (_floatingAnchorElem !== null) {
        setAnchorElem(_floatingAnchorElem);
      }
    };

    const handleChange = (nextEditorState: EditorState) => {
      nextEditorState.read(() => {
        onChange(nextEditorState.toJSON().root.children, $convertToMarkdownString(PAGES_TRANSFORMERS));
      });
    };

    return (
      <LexicalComposer initialConfig={initialConfig}>
        <SharedHistoryContext>
          <TableContext>
            <Box className="editor-shell" sx={{ position: 'relative', overflow: 'auto', ...shellSx }}>
              {plugins?.({ setIsLinkEditMode })}
              <RichTextPlugin
                contentEditable={
                  <ContentEditable ref={onRef} sx={sx}>
                    {children}
                  </ContentEditable>
                }
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
              />
              {anchorElem && (
                <>
                  <DraggableBlockPlugin anchorElem={anchorElem} />
                  {!isReadOnly && placeholder && <PlaceholderPlugin text={placeholder} anchorElem={anchorElem} />}
                  <FloatingLinkEditorPlugin
                    anchorElem={anchorElem}
                    isLinkEditMode={isLinkEditMode}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                  <TableCellActionMenuPlugin anchorElem={anchorElem} cellMerge={true} />
                  {pluginsWithAnchor?.({ anchorElem, setIsLinkEditMode })}
                </>
              )}
              <EditorRefPlugin editorRef={internalEditorRef} />
              <OnChangePlugin ignoreSelectionChange ignoreHistoryMergeTagChange onChange={handleChange} />
              <MarkdownShortcutPlugin transformers={PAGES_TRANSFORMERS} />
              <TablePlugin hasCellMerge hasCellBackgroundColor />
              <ClearEditorPlugin />
              <ComponentPickerPlugin />
              <AutoEmbedPlugin />
              <MentionsPlugin />
              <HashtagPlugin />
              <KeywordsPlugin />
              <AutoLinkPlugin />
              <ExcalidrawPlugin />
              <HistoryPlugin externalHistoryState={historyState} />
              <CodeHighlightPlugin />
              <ListPlugin />
              <CheckListPlugin />
              <ListMaxIndentLevelPlugin maxDepth={7} />
              <TableCellResizer />
              <LinkPlugin />
              <TwitterPlugin />
              <YouTubePlugin />
              <FigmaPlugin />
              <ClickableLinkPlugin />
              <HorizontalRulePlugin />
              <TabFocusPlugin />
              <TabIndentationPlugin />
              <CollapsiblePlugin />
              <PageBreakPlugin />
              <LayoutPlugin />
            </Box>
          </TableContext>
        </SharedHistoryContext>
      </LexicalComposer>
    );
  },
);

export default PagesEditor;
