import { AnnotationBackendJSON } from '@nutrient-sdk/viewer';

const unitTypeByGroupKey = {
  autoAnnotationParent: 'pieces',
  autoAnnotationChild: 'pieces',
  'multi-count': 'pieces',
};

const unitTypeByType = {
  'pspdfkit/shape/line': 'linear',
  'pspdfkit/shape/rectangle': 'area',
  'pspdfkit/shape/ellipse': 'area',
  'pspdfkit/shape/polygon': 'area',
  'pspdfkit/shape/polyline': 'linear',
};

export const getAnnotationUnitType = (annotation: AnnotationBackendJSON) => {
  const key = annotation.customData?.specialType as keyof typeof unitTypeByGroupKey | undefined;
  const unitTypeByGroup = key && unitTypeByGroupKey[key];
  // @ts-expect-error
  return unitTypeByGroup ?? unitTypeByType[annotation.type];
};
