import { FeatureName, MentionType, useGetMentionsByFeature } from '@/api/generated';
import { useMemo } from 'react';
import { SuggestionDataItem } from 'react-mentions';

export interface MentionSuggestionDataItem extends SuggestionDataItem {
  description?: string;
  type: string;
}

export const useMentions = (featureName: FeatureName, projectId?: string) => {
  const { data } = useGetMentionsByFeature({
    project_id: projectId,
    feature_name: featureName,
  });

  const mentions: MentionSuggestionDataItem[] = useMemo(
    () =>
      Object.values(data?.mentions ?? {})
        .flat()
        .map(
          mention =>
            ({
              display: mention.name,
              id: mention.id,
              type: mention.mention_type,
              description: mention.description ?? '',
            }) satisfies MentionSuggestionDataItem,
        ),
    [data],
  );

  const mentionTypes = useMemo(() => Object.keys(data?.mentions ?? {}) as MentionType[], [data]);

  return { mentions, mentionTypes };
};
