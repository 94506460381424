import { FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface RootPortalProps {
  children: ReactNode;
}

const RootPortal: FC<RootPortalProps> = ({ children }) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setRef(document.getElementById('root-portal') as HTMLDivElement | null);
  }, []);

  if (!ref) return null;

  return createPortal(children, ref);
};

export default RootPortal;
