import { createEmptyState } from '@/containers/PagesEditor/utils/createEmptyState';
import { Block } from '@/containers/PagesEditor/types';

export const createRootNode = (initialState?: Block[] | null) => ({
  root: {
    children: initialState?.length ? initialState : createEmptyState(),
    direction: 'ltr',
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
});
