import PSPDFKit, { AnnotationsUnion, IInteractionMode, ILayoutMode, Instance, IScrollMode } from '@nutrient-sdk/viewer';
import downloadFile from '@/utils/downloadFile';
import { EquipmentDescriptionSearch, UnitType } from '@/api/generated';
import { MeasurementParams, SetToolCategoryOptions } from '@/containers/DocumentEditor/types';

type Config = {
  instance: Instance;
  setToolbarCategory: (
    category: EquipmentDescriptionSearch | null,
    options: SetToolCategoryOptions,
  ) => EquipmentDescriptionSearch | null | undefined;
  setMultiCountColor: (color: string) => void;
};

export class ToolbarService {
  constructor(private config: Config) {}

  setPreset(name: string | null) {
    this.config.instance.setCurrentAnnotationPreset(name);
  }

  setToolbarCategory(category: EquipmentDescriptionSearch | null, options: SetToolCategoryOptions) {
    return this.config.setToolbarCategory(category, options);
  }

  setMultiCountColor(color: string) {
    this.config.setMultiCountColor(color);
  }

  setDynamicPreset(presetData: Partial<AnnotationsUnion>, basePreset = 'dynamic') {
    this.config.instance.setAnnotationPresets(prevPresets => ({
      ...prevPresets,
      dynamic: {
        ...prevPresets[basePreset],
        ...presetData,
        customData: { ...prevPresets[basePreset].customData, ...presetData.customData },
      },
    }));
    this.setPreset('dynamic');
  }

  openMeasurementTool(
    interactionMode: IInteractionMode,
    { category, presetData, basePreset, toolType }: MeasurementParams & { basePreset: string; toolType: UnitType },
  ) {
    const nextCategory = this.setToolbarCategory(category ?? null, { toolType });
    const updatedPresetData = nextCategory
      ? {
          ...presetData,
          customData: {
            ...presetData?.customData,
            groupAnnotationKey: nextCategory.equipment_id,
          },
        }
      : presetData ?? {};

    // To get the updated category on the preset, we need to shut down the current interaction mode, set the current preset and turn on the current interaction mode.
    this.toggleInteractionMode(interactionMode, false);
    this.setDynamicPreset(updatedPresetData, basePreset);
    this.toggleInteractionMode(interactionMode, true);
  }

  toggleInteractionMode(mode: IInteractionMode, state?: boolean) {
    this.config.instance.setViewState(viewState =>
      viewState.set('interactionMode', state || (state === undefined && viewState.interactionMode !== mode) ? mode : null),
    );
  }

  setLayoutMode(mode: ILayoutMode) {
    this.config.instance.setViewState(viewState => viewState.set('layoutMode', mode));
  }

  setScrollMode(mode: IScrollMode) {
    this.config.instance.setViewState(viewState => viewState.set('scrollMode', mode));
  }

  toggleScrollMode() {
    this.config.instance.setViewState(viewState =>
      viewState.set('scrollMode', viewState.scrollMode === 'CONTINUOUS' ? 'PER_SPREAD' : 'CONTINUOUS'),
    );
  }

  rotateLeft() {
    this.config.instance.setViewState(viewState => viewState.rotateLeft());
  }

  rotateRight() {
    this.config.instance.setViewState(viewState => viewState.rotateRight());
  }

  zoomIn() {
    this.config.instance.setViewState(viewState => viewState.zoomIn());
  }

  zoomOut() {
    this.config.instance.setViewState(viewState => viewState.zoomOut());
  }

  fitPagetoViewport() {
    this.config.instance.setViewState(viewState => viewState.set('zoom', PSPDFKit.ZoomMode.FIT_TO_VIEWPORT));
  }

  print() {
    this.config.instance.print();
  }

  async downloadFile(filename: string) {
    const buffer = await this.config.instance.exportPDF();
    downloadFile(buffer, filename, 'application/pdf');
  }
}

export const injectToolbarService =
  <T = object>(cb: (service: ToolbarService, params: T) => void) =>
  (config: Config, params: T) =>
    cb(new ToolbarService(config), params);
