import { ElementTransformer } from '@lexical/markdown';
import { $isParagraphNode, LexicalNode } from 'lexical';
import {
  $createInlineAiContainerNode,
  $isInlineAiContainerNode,
  InlineAiContainerNode,
} from '@/containers/PagesEditor/nodes/InlineAiContainerNode';
import { $createInlineAiNode } from '@/containers/PagesEditor/nodes/InlineAiNode';

export const INLINE_AI_TRANSFORMER: ElementTransformer = {
  dependencies: [InlineAiContainerNode],
  export: (node: LexicalNode) => {
    if (!$isParagraphNode(node)) return null;

    return node
      .getChildren()
      .map(child => {
        if ($isInlineAiContainerNode(child)) return `<block id="${child.getUuid()}" inline></block>`;
        return child.getTextContent();
      })
      .join('');
  },
  regExp: /<block id="(.+?)" inline><\/block>/,
  replace: parentNode => {
    // TODO this is not correct, in case we need the right one, need to ask backend to change its parsing.
    const container = $createInlineAiContainerNode();
    container.append($createInlineAiNode({ prompt: '' }));
    parentNode.append(container);
  },
  type: 'element',
};
