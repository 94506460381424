import { FC } from 'react';
import { ButtonBase, ButtonBaseProps, useTheme } from '@mui/material';

interface ToolbarButtonProps extends ButtonBaseProps {
  isActive?: boolean;
}

const ToolbarButton: FC<ToolbarButtonProps> = ({ isActive, sx, ...props }) => {
  const { palette } = useTheme();

  return (
    <ButtonBase
      {...props}
      sx={{
        width: 26,
        height: 26,
        borderRadius: 1,
        backgroundColor: isActive ? palette.grey[300] : 'transparent',
        '&:hover': { backgroundColor: isActive ? palette.grey[300] : palette.grey[50] },
        color: props.disabled ? palette.grey[300] : undefined,
        ...sx,
      }}
      type="button"
    />
  );
};

export default ToolbarButton;
