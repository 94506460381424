import { TabContext, TabPanel } from '@mui/lab';
import { TABS } from '@/views/Knowledge/components/consts';
import { Box, CircularProgress, IconButton, Stack, useTheme } from '@mui/material';
import Chat from '@/views/Knowledge/components/Chat';
import Threads from '@/views/Knowledge/components/Threads';
import KnowledgeBase from '@/views/Knowledge/components/KnowledgeBase';
import { useTranslation } from 'react-i18next';
import { useKnowledge } from '@/views/Knowledge/useKnowledge';
import { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { MessagesValue } from '@/views/Knowledge/OpenKnowledge';
import { FormInputSubmitRef } from '@/components/FormInputSubmit/FormInputSubmit';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { Thread } from '@/api/generated';
import Tabs, { TabsValues } from '@/views/Knowledge/components/Tabs';
import CustomCheckbox from '@/views/Knowledge/components/CustomCheckbox';
import Icon from '@/components/Icon/Icon';
import { usePageTitle } from '@/hooks/usePageTitle';
import { TreeFileSystemNode } from '@/types';
import AppHeader from '@/components/AppHeader';

const tabs: TabsValues[] = [
  { iconName: 'chat', value: TABS.CHAT },
  { iconName: 'history', value: TABS.THREADS },
  { iconName: 'file', value: TABS.KNOWLEDGE_BASE },
];

const HEADER_HEIGHT = 83;

const OrgKnowledgeBase = () => {
  const { t } = useTranslation('common');
  const { palette, spacing } = useTheme();
  const { isThreadLoading, setCurrentThreadId, currentThreadId, isModified, setIsModified, isActive } = useKnowledge();
  const [tabValue, setTabValue] = useState<TABS>(TABS.CHAT);
  const [currentQuestionResponse, setCurrentQuestionResponse] = useState<Thread | undefined>();
  const [messages, setMessages] = useState<MessagesValue[]>([]);
  const [isWeb, setIsWeb] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const formInputSubmitRef = useRef<FormInputSubmitRef | null>(null);
  const { showConfirmDialog } = useConfirmDialog();
  const { setPageTitle } = usePageTitle();
  const treeNodesByIdRef = useRef<Record<string, TreeFileSystemNode>>({});

  const commonTabsStyle = {
    px: 6,
    py: 2,
    overflowY: 'auto',
  };

  useEffect(() => {
    setPageTitle(t('knowledge.pageTitle'));
  }, [setPageTitle, t]);

  const handleTabChange = async (_: SyntheticEvent, newValue: TABS) => {
    const result =
      tabValue === TABS.KNOWLEDGE_BASE && isModified
        ? await showConfirmDialog({
            title: t('knowledge.memos.discardModal.title'),
            confirm: t('knowledge.memos.discardModal.discard'),
            cancel: t('knowledge.memos.discardModal.cancel'),
          })
        : true;

    if (result) {
      setTabValue(newValue);
      setIsModified(false);
    }
  };

  useEffect(() => {
    if (tabValue === TABS.CHAT && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [tabValue]);

  useEffect(() => {
    if (isActive && formInputSubmitRef.current) {
      formInputSubmitRef.current.focus();
    }
  }, [tabValue, isActive]);

  const createNewThread = () => {
    setMessages([]);
    setCurrentQuestionResponse(undefined);
    setCurrentThreadId('');
    setTabValue(TABS.CHAT);
  };

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => setIsWeb(event.target.checked);

  return (
    <Stack sx={{ height: '100%', alignItems: 'stretch' }}>
      <AppHeader icon={<Icon name="orgKb" fontSize="large" />} text={t('knowledge.pageTitle')} />
      <TabContext value={tabValue}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          className="draggable-header"
          sx={{
            borderBottom: 1,
            borderColor: palette.custom.separationLine,
            p: spacing(4.5, 2, 2.5, 2),
            mt: 0.5,
            position: 'sticky',
            top: 0,
            backgroundColor: palette.background.default,
            zIndex: 1,
          }}
        >
          <Tabs tabs={tabs} handleTabChange={handleTabChange} />
          <Stack flexDirection="row" alignItems="center" gap={1.5} sx={{ flex: '1 0 auto' }}>
            <CustomCheckbox checked={isWeb} onChange={handleToggle} label={t('knowledge.toggle.web')} />
            <IconButton sx={{ p: 0.5 }} color="primary" size="small" onClick={createNewThread}>
              <Icon name="newThread" fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
        <TabPanel
          value={TABS.CHAT}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: tabValue === TABS.CHAT ? '100%' : 'auto',
            mt: 'auto',
            p: 0,
            overflow: 'hidden',
          }}
          ref={chatContainerRef}
        >
          {isThreadLoading && currentThreadId ? (
            <Box sx={{ position: 'absolute', left: '50%', top: '50%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Chat
              currentQuestionResponse={currentQuestionResponse}
              tabValue={tabValue}
              setTabValue={setTabValue}
              messages={messages}
              setMessages={setMessages}
              isWeb={isWeb}
              setCurrentQuestionResponse={setCurrentQuestionResponse}
            />
          )}
        </TabPanel>
        <TabPanel sx={{ p: 0, overflowY: 'auto', height: `calc(100% - ${HEADER_HEIGHT}px)` }} value={TABS.THREADS}>
          <Threads setTabValue={setTabValue} tabValue={tabValue} />
        </TabPanel>
        <TabPanel
          sx={{ ...commonTabsStyle, height: `calc(100% - ${HEADER_HEIGHT}px)`, pl: 3, pr: 4 }}
          value={TABS.KNOWLEDGE_BASE}
        >
          <KnowledgeBase nodesByIdRef={treeNodesByIdRef} />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default OrgKnowledgeBase;
