import { $applyNodeReplacement, ElementNode, LexicalNode, NodeKey, type SerializedElementNode, Spread } from 'lexical';

export type SerializedInlineAiContainerNode = Spread<{ uuid: string }, SerializedElementNode>;

export class InlineAiContainerNode extends ElementNode {
  /** @internal */
  __uuid: string;

  static getType() {
    return 'inline-ai-container';
  }

  static clone(node: InlineAiContainerNode): InlineAiContainerNode {
    return new InlineAiContainerNode(node.__uuid, node.__key);
  }

  static importJSON(node: SerializedInlineAiContainerNode): InlineAiContainerNode {
    return $createInlineAiContainerNode(node.uuid);
  }

  constructor(uuid?: string, key?: NodeKey) {
    super(key);
    this.__uuid = uuid ?? crypto.randomUUID();
  }

  getUuid(): string {
    return this.__uuid;
  }

  setUuid(uuid: string) {
    const writable = this.getWritable();
    writable.__uuid = uuid;
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.classList.add('InlineAiContainerNode');
    return element;
  }

  updateDOM() {
    return false;
  }

  canBeEmpty() {
    return false;
  }

  canInsertTextBefore() {
    return false;
  }

  canInsertTextAfter() {
    return false;
  }

  isInline() {
    return true;
  }

  exportJSON(): SerializedInlineAiContainerNode {
    return {
      ...super.exportJSON(),
      type: 'inline-ai-container',
      version: 1,
      uuid: this.getUuid(),
    };
  }
}

export function $createInlineAiContainerNode(uuid?: string) {
  return $applyNodeReplacement(new InlineAiContainerNode(uuid));
}

export function $isInlineAiContainerNode(node: LexicalNode | null | undefined): node is InlineAiContainerNode {
  return node instanceof InlineAiContainerNode;
}
