import { getCollapsedMargins } from '@/containers/PagesEditor/utils/getCollapsedMargins';
import caretFromPoint from 'shared/caretFromPoint';

const SPACE = 4;
const TARGET_LINE_HALF_HEIGHT = 2;
const TEXT_BOX_HORIZONTAL_PADDING = 28;

export const setTargetLine = ({
  targetLineElem,
  targetBlockElem,
  anchorElem,
  mouseY,
}: {
  targetLineElem: HTMLElement;
  targetBlockElem: HTMLElement;
  anchorElem: HTMLElement;
  mouseY: number;
}) => {
  const { top: targetBlockElemTop, height: targetBlockElemHeight } = targetBlockElem.getBoundingClientRect();
  const { top: anchorTop, width: anchorWidth } = anchorElem.getBoundingClientRect();
  const { marginTop, marginBottom } = getCollapsedMargins(targetBlockElem);
  let lineTop = targetBlockElemTop;
  if (mouseY >= targetBlockElemTop) {
    lineTop += targetBlockElemHeight + marginBottom / 2;
  } else {
    lineTop -= marginTop / 2;
  }

  const top = lineTop - anchorTop - TARGET_LINE_HALF_HEIGHT;
  const left = TEXT_BOX_HORIZONTAL_PADDING - SPACE;

  targetLineElem.style.transform = `translate(${left}px, ${top}px)`;
  targetLineElem.style.width = `${anchorWidth - (TEXT_BOX_HORIZONTAL_PADDING - SPACE) * 2}px`;
  targetLineElem.style.opacity = '.4';
};

export const setTargetHorizontalLine = ({
  targetLineElem,
  anchorElem,
  mouseX,
  mouseY,
}: {
  targetLineElem: HTMLElement;
  anchorElem: HTMLElement;
  mouseX: number;
  mouseY: number;
}) => {
  const caret = caretFromPoint(mouseX, mouseY);
  if (!caret) return;

  const range = new Range();
  range.setEnd(caret.node, caret.offset);
  range.setStart(caret.node, caret.offset);

  const { top: anchorTop, left: anchorLeft } = anchorElem.getBoundingClientRect();
  const { top: caretTop, left: caretLeft, height: caretHeight } = range.getBoundingClientRect();
  const nodeElement = caret.node.nodeType === 1 ? (caret.node as HTMLElement) : null;
  const nodeBounds = nodeElement?.getBoundingClientRect();

  const height = caretHeight || nodeBounds?.height;

  const relativeTop = caretTop === 0 && nodeBounds ? nodeBounds.top : caretTop;
  const top = relativeTop - anchorTop;

  const relativeLeft = caretLeft === 0 && nodeBounds ? nodeBounds.left : caretLeft;
  const additionalOffset = caretLeft === 0 && caret.offset === 1 && nodeBounds ? nodeBounds.width : 0;
  const left = relativeLeft - anchorLeft + additionalOffset;

  targetLineElem.style.transform = `translate(${left}px, ${top}px)`;
  targetLineElem.style.height = `${height}px`;
  targetLineElem.style.opacity = '.4';

  return caret;
};

export const hideTargetLine = (targetLineElem: HTMLElement | null) => {
  if (targetLineElem) {
    targetLineElem.style.opacity = '0';
    targetLineElem.style.transform = 'translate(-10000px, -10000px)';
  }
};
