import { Button, MenuItem, SxProps } from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Icon, { IconNames } from '@/components/Icon/Icon';
import { StyledMenu } from '@/components/StyledMenu';
import { AiAnswerType } from '@/containers/PagesEditor/types';

interface AiAnswerTypeSelectProps {
  sx?: SxProps;
  value?: AiAnswerType;
  onChange: (answerType: AiAnswerType) => void;
}

const AiAnswerTypeSelect: FC<AiAnswerTypeSelectProps> = ({ sx, value = 'short_block', onChange }) => {
  const { t } = useTranslation('templates');
  const popupState = usePopupState({ variant: 'popover', popupId: 'ai-answer-type' });

  const labels: Record<string, string> = {
    short_block: t('aiBlock.answerType.short_block'),
    long_block: t('aiBlock.answerType.long_block'),
    table: t('aiBlock.answerType.table'),
    inline_block: t('aiBlock.answerType.inline_block'),
  };

  const handleClick = (nextValue: AiAnswerType) => () => {
    onChange(nextValue);
    popupState.close();
  };

  const iconsMap: Record<AiAnswerType, IconNames> = {
    short_block: 'shortParagraphAi',
    long_block: 'paragraphAi',
    table: 'table',
    inline_block: 'inlineAi',
    stakeholder: 'plus',
  };

  return (
    <>
      <Button
        {...bindTrigger(popupState)}
        sx={{ gap: 0.5, pl: 1, pr: 0.5, height: 20, fontSize: 'body3.fontSize', ...sx }}
        variant="outlined"
        color="secondary"
        size="2xsmall"
      >
        <Icon name={iconsMap[value]} sx={{ width: 12, height: 12 }} />
        {t('aiBlock.answerType.title', { type: labels[value] })}
        <Icon name="arrowDown" fontSize="small" />
      </Button>
      <StyledMenu {...bindMenu(popupState)}>
        <MenuItem onClick={handleClick('short_block')}>
          <Icon name="shortParagraphAi" fontSize="small" />
          {labels['short_block']}
        </MenuItem>
        <MenuItem onClick={handleClick('long_block')}>
          <Icon name="paragraphAi" fontSize="small" />
          {labels['long_block']}
        </MenuItem>
        <MenuItem onClick={handleClick('table')}>
          <Icon name="table" fontSize="small" />
          {labels['table']}
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default AiAnswerTypeSelect;
