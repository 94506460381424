import { Mention, MentionsInput, MentionsInputClass, MentionsInputProps } from 'react-mentions';
import {
  forwardRef,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Stack, StackProps, Typography, useTheme } from '@mui/material';
import Icon, { IconNames } from '@/components/Icon/Icon';
import { FeatureName, MentionType } from '@/api/generated';
import { Divider, Footer, Header, TabItem } from './RoundedMentionInput.styled';
import { MentionSuggestionDataItem, useMentions } from './useMentions';
import { useTranslation } from 'react-i18next';

export type MentionsInstance = { openMentions: (type: string) => void };

export interface RoundedMentionInputProps extends Omit<MentionsInputProps, 'children' | 'onSubmit' | 'inputRef'> {
  minRows?: number;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  mentionType?: MentionType;
  minMaxHeight?: number;
  minMaxSuggestionHeight?: number;
  instanceRef?: MutableRefObject<MentionsInstance | null>;
  ContainerProps?: StackProps;
  featureName: FeatureName;
  projectId?: string;
}

interface RenderSuggestionsProps {
  suggestion: MentionSuggestionDataItem;
  highlightedDisplay: ReactNode;
  index: number;
}

type IconType = Omit<IconNames, 'users' | 'wrench' | 'pdf' | 'folder' | 'tag'>;

const IconByType: Record<MentionType, IconType | undefined> = {
  tool: 'wrench',
  document: 'pdf',
  org_document: 'pdf',
  person: 'users',
  project: 'folder',
  page_segment: undefined,
  create_page: undefined,
  document_type: undefined,
  document_subtype: undefined,
  query_tag: 'tag',
};

const prefixByType: Record<MentionType, '@' | '@@' | '#'> = {
  document: '@',
  org_document: '@',
  person: '@',
  document_type: '@@',
  document_subtype: '@@',
  tool: '#',
  project: '#',
  create_page: '#',
  query_tag: '#',
  page_segment: '#',
};

const getDisplayPrefix = (type: MentionType | null) => (type ? prefixByType[type] : null);

const checkEnding = (str: string) => {
  const match = str.match(/(@@|@|#)\s*$/);
  return match ? match[0] : null;
};

const getSelectedTypesByPrefix = (str: string) => {
  const prefix = checkEnding(str);
  if (!prefix) return null;
  return Object.keys(prefixByType).filter(key => prefixByType[key as MentionType] === prefix) as MentionType[];
};

const MAX_HEIGHT = 300;

const RoundedMentionInput = forwardRef<HTMLTextAreaElement | null, RoundedMentionInputProps>((props, ref) => {
  const {
    minRows = 1,
    value,
    startIcon,
    endIcon,
    minMaxHeight = 240,
    minMaxSuggestionHeight = 300,
    ContainerProps,
    instanceRef,
    featureName,
    projectId,
    ...restProps
  } = props;
  const { palette, typography } = useTheme();
  const { t } = useTranslation('common');

  const refContainer = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const fakeInputRef = useRef<HTMLTextAreaElement | null>(null);
  const portalRef = useRef<HTMLDivElement | null>(null);
  const [selectedType, setSelectedType] = useState<MentionType | null>(null);

  const { mentions, mentionTypes } = useMentions(featureName, projectId);
  const selectedTab = selectedType || mentionTypes[0];

  const inputRect = refContainer?.current && refContainer?.current.getBoundingClientRect();
  const suggestionHeight = useMemo(
    () =>
      Math.min(
        (inputRect?.top || 0) >= window.outerHeight - MAX_HEIGHT ? MAX_HEIGHT : (inputRect?.top || 0) - minMaxHeight,
        minMaxSuggestionHeight,
      ),
    [inputRect?.top],
  );

  useEffect(() => {
    portalRef.current = document.getElementById('root-portal') as HTMLDivElement;
  }, []);

  useEffect(() => {
    if (!value) return;

    const newType = getSelectedTypesByPrefix(value)?.find(type => mentionTypes.includes(type));
    if (!newType) return;

    const newPrefix = getDisplayPrefix(newType);
    const currentPrefix = getDisplayPrefix(selectedType);
    if (newPrefix !== currentPrefix) setSelectedType(newType);
  }, [value]); // here need only value!

  const mentionsInstanceRef = useRef<MentionsInputClass | null>(null);

  useImperativeHandle(instanceRef, () => ({
    openMentions: (type: string) => {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const mentionsInstance = mentionsInstanceRef.current as unknown as any;
      const input = inputRef.current;
      if (!mentionsInstance || !input) return;

      const position = input.value.length;
      // @ts-expect-error
      mentionsInstanceRef.current.setState(prev => ({ ...prev, selectionStart: position, selectionEnd: position }));
      setTimeout(() => {
        input.value += type;
        input.focus();
        mentionsInstance.handleChange({
          target: {
            selectionStart: position + type.length,
            selectionEnd: position + type.length,
            value: input.value,
          },
          nativeEvent: { isComposing: true },
        });
      });
    },
  }));

  const recalculateRowHeight = () => {
    const fakeInput = fakeInputRef.current;
    const instance = mentionsInstanceRef.current;
    // @ts-expect-error
    const input = instance?.inputElement as HTMLTextAreaElement;
    // @ts-expect-error
    const highlighter = instance?.highlighterElement as HTMLDivElement;
    if (!instance || !input || !highlighter || !fakeInput) return;

    fakeInput.value = input.value;
    fakeInput.style.width = `${input.clientWidth}px`;
    fakeInput.style.paddingLeft = input.style.paddingLeft;
    fakeInput.style.paddingRight = input.style.paddingRight;
    input.rows = Math.max(minRows ?? 1, Math.round(fakeInput.scrollHeight / fakeInput.clientHeight));
    highlighter.style.height = `${input.offsetHeight}px`;
  };

  useEffect(() => {
    recalculateRowHeight();

    if (mentionsInstanceRef.current) {
      const resizeObserver = new ResizeObserver(recalculateRowHeight);
      // @ts-expect-error
      resizeObserver.observe(mentionsInstanceRef.current.inputElement);
    }
  }, []);

  const renderSuggestions = (args: RenderSuggestionsProps): JSX.Element => {
    const { suggestion, index } = args;
    const itemType = suggestion.type as keyof typeof IconByType;
    const iconName = IconByType[itemType];

    return (
      <Stack
        key={index}
        flexDirection="row"
        alignItems="center"
        gap={1.5}
        title={suggestion.display}
        px={3}
        py={1}
        sx={{
          borderBottom: `1px solid ${palette.custom.separationLine}`,
        }}
      >
        {iconName && <Icon name={iconName as IconNames} fontSize="small" />}
        <Typography variant="body2" color="text.dark">
          {suggestion.display}
        </Typography>
      </Stack>
    );
  };

  const renderSuggestionsContainer = useCallback(
    (children: ReactNode) => (
      <Box sx={{ minWidth: 350, pointerEvents: 'auto' }}>
        <Header>
          {mentionTypes.map(type => (
            <TabItem key={type} onClick={() => setSelectedType(type)} isActive={selectedTab === type}>
              <Typography variant="body3">{t(`mention_types.${type}`)}</Typography>
            </TabItem>
          ))}
        </Header>
        <Box sx={{ minHeight: 30 }}>{children}</Box>
        <Footer>
          <Typography variant="body4">{t('mention_suggestions.documents')}</Typography>
          <Divider />
          <Typography variant="body4">{t('mention_suggestions.document_types')}</Typography>
          <Divider />
          <Typography variant="body4">{t('mention_suggestions.ai_agents')}</Typography>
        </Footer>
      </Box>
    ),
    [selectedTab, setSelectedType, mentionTypes, t],
  );

  return (
    <Stack
      {...ContainerProps}
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 5,
        boxShadow: 1,
        width: '100%',
        border: `1px solid ${palette.primary.light}`,
        backgroundColor: palette.background.default,
        position: 'relative',
        ...ContainerProps?.sx,
      }}
      ref={refContainer}
    >
      {startIcon && startIcon}
      <textarea
        ref={fakeInputRef}
        rows={1}
        className="mentions__input"
        style={{
          position: 'absolute',
          zIndex: -1,
          top: -100,
          left: 0,
          border: 0,
          padding: 0,
          overflow: 'hidden',
          fontFamily: typography.fontFamily,
          visibility: 'hidden',
        }}
        value={value}
      />
      <MentionsInput
        {...restProps}
        value={value}
        // forceSuggestionsAboveCursor NOTE: uncommit if you need top only position
        allowSuggestionsAboveCursor
        suggestionsPortalHost={portalRef.current ?? undefined}
        inputRef={(instance: HTMLTextAreaElement | null) => {
          inputRef.current = instance;
          /* @ts-expect-error */
          ref && (ref.current = instance);
        }}
        onChange={(...args) => {
          recalculateRowHeight();
          props.onChange?.(...args);
        }}
        // @ts-expect-error
        ref={mentionsInstanceRef}
        className={`mentions ${minRows === 1 ? 'mentions_oneLine' : ''}`}
        customSuggestionsContainer={renderSuggestionsContainer}
        style={{
          suggestions: {
            list: { maxHeight: suggestionHeight },
            item: { padding: 0 },
          },
          ...(props.style || {}),
        }}
      >
        <Mention
          renderSuggestion={(suggestion, _search, highlightedDisplay, index) => {
            if ((suggestion as MentionSuggestionDataItem).type !== selectedTab) return null;
            return renderSuggestions({
              suggestion: suggestion as MentionSuggestionDataItem,
              highlightedDisplay,
              index,
            });
          }}
          appendSpaceOnAdd
          data={mentions}
          trigger={/((?:@{1,2}|#)(\S*))$/}
          markup="@[__display__](__id__)"
          displayTransform={(id, display) =>
            `${getDisplayPrefix(mentions.find(el => el.id === id)?.type as MentionType)}${display} `
          }
        />
      </MentionsInput>
      {endIcon && endIcon}
    </Stack>
  );
});

export default RoundedMentionInput;
