import { FC, ReactNode } from 'react';
import { ButtonBaseProps, ButtonBase, Popover, Stack } from '@mui/material';
import { bindPopover, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import Icon from '@/components/Icon/Icon';

interface ToolbarMenuProps extends Omit<ButtonBaseProps, 'id' | 'sx'> {
  id: string;
  selectedValue: ReactNode;
}

const ToolbarMenu: FC<ToolbarMenuProps> = ({ id, children, selectedValue, ...props }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: `toolbar-menu-popup-${id}` });

  return (
    <ButtonBase
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        height: 26,
        p: 0.5,
        borderRadius: 1,
        whiteSpace: 'nowrap',
      }}
      type="button"
      {...bindToggle(popupState)}
    >
      {selectedValue}
      <Icon name="arrowDown" fontSize="small" />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        slotProps={{ paper: { sx: { boxShadow: 1 } } }}
        // Add preventing to keep text selection
        BackdropProps={{ onMouseDown: event => event.preventDefault() }}
      >
        <Stack gap={0.5} p={1}>
          {children}
        </Stack>
      </Popover>
    </ButtonBase>
  );
};

export default ToolbarMenu;
