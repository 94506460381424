import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@/store';

export type CreateQuestionDialogState = {
  isOpened: boolean;
};

export const createQuestionDialogSlice = createSlice({
  name: 'createQuestionDialog',
  initialState: { isOpened: false } as CreateQuestionDialogState,
  reducers: {
    showCreateQuestionDialog: state => ({
      ...state,
      isOpened: true,
    }),
    hideCreateQuestionDialog: state => ({
      ...state,
      isOpened: false,
    }),
  },
});

export const { showCreateQuestionDialog, hideCreateQuestionDialog } = createQuestionDialogSlice.actions;

export const selectCreateQuestionDialog = (state: RootState) => state.createQuestionDialog;
