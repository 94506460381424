import { $applyNodeReplacement, LexicalNode, ParagraphNode } from 'lexical';

export class InlineAiContentNode extends ParagraphNode {
  static getType() {
    return 'inline-ai-content';
  }

  static clone(node: InlineAiContentNode): InlineAiContentNode {
    return new InlineAiContentNode(node.__key);
  }

  static importJSON(): InlineAiContentNode {
    return $createInlineAiContentNode();
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.classList.add('InlineAiContentNode');
    return element;
  }

  updateDOM() {
    return false;
  }

  isInline() {
    return true;
  }
}

export function $createInlineAiContentNode() {
  return $applyNodeReplacement(new InlineAiContentNode());
}

export function $isInlineAiContentNode(node: LexicalNode | null | undefined): node is InlineAiContentNode {
  return node instanceof InlineAiContentNode;
}
