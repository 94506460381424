import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { Dispatch, FC, useCallback, useEffect, useRef } from 'react';
import { getDOMRangeRect } from '@lexical/playground/utils/getDOMRangeRect';
import { setFloatingElemPosition } from '@lexical/playground/utils/setFloatingElemPosition';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { INSERT_CHECK_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from '@lexical/list';
import { $patchStyleText, $setBlocksType } from '@lexical/selection';
import { HeadingTagType, $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import { $createCodeNode } from '@lexical/code';
import ToolbarButton from '@/containers/PagesEditor/components/ToolbarButton';
import ActionsSeparator from '@/components/ActionsSeparator';
import ToolbarMenu from '@/containers/PagesEditor/components/ToolbarMenu';
import {
  backgroundColors,
  blockTypes,
  ChangeableBlockType,
  defaultBgColor,
  defaultTextColor,
  textColors,
} from '@/containers/PagesEditor/plugins/FloatingTextFormatToolbarPlugin/constants';
import ToolbarMenuItem from '@/containers/PagesEditor/components/ToolbarMenuItem';
import ColorCircle from '@/containers/PagesEditor/components/ColorCircle';
import ToolbarColorMenuItem from '@/containers/PagesEditor/components/ToolbarColorMenuItem';
import nextTick from '@/services/nextTick';
import PageEditorIcon from '@/containers/PagesEditor/ui/PageEditorIcon';
import AskAiButton from '@/containers/PagesEditor/components/AskAiButton';
import { ASK_AI_COMMAND } from '@/containers/PagesEditor/plugins/AskAiPlugin/AskAiPlugin';
import Icon from '@/components/Icon/Icon';

interface TextFormatFloatingToolbarProps {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  selectedBlockType: ChangeableBlockType | null;
  textColor?: string;
  bgColor?: string;
  isText: boolean;
  isBold: boolean;
  isItalic: boolean;
  isLink: boolean;
  isUnderline: boolean;
  setIsLinkEditMode: Dispatch<boolean>;
}

const TextFormatFloatingToolbar: FC<TextFormatFloatingToolbarProps> = ({
  editor,
  anchorElem,
  selectedBlockType,
  textColor = defaultTextColor,
  bgColor = defaultBgColor,
  isText,
  isLink,
  isBold,
  isItalic,
  isUnderline,
  setIsLinkEditMode,
}) => {
  const { t } = useTranslation('lexicalEditor');
  const { palette } = useTheme();
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      setIsLinkEditMode(false);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink, setIsLinkEditMode]);

  const updateFloatingToolbarWithTick = () => {
    nextTick(() => editor.update(() => $updateTextFormatFloatingToolbar()));
  };

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
        updateFloatingToolbarWithTick();
      }
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (selectedBlockType === headingSize) return;

    editor.update(() => {
      const selection = $getSelection();
      $setBlocksType(selection, () => $createHeadingNode(headingSize));
      updateFloatingToolbarWithTick();
    });
  };

  const formatBulletList = () => {
    if (selectedBlockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      updateFloatingToolbarWithTick();
    } else {
      formatParagraph();
    }
  };

  const formatCheckList = () => {
    if (selectedBlockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
      updateFloatingToolbarWithTick();
    } else {
      formatParagraph();
    }
  };

  const formatNumberedList = () => {
    if (selectedBlockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      updateFloatingToolbarWithTick();
    } else {
      formatParagraph();
    }
  };

  const formatQuote = () => {
    if (selectedBlockType === 'quote') return;

    editor.update(() => {
      const selection = $getSelection();
      $setBlocksType(selection, () => $createQuoteNode());
    });
  };

  const formatCode = () => {
    if (selectedBlockType === 'code') return;

    editor.update(() => {
      let selection = $getSelection();
      if (selection === null) return;

      if (selection.isCollapsed()) {
        $setBlocksType(selection, () => $createCodeNode());
      } else {
        const textContent = selection.getTextContent();
        const codeNode = $createCodeNode();
        selection.insertNodes([codeNode]);
        selection = $getSelection();
        if ($isRangeSelection(selection)) {
          selection.insertRawText(textContent);
        }
      }
      updateFloatingToolbarWithTick();
    });
  };

  const $updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection();

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
    const nativeSelection = window.getSelection();

    if (popupCharStylesEditorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);

      setFloatingElemPosition(rangeRect, popupCharStylesEditorElem, anchorElem, isLink, undefined, 15);
    }
  }, [editor, anchorElem, isLink]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        $updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener('resize', update);
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [editor, $updateTextFormatFloatingToolbar, anchorElem]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      $updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateTextFormatFloatingToolbar();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          $updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, $updateTextFormatFloatingToolbar]);

  const blockTypeChangeHandlers: Record<(typeof blockTypes)[number], () => void> = {
    paragraph: formatParagraph,
    h1: () => formatHeading('h1'),
    h2: () => formatHeading('h2'),
    h3: () => formatHeading('h3'),
    number: formatNumberedList,
    bullet: formatBulletList,
    check: formatCheckList,
    quote: formatQuote,
    code: formatCode,
  };

  const onColorSelect = useCallback(
    (color: string, type: 'text' | 'background') => {
      editor.update(() => {
        const selection = $getSelection();
        if (selection === null) return;

        $patchStyleText(selection, {
          color: type === 'text' ? color : null,
          'background-color': type === 'background' ? color : null,
        });
      });
    },
    [editor],
  );

  const onAskAi = () => {
    editor.dispatchCommand(ASK_AI_COMMAND, { isAskingWithSelection: true });
  };

  const renderButtons = () => {
    if (!editor.isEditable()) return null;

    const textColorIsSelected = bgColor === defaultBgColor;

    return (
      <Stack direction="row" gap={1.5} height="100%" alignItems="center">
        {isText && (
          <>
            <AskAiButton onClick={onAskAi} />
            <ActionsSeparator />
          </>
        )}
        <Stack direction="row" gap={0.5}>
          <ToolbarMenu
            id="node-type"
            selectedValue={
              selectedBlockType && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PageEditorIcon blockType={selectedBlockType} fontSize="small" />
                  {t(`editor.toolbar.${selectedBlockType}`)}
                </Box>
              )
            }
          >
            {blockTypes.map(blockType => (
              <ToolbarMenuItem isSelected={selectedBlockType === blockType} onClick={blockTypeChangeHandlers[blockType]}>
                <PageEditorIcon blockType={blockType} fontSize="small" /> {t(`editor.toolbar.${blockType}`)}
              </ToolbarMenuItem>
            ))}
          </ToolbarMenu>
          {isText && (
            <ToolbarMenu id="text-color" selectedValue={<ColorCircle color={textColorIsSelected ? textColor : bgColor} />}>
              {Object.entries(textColors).map(([name, color]) => (
                <ToolbarColorMenuItem
                  type="text"
                  isSelected={textColorIsSelected && color === textColor}
                  color={color}
                  onClick={() => onColorSelect(color, 'text')}
                >
                  {t(`editor.toolbar.textColors.${name}`)}
                </ToolbarColorMenuItem>
              ))}
              {Object.entries(backgroundColors).map(([name, color]) => (
                <ToolbarColorMenuItem
                  type="background"
                  isSelected={!textColorIsSelected && color === bgColor}
                  color={color}
                  onClick={() => onColorSelect(color, 'background')}
                >
                  {t(`editor.toolbar.backgroundColors.${name}`)}
                </ToolbarColorMenuItem>
              ))}
            </ToolbarMenu>
          )}
        </Stack>
        {isText && (
          <>
            <ActionsSeparator />
            <Stack direction="row" gap={0.5}>
              <ToolbarButton
                isActive={isBold}
                title={t('editor.toolbar.bold')}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
              >
                <Icon name="bold" />
              </ToolbarButton>
              <ToolbarButton
                isActive={isItalic}
                title={t('editor.toolbar.italic')}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
              >
                <Icon name="italic" />
              </ToolbarButton>
              <ToolbarButton
                isActive={isUnderline}
                title={t('editor.toolbar.underline')}
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                }}
              >
                <Icon name="underline" />
              </ToolbarButton>
              <ToolbarButton isActive={isLink} title={t('editor.toolbar.link')} onClick={insertLink}>
                <Icon name="link" />
              </ToolbarButton>
            </Stack>
          </>
        )}
      </Stack>
    );
  };

  return (
    <Stack
      direction="row"
      gap={1}
      ref={popupCharStylesEditorRef}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
        height: 40,
        px: 2,
        verticalAlign: 'middle',
        opacity: 0,
        borderRadius: 999,
        boxShadow: 1,
        backgroundColor: palette.background.default,
        transition: 'opacity 0.5s',
        willChange: 'transform',
      }}
    >
      {renderButtons()}
    </Stack>
  );
};

export default TextFormatFloatingToolbar;
