import { $nodesOfType } from 'lexical';
import { AiContainerNode } from '@/containers/PagesEditor/nodes/AiContainerNode';
import { $createAiContentNode, $isAiContentNode } from '@/containers/PagesEditor/nodes/AiContentNode';
import { AITemplateBlock } from '@/api/generated';
import { $createElementNodeFromMarkdown } from '@/containers/PagesEditor/utils/$createElementNodeFromMarkdown';

export const $updateAiBlocksContent = (aiBlocks: AITemplateBlock[]) => {
  if (!aiBlocks?.length) return;

  const aiContainerNodes = $nodesOfType(AiContainerNode);
  if (!aiContainerNodes.length) return;

  aiContainerNodes.forEach(node => {
    const aiBlock = aiBlocks.find(block => block._id === node.getUuid());
    if (!aiBlock?.content) return;

    const hasContent = node.getChildren().some(child => $isAiContentNode(child));
    if (hasContent) return;

    const contentNode = $createAiContentNode();
    contentNode.append($createElementNodeFromMarkdown(aiBlock.content!));
    node.append(contentNode);
  });
};
