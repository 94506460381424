import { FC } from 'react';
import { Box, useTheme } from '@mui/material';

const ActionsSeparator: FC = () => {
  const { palette } = useTheme();

  return <Box sx={{ borderRight: `1px solid ${palette.grey[50]}`, height: '100%' }} />;
};

export default ActionsSeparator;
