import { useEffect, useRef } from 'react';
import PSPDFKit, { Instance, UnknownAnnotationJSON } from '@nutrient-sdk/viewer';
import { Match } from '@/api/generated';

type Params = {
  instance?: Instance;
  selectedMatch?: Match;
};

export const useMatchesAnnotations = ({ instance, selectedMatch }: Params) => {
  const matchAnnotationsIdsRef = useRef<string[]>([]);

  useEffect(() => {
    if (!instance) return;

    instance.delete(matchAnnotationsIdsRef.current);
    matchAnnotationsIdsRef.current = [];

    if (!selectedMatch) return;

    const matchAnnotations = ((selectedMatch.annotations ?? []) as UnknownAnnotationJSON[]).map(annotation => ({
      ...annotation,
      // @ts-expect-error
      id: annotation._id!,
      flags: ['locked' as const],
      customData: { isLocal: true, isMatchAnnotation: true },
    }));
    instance.create(
      matchAnnotations.map(annotation => PSPDFKit.Annotations.fromSerializableObject(annotation).set('id', annotation.id)),
    );
    matchAnnotationsIdsRef.current = matchAnnotations.map(annotation => annotation.id);
  }, [selectedMatch, instance]);
};
