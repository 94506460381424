import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import uploadProgressSlice from '@/store/uploadSlice';
import fileTreeSlice from '@/store/fileTreeSlice';
import pdfEditorSlice from '@/store/pdfEditorSlice';
import { createQuestionDialogSlice } from '@/store/createQuestionDialogSlice';
import { refreshQueriesSlice } from '@/store/refreshQueriesSlice';
import { helpDialogSlice } from '@/store/helpDialogSlice';
import { selectQueriesSlice } from '@/store/selectQueriesSlice';
import { lexicalPagesSlice } from '@/store/lexicalPagesSlice';

const rootReducer = combineSlices(
  uploadProgressSlice,
  fileTreeSlice,
  pdfEditorSlice,
  createQuestionDialogSlice,
  refreshQueriesSlice,
  helpDialogSlice,
  selectQueriesSlice,
  lexicalPagesSlice,
);

export const store = configureStore({
  reducer: rootReducer,
  devTools: !import.meta.env.PROD,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
