import { Components, Theme } from '@mui/material/styles';

const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: '1px solid',
      borderColor: theme.palette.controls.border,
      backgroundColor: theme.palette.controls.background,
      '&:hover:not(.Mui-error), &.Mui-focused, &.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      },
    }),
    input: ({ theme }) => ({
      padding: theme.spacing(1.3, 1.5),
      fontSize: theme.typography.body1.fontSize,
      '&::placeholder': {
        color: theme.palette.controls.placeholder,
        opacity: 1,
      },
      '&.MuiInputBase-inputSizeSmall': {
        padding: theme.spacing(1, 1.5),
      },
    }),
    notchedOutline: {
      borderColor: 'transparent',
    },
    multiline: {
      padding: 0,
    },
  },
};
export default MuiOutlinedInput;
