import { Instance } from '@nutrient-sdk/viewer';
import { useEffect } from 'react';

type Params = {
  instance?: Instance;
  hiddenAnnotationsIds: string[];
  highlightedAnnotationsIds: string[];
};

const getAnnotationSelectors = (id: string) => `
  .PSPDFKit-Annotation-Keyboard-Navigable[data-annotation-id="${id}"],
  .PSPDFKit-Annotation[data-annotation-id="${id}"],
  div:has(+ .PSPDFKit-Annotation[data-annotation-id="${id}"])
`;

export const useAnnotationsStyling = ({ instance, hiddenAnnotationsIds, highlightedAnnotationsIds }: Params) => {
  useEffect(() => {
    if (!instance) return;

    const styleTag = document.createElement('style');
    styleTag.id = 'annotations-styling';
    instance.contentDocument.appendChild(styleTag);

    const hideClasses = hiddenAnnotationsIds.map(getAnnotationSelectors).join(', ');
    const hiddenStyles = hiddenAnnotationsIds.length ? `${hideClasses} { display: none; }` : '';

    const highlightClasses = highlightedAnnotationsIds.map(getAnnotationSelectors).join(', ');
    const highlightStyles = highlightedAnnotationsIds.length
      ? `.PSPDFKit-Annotation { opacity: 0.5 } ${highlightClasses} { opacity: 1 }`
      : '';

    styleTag.innerText = [hiddenStyles, highlightStyles].join(' ');

    return () => {
      styleTag.remove();
    };
  }, [instance, hiddenAnnotationsIds, highlightedAnnotationsIds]);
};
