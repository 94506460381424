import { useEffect } from 'react';
import PSPDFKit, { Instance, UnknownAnnotationJSON } from '@nutrient-sdk/viewer';
import { useAppDispatch, useAppSelector } from '@/store';
import { deleteNewAnnotations, selectNewAnnotations } from '@/store/pdfEditorSlice';
import { useProject } from '@/views/Project/hooks/useProject';
import { useDocuments } from '@/views/Project/hooks/useDocuments';

type Params = {
  instance?: Instance;
};

export const useUpdateNewAnnotations = ({ instance }: Params) => {
  const dispatch = useAppDispatch();
  const { projectSlug } = useProject();
  const { documentId } = useDocuments();
  const newAnnotations = useAppSelector(selectNewAnnotations(projectSlug, documentId));

  useEffect(() => {
    if (!instance || !newAnnotations) return;

    dispatch(deleteNewAnnotations({ slug: projectSlug, documentId: documentId! }));
    instance.create(
      newAnnotations.map(annotation =>
        PSPDFKit.Annotations.fromSerializableObject(annotation as UnknownAnnotationJSON)
          .set('id', annotation.id)
          .set('customData', { ...annotation.customData, skipSave: true }),
      ),
    );
  }, [instance, newAnnotations]);
};
