import { FC, MouseEvent } from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Box, IconButton, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';

export interface TagViewProps extends LoadingButtonProps {
  tag: string;
  isHighlighted?: boolean;
  onDelete?: () => void;
}

const TagView: FC<TagViewProps> = ({ sx, color = 'secondary', size, tag, isHighlighted, onDelete, disabled, ...props }) => {
  const { palette } = useTheme();

  const handleDelete = (event: MouseEvent) => {
    event.stopPropagation();
    onDelete?.();
  };

  return (
    <LoadingButton
      variant="contained"
      color={color}
      size={size}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0,
        borderRadius: '999px',
        '&:hover': disabled
          ? undefined
          : {
              gap: 0.7,
              backgroundColor: isHighlighted ? palette.grey[200] : palette.background.default,
              '.TagChip__delete': { width: 12, marginRight: -0.5 },
              '& button': { opacity: 1 },
            },
        ...sx,
      }}
      {...props}
    >
      <Box sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{tag}</Box>
      {!disabled && (
        <IconButton
          sx={{ width: 0, p: 0, overflow: 'hidden', transition: 'width .2s' }}
          className="TagChip__delete"
          size="small"
          disableRipple
          onClick={handleDelete}
        >
          <Icon name="x" fontSize="xsmall" htmlColor={palette.text.primary} />
        </IconButton>
      )}
    </LoadingButton>
  );
};

export default TagView;
