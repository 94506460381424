import { FC, ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog, { ConfirmDialogTexts, Options } from '@/components/ConfirmDialog';
import { ConfirmDialogContext, ConfirmDialogContextValue } from '@/contexts/ConfirmDialogContext';

interface ConfirmDialogProviderProps {
  children: ReactNode;
}

export const ConfirmDialogProvider: FC<ConfirmDialogProviderProps> = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation('common');

  const defaultTexts = { confirm: t('confirmDialog.confirm'), cancel: t('confirmDialog.cancel') };
  const texts = useRef<ConfirmDialogTexts>({ ...defaultTexts, title: '' });
  const options = useRef<Options>({ hideCancel: false });
  const resolveRef = useRef<(result: boolean | string | undefined) => void>(() => false);

  const contextValue: ConfirmDialogContextValue = {
    showConfirmDialog: (nextTexts: ConfirmDialogTexts, optionsParam: Options = {}) => {
      options.current = optionsParam;
      texts.current = { ...defaultTexts, ...nextTexts };
      setIsOpened(true);
      return new Promise<boolean | string | undefined>(resolve => (resolveRef.current = resolve));
    },
  };

  const onDialogClose = (result?: boolean | string) => {
    resolveRef.current(result);
    setIsOpened(false);
  };

  return (
    <ConfirmDialogContext.Provider value={contextValue}>
      <ConfirmDialog isOpened={isOpened} options={options.current} texts={texts.current} onClose={onDialogClose} />
      {children}
    </ConfirmDialogContext.Provider>
  );
};
