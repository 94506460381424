import { FC, ReactElement } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import DraggableLexicalBlock from '@/containers/PagesEditor/ui/DraggableLexicalBlock';

interface BlocksGroupProps {
  title: string;
  children: Array<ReactElement<typeof DraggableLexicalBlock>>;
}

const BlocksGroup: FC<BlocksGroupProps> = ({ title, children }) => {
  const { palette } = useTheme();

  return (
    <Stack sx={{ gap: 1.5 }}>
      <Typography sx={{ fontSize: 'body1.fontSize', fontWeight: 700, color: palette.grey[700] }}>{title}</Typography>
      {children}
    </Stack>
  );
};

export default BlocksGroup;
