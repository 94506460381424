import PSPDFKit, { AnnotationsWillChangeReason, Instance } from '@nutrient-sdk/viewer';
import nextTick from '@/services/nextTick';

export function focusOnConfirmButtonOnAnnotationDelete(instance: Instance) {
  instance.addEventListener('annotations.willChange', (event: { reason: AnnotationsWillChangeReason }) => {
    if (event.reason !== PSPDFKit.AnnotationsWillChangeReason.DELETE_START) return;

    nextTick(() => {
      const button = instance.contentDocument.querySelector<HTMLElement>('.PSPDFKit-Confirm-Dialog-Button-Confirm');
      button?.focus();
    });
  });
}
