import { DragEvent, FC, ReactNode, useRef } from 'react';
import { Box, useTheme } from '@mui/material';
import { DRAG_DATA_FORMAT, INLINE_DRAG_DATA_FORMAT } from '@/containers/PagesEditor/constants';
import { DraggableBlockData, DraggableBlockSettings, DraggableBlockType } from '@/containers/PagesEditor/types';
import Icon from '@/components/Icon/Icon';

interface DraggableLexicalBlockProps {
  type: DraggableBlockType;
  settings?: DraggableBlockSettings;
  icon: ReactNode;
  text: ReactNode;
}

const DraggableLexicalBlock: FC<DraggableLexicalBlockProps> = ({ type, settings, icon, text }) => {
  const { palette } = useTheme();
  const blockRef = useRef<HTMLDivElement | null>(null);

  const onDragStart = (event: DragEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const format = settings && 'isInline' in settings && settings.isInline ? INLINE_DRAG_DATA_FORMAT : DRAG_DATA_FORMAT;
    event.dataTransfer.setData(format, JSON.stringify({ type, settings } satisfies DraggableBlockData));
    event.dataTransfer.setDragImage(blockRef.current!, event.clientX - rect.left, event.clientY - rect.top);
    event.dataTransfer.dropEffect = 'move';
  };

  return (
    <Box
      ref={blockRef}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
        px: 1,
        py: 0.75,
        border: '1px solid',
        borderColor: palette.grey[200],
        borderRadius: 2,
        backgroundColor: palette.background.default,
        color: palette.grey[800],
        cursor: 'grab',
        overflow: 'hidden',
        transition: 'background-color 0.2s',
        transform: 'translate(0, 0)',
        '&:hover': { backgroundColor: palette.grey[100], '.DraggableLexicalBlock__dragIcon': { display: 'flex' } },
      }}
      draggable
      onDragStart={onDragStart}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {icon}
        {text}
      </Box>
      <Box className="DraggableLexicalBlock__dragIcon" sx={{ display: 'none', alignItems: 'center' }}>
        <Icon name="moveDots" htmlColor={palette.grey[900]} />
      </Box>
    </Box>
  );
};

export default DraggableLexicalBlock;
