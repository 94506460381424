import { IconButton, IconButtonProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';

const ActionButton = forwardRef<HTMLButtonElement, IconButtonProps>(({ children, sx, ...props }, ref) => {
  const { palette, shape } = useTheme();

  return (
    <IconButton
      color="primary"
      ref={ref}
      sx={{
        flex: 0,
        backgroundColor: 'transparent',
        color: palette.grey[900],
        p: 0,
        borderRadius: shape.borderRadius,

        '&:hover': {
          boxShadow: 0,
          backgroundColor: palette.secondary.main,
          color: palette.grey[900],
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
});

export default ActionButton;
