import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Stack, Tooltip, useTheme } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import keyBy from 'lodash/keyBy';
import { arrayToTree } from 'performant-array-to-tree';
import { $createRangeSelection, $getRoot, LexicalEditor, SerializedLexicalNode } from 'lexical';
import debounce from 'lodash/debounce';
import { CreateHandler, DeleteHandler, MoveHandler, RenameHandler } from 'react-arborist';
import { enqueueSnackbar } from 'notistack';
import { format } from 'date-fns/format';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { TreeItem, TreePage } from '@/components/Pages/types';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { useOrganization } from '@/hooks/useOrganization';
import { getPageIdFromLocalStorage, isExistedPage, savePageIdToLocalStorage } from '@/components/Pages/utils';
import {
  PageActionType,
  PageEditAction,
  PageEditActionParams,
  PageMetadata,
  PageMetadataParentId,
  PageType,
  Source,
  askAutomate,
  editPage,
  getFollowUpQuestions,
  PageResponse,
  FeatureName,
} from '@/api/generated';
import { AskAiFn, Block, EditedPage } from '@/containers/PagesEditor/types';
import sortBy from 'lodash/sortBy';
import useDispatchEditorClick from '@/containers/PagesEditor/hooks/useDispatchEditorClick';
import { loadImageBase64 } from '@/utils/loadImageBase64';
import LexicalPdfExport from '@/components/LexicalPdfExport/LexicalPdfExport';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $createElementNodeFromMarkdown } from '@/containers/PagesEditor/utils/$createElementNodeFromMarkdown';
import PagesDrawer from '@/components/Pages/components/PagesDrawer';
import PagesEditor from '@/containers/PagesEditor/PagesEditor';
import { IconNames } from '@/components/Icon/Icon';
import { getFadingShadowSxProps } from '@/utils/getFadingShadowSxProps';
import CellEditable from '@/components/CellEditable/CellEditable';
import { EDITOR_CONTENT_LEFT_PADDING, EDITOR_SHEET_MARGIN_SPACING } from '@/containers/PagesEditor/constants';
import SliderWithConfirm from '@/components/Pages/components/SliderWithConfirm';
import FabMenu from '@/components/FabMenu/FabMenu';
import FabButton from '@/components/FabMenu/FabButton';
import AskAiPlugin from '@/containers/PagesEditor/plugins/AskAiPlugin/AskAiPlugin';
import { TemplateContextProvider } from '@/containers/PagesEditor/context/TemplateContext';
import { selectCurrentPage, selectPages, updateCurrentPage, updatePageTitle } from '@/store/lexicalPagesSlice';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  createNewPage,
  deletePage,
  loadCurrentPage,
  loadPages,
  toggleIsPrivate,
  updatePage,
  updatePages,
  waitForPageReady,
} from '@/store/lexicalPagesThunks';
import { createRootNode } from '@/containers/PagesEditor/utils/createRootNode';
import FloatingTextFormatToolbarPlugin from '@/containers/PagesEditor/plugins/FloatingTextFormatToolbarPlugin/FloatingTextFormatToolbarPlugin';
import { isTemplatePage } from '@/containers/PagesEditor/utils/isTemplatePage';
import nextTick from '@/services/nextTick';
import { $updateAiBlocksContent } from '@/containers/PagesEditor/utils/$updateAiBlocksContent';
import { $updateInlineAiBlocksContent } from '@/containers/PagesEditor/utils/$updateInlineAiBlocksContent';
import { $updateVariableBlocksContent } from '@/containers/PagesEditor/utils/$updateVariableBlocksContent';

export interface AiMenuItemProps {
  iconName: IconNames;
  textContent: string;
  onClick: (text: string) => void;
}

interface PagesProps {
  slug: string;
  projectId?: string;
  width?: string | number;
  minMaxHeight: number;
  aiMenuItems: AiMenuItemProps[];
  featureName: FeatureName;
}

const INITIAL_STATE = { showSlider: false, sliderValue: 0 };

const Pages: FC<PagesProps> = ({ slug, projectId = null, width = 'auto', minMaxHeight, aiMenuItems, featureName }) => {
  const { t } = useTranslation('lexicalEditor');
  const { palette, typography, spacing } = useTheme();
  const defaultTitle = t('pageDefaultTitle');
  const { showConfirmDialog } = useConfirmDialog();
  const { organization } = useOrganization();
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageId = searchParams.get('pageId');
  const { currentPage, isCurrentPageLoading } = useAppSelector(selectCurrentPage);
  const skipEditorUpdateRef = useRef(false);
  const currentPageIdRef = useRef<string | null | undefined>(currentPageId);
  const currentlyCreatingPageRef = useRef<Promise<PageResponse | AxiosError | undefined> | null>(null);
  const { pages, isPagesLoading } = useAppSelector(selectPages(slug));

  const lexicalEditorRef = useRef<LexicalEditor | null>(null);
  const ignoreNextEditorUpdate = useRef(false);

  const pagesRef = useRef<PageMetadata[]>();
  const pagesByIdRef = useRef<Record<string, PageMetadata>>({});
  const [pageTitle, setPageTitle] = useState(defaultTitle);
  const [pagesTree, setPagesTree] = useState<TreePage[]>();
  const [isSliderChangeDisabled, setIsSliderChangeDisabled] = useState(false);
  const [isConfirmButtonShowed, setIsConfirmButtonShowed] = useState(false);
  const [actionData, setActionData] = useState<PageEditAction>({
    action: PageActionType.change_page_length,
    params: null,
  });
  const [sliderStateActions, setSliderStateActions] = useState(INITIAL_STATE);
  const [isSliderActionDataLoading, setIsSliderActionDataLoading] = useState(false);

  const notNewPage = currentPage && 'created_by' in currentPage ? currentPage : null;
  const templatePage = isTemplatePage(currentPage) ? currentPage : null;

  const getSuggestions = async (selectedText?: string) =>
    getFollowUpQuestions({
      source: selectedText ? Source.create_follow_ups : Source.create,
      context: { page_id: currentPageIdRef.current ?? '', query_id: '', equipment_id: '', text: '' },
    });

  const updateTreePages = (cb: (currentPages: PageMetadata[]) => PageMetadata[]) => {
    pagesRef.current = cb(pagesRef.current ?? []);
    pagesByIdRef.current = keyBy(pagesRef.current, '_id');
    pagesRef.current = pagesRef.current.filter(
      page => !page.parentId || page.parentId === 'root' || pagesByIdRef.current[page.parentId],
    );

    const pageItems: TreeItem[] = pagesRef.current.map(page => ({
      id: page._id!,
      name: page.name,
      parentId: !page.parentId || page.parentId === 'root' ? undefined : page.parentId,
      isFolder: page.page_type === 'folder',
      order: page.order,
      page,
    }));

    setPagesTree(arrayToTree(pageItems, { id: 'id', parentId: 'parentId', dataField: 'item' }) as TreePage[]);
  };

  const currentPagePath = useMemo(() => {
    if (!currentPageId) return;

    const getPath = (pageId: string): string[] => {
      const page = pagesByIdRef.current[pageId];
      if (!page) return [];
      if (!page.parentId) return [page.name];
      return [...getPath(page.parentId), page.name];
    };

    return getPath(currentPageId);
  }, [currentPageId, pagesTree]);

  const clearEditor = () => {
    if (!lexicalEditorRef.current) return;

    lexicalEditorRef.current.update(() => $getRoot().clear());
    ignoreNextEditorUpdate.current = true;
  };

  const askAi: AskAiFn = (question, { reference, mentions = [] } = {}) =>
    askAutomate(slug!, {
      question_receive: {
        mentions,
        question,
        reference,
      },
      page_id: currentPageIdRef.current!,
    });

  const setUrlPageId = (pageId: string | undefined, replace = false) => {
    currentlyCreatingPageRef.current = null;
    setSearchParams(
      prevParams => {
        if (prevParams.get('pageId') === pageId) return prevParams;
        const nextParams = new URLSearchParams(prevParams);
        if (pageId) {
          nextParams.set('pageId', pageId);
        } else {
          nextParams.delete('pageId', pageId);
        }
        return nextParams;
      },
      { replace },
    );
    currentPageIdRef.current = pageId;
  };

  useEffect(() => {
    dispatch(loadPages({ slug }));
  }, [slug]);

  useEffect(() => {
    updateTreePages(() => (pages?.length ? sortBy(pages, ['parentId', 'order']) : []));
    pages?.forEach(async page => {
      if (page.isReady) return;
      dispatch(waitForPageReady({ id: page._id!, slug }));
    });
  }, [pages]);

  useEffect(() => {
    setPageTitle(currentPage?.name ?? defaultTitle);
  }, [currentPage]);

  useEffect(() => {
    if (isCurrentPageLoading) return;

    setPageTitle(currentPage?.name ?? defaultTitle);
    if (!lexicalEditorRef.current || skipEditorUpdateRef.current) return;

    ignoreNextEditorUpdate.current = true;
    skipEditorUpdateRef.current = false;
    const editorState = lexicalEditorRef.current.parseEditorState(
      JSON.stringify(createRootNode(currentPage?.content as Block[] | undefined)),
    );
    lexicalEditorRef.current.setEditorState(editorState);
  }, [currentPageId, isCurrentPageLoading]);

  useEffect(() => {
    nextTick(() => {
      if (!lexicalEditorRef.current || !templatePage) return;
      lexicalEditorRef.current.update(() => {
        $updateAiBlocksContent(templatePage.ai_blocks ?? []);
        $updateInlineAiBlocksContent(templatePage.ai_blocks ?? []);
        $updateVariableBlocksContent(templatePage.variable_blocks ?? []);
      });
    });
  }, [templatePage?.ai_blocks]);

  useEffect(() => {
    if (!pages || isPagesLoading) return;
    (async () => {
      const storagePageId = getPageIdFromLocalStorage(slug!);
      const isPageWithTypePageExists = !!pagesRef.current?.some(page => page.page_type === PageType.page);
      const isUrlPageExists = currentPageId && pagesRef.current?.some(page => page._id === currentPageId);
      const isStoragePageExists = storagePageId && pagesRef.current?.some(page => page._id === storagePageId);

      if (isUrlPageExists || isStoragePageExists || isPageWithTypePageExists) {
        const nextPageId = isUrlPageExists ? currentPageId : isStoragePageExists ? storagePageId : pagesRef.current![0]._id!;
        if (isUrlPageExists) {
          dispatch(loadCurrentPage({ slug, id: nextPageId }));
        } else {
          setUrlPageId(nextPageId, true);
          !isStoragePageExists && enqueueSnackbar(t('pageNotExist'), { variant: 'error' });
        }
      } else if (currentPageId) {
        setUrlPageId(undefined, true);
      } else {
        dispatch(updateCurrentPage({ name: defaultTitle }));
        clearEditor();
      }
    })();
  }, [isPagesLoading, currentPageId]);

  useDispatchEditorClick(lexicalEditorRef.current);

  const getCreatingPageIdOrCurrentId = async () => {
    if (!currentlyCreatingPageRef.current) return currentPageIdRef.current;
    const page = await currentlyCreatingPageRef.current;
    return page && '_id' in page ? page._id! : undefined;
  };

  const createOrUpdatePage = async (pageToUpdate: EditedPage, { updateEditor }: { updateEditor?: boolean } = {}) => {
    const pageId = await getCreatingPageIdOrCurrentId();

    if (pageId) {
      const localPage = pagesByIdRef.current[pageId];
      const action = await dispatch(
        updatePage({
          id: pageId,
          slug,
          page: {
            order: localPage.order,
            page_type: localPage.page_type ?? undefined,
            ...pageToUpdate,
            parentId: 'parentId' in pageToUpdate ? pageToUpdate.parentId : localPage.parentId ?? 'root',
          },
        }),
      );
      const updatedPage = action.payload;
      if (!updatedPage || updatedPage instanceof AxiosError) return;

      return updatedPage;
    } else {
      const pagePromise = dispatch(createNewPage({ slug, page: pageToUpdate })).then(action => action.payload);
      currentlyCreatingPageRef.current = pagePromise;
      const newPage = await pagePromise;
      if (!newPage || newPage instanceof AxiosError) return;

      if (pagePromise === currentlyCreatingPageRef.current) {
        skipEditorUpdateRef.current = !updateEditor;
        savePageIdToLocalStorage(slug!, newPage._id!);
        setUrlPageId(newPage._id!);
      }

      return newPage;
    }
  };

  const onContentChange = useMemo(
    () =>
      debounce(async (pageToUpdate: EditedPage) => {
        if (ignoreNextEditorUpdate.current) {
          ignoreNextEditorUpdate.current = false;
          return;
        }

        createOrUpdatePage(pageToUpdate);
      }, 500),
    [],
  );

  const onTitleValidate = async (nextTitle: string) => {
    if (!currentPage) return;

    if (!nextTitle.trim() || nextTitle.trim() === currentPage.name) {
      setPageTitle(currentPage.name);
    } else {
      const page = { ...currentPage, name: nextTitle };
      dispatch(updateCurrentPage(page));
      createOrUpdatePage(page);
    }
  };

  const onPage = useCallback(
    (pageId: string) => {
      skipEditorUpdateRef.current = false;
      setUrlPageId(pageId);
    },
    [searchParams],
  );

  const onCreate: CreateHandler<TreePage> = async ({ parentId }) => {
    const firstParentChild =
      parentId === null ? pagesRef.current?.[0] : pagesRef.current?.find(page => page.parentId === parentId);

    currentPageIdRef.current = undefined;
    const newPage = await createOrUpdatePage(
      {
        name: defaultTitle,
        parentId: parentId ?? 'root',
        page_type: 'page',
        order: firstParentChild?.order !== undefined ? firstParentChild.order - 1 : 0,
      },
      { updateEditor: true },
    );
    if (!newPage || !('_id' in newPage)) throw new Error('Missing page');

    return { id: newPage._id! };
  };

  const onRename: RenameHandler<TreePage> = ({ id, name, node }) => {
    if (!pagesRef.current) return;

    updateTreePages(prevPages => prevPages.map(page => (page._id === id ? { ...page, name } : page)));
    createOrUpdatePage({ _id: node.data.item.id, name });

    if (currentPageId === node.data.item.id) {
      dispatch(updatePageTitle(name));
    }
  };

  const onMove: MoveHandler<TreePage> = ({ dragIds, parentId, parentNode, index }) => {
    updateTreePages(prevPages => {
      const nextOrder =
        (parentNode?.children?.[index]?.data?.item?.page?.order ?? pagesTree![index]?.item?.page?.order ?? Infinity) - 0.5;

      const updatedPages = sortBy(
        prevPages.map(page =>
          dragIds.includes(page._id!)
            ? {
                ...page,
                page_type: 'page' as const,
                parentId,
                order: nextOrder,
              }
            : { ...page },
        ),
        ['parentId', 'order'],
      );

      let order = 0;
      let prevParentId: PageMetadataParentId | undefined = undefined;
      // Order will be set to order that pages have inside the array.
      updatedPages.map(page => {
        if (prevParentId !== page.parentId) {
          order = 0;
          prevParentId = page.parentId;
        }

        order++;
        page.order = order;
      });

      return updatedPages;
    });

    const updatedPages = pagesRef.current!.map(({ _id, name, order, page_type, parentId: parentIdToUpdate }) => ({
      page_id: _id!,
      name,
      order,
      parentId: parentIdToUpdate,
      page_type,
    }));
    dispatch(updatePages({ slug, pages: updatedPages }));
  };

  const onDelete: DeleteHandler<TreePage> = async ({ ids }) => {
    const multiplePages = ids.length > 1;
    const result = await showConfirmDialog({
      title: multiplePages ? t('deleteConfirm.titleMultiple') : t('deleteConfirm.title'),
      description: multiplePages ? t('deleteConfirm.descriptionMultiple') : t('deleteConfirm.description'),
      confirm: t('deleteConfirm.confirm'),
    });
    if (!result) return;

    ids.forEach(pageId => dispatch(deletePage({ slug, id: pageId })));
    updateTreePages(prevPages => prevPages.filter(({ _id }) => !ids.includes(_id!)));

    if (currentPageId && !ids.includes(currentPageId)) return;
    setUrlPageId(pagesRef.current?.[0]?._id, true);
    skipEditorUpdateRef.current = false;
  };

  const onPublicToggle = async () => {
    if (!currentPage || !isExistedPage(currentPage)) return;

    const nextIsPrivate = !currentPage.isPrivate;
    const result = nextIsPrivate
      ? true
      : await showConfirmDialog({
          title: t('editor.makePagePublicConfirm.title'),
          description: t('editor.makePagePublicConfirm.description'),
          confirm: t('editor.makePagePublicConfirm.confirm'),
        });
    if (!result) return;

    dispatch(toggleIsPrivate({ id: currentPage._id!, slug, state: nextIsPrivate }));
  };

  const onExport = async () => {
    if (!lexicalEditorRef.current) return;

    const logoBase64 = await loadImageBase64(organization.logo.url);
    const proportions = organization.logo.width! / organization.logo.height!;

    lexicalEditorRef.current.update(async () => {
      const todayDate = format(Date.now(), 'P');
      const title = currentPage?.name ?? '';
      const pageName = title ? `${title}-` : 'pelles-page-';

      const nodes = $getRoot().getChildren();
      const blob = await pdf(
        <LexicalPdfExport title={currentPage!.name} nodes={nodes} logoBase64={logoBase64} logoProportions={proportions} />,
      ).toBlob();
      saveAs(blob, `${pageName}${todayDate}.pdf`);
    });
  };

  const onCopy = () => {
    if (!lexicalEditorRef.current) return;

    lexicalEditorRef.current.getEditorState().read(async () => {
      const htmlString = $generateHtmlFromNodes(lexicalEditorRef.current!);
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([htmlString], { type: 'text/html' }),
        'text/plain': new Blob([htmlString], { type: 'text/plain' }),
      });

      try {
        await navigator.clipboard.write([clipboardItem]);
        enqueueSnackbar(t('editor.copySuccess'));
      } catch (error) {
        enqueueSnackbar(t('editor.copyError'), { variant: 'error' });
      }
    });
  };

  const resetSlider = () => {
    if (isSliderActionDataLoading) return;
    setSliderStateActions(INITIAL_STATE);
    setIsConfirmButtonShowed(false);
    setIsSliderChangeDisabled(false);
  };

  const putAiTextIntoEditor = (textFromAi: string) => {
    if (!lexicalEditorRef.current) return;

    lexicalEditorRef.current.update(() => {
      $getRoot().clear();

      const newParagraphNode = $createElementNodeFromMarkdown(textFromAi);
      const selection = $createRangeSelection();
      selection.insertNodes(newParagraphNode.getChildren());
    });
    resetSlider();
  };

  const onValueChange = (_: Event, newValue: number | number[]) => {
    if (isSliderChangeDisabled) return;

    setSliderStateActions(prev => ({ ...prev, sliderValue: newValue as number }));
    setActionData({
      ...actionData,
      params: { change_percent: newValue as number },
    });
  };

  const onValueChangeCommitted = () => {
    setIsSliderChangeDisabled(true);
    setIsConfirmButtonShowed(true);
  };

  const onConfirmSelection = async () => {
    try {
      setIsSliderActionDataLoading(true);
      const markdown = await editPage(slug, currentPageId!, {
        action: actionData.action,
        params: actionData.params,
      });
      putAiTextIntoEditor(markdown as string);
    } catch (err) {
      console.error(err);
    } finally {
      setIsSliderActionDataLoading(false);
    }
  };

  const handleAction = (action: PageActionType, params: PageEditActionParams) => {
    setActionData({ action, params });
    setSliderStateActions(prev => ({
      ...prev,
      showSlider: action !== PageActionType.touch_up,
    }));
  };

  useEffect(() => {
    if (actionData.action === PageActionType.touch_up) {
      onConfirmSelection();
    }
  }, [actionData]);

  const renderEditorActions = () => (
    <Stack
      sx={{
        position: 'absolute',
        bottom: spacing(EDITOR_SHEET_MARGIN_SPACING + 2),
        left: spacing(EDITOR_SHEET_MARGIN_SPACING + 2),
      }}
      alignItems="center"
      gap={1.5}
    >
      {sliderStateActions.showSlider && (
        <Stack
          sx={{
            height: 225,
            mb: 1.5,
            boxShadow: 1,
            backgroundColor: palette.background.default,
            py: 2,
            px: 2.25,
            borderRadius: 999,
          }}
        >
          <SliderWithConfirm
            onClickAway={resetSlider}
            isConfirmButtonShowed={isConfirmButtonShowed}
            isSliderActionDataLoading={isSliderActionDataLoading}
            onValueChangeCommitted={onValueChangeCommitted}
            onValueChange={onValueChange}
            onConfirmSelection={onConfirmSelection}
            sliderValue={sliderStateActions.sliderValue}
            type={actionData.action}
          />
        </Stack>
      )}
      <FabMenu popupId="editor-fabs" resetSlider={resetSlider}>
        <Tooltip arrow title={t('editor.touchUps')} placement="right">
          <FabButton iconName="paintBrush" onClick={() => handleAction(PageActionType.touch_up, null)} />
        </Tooltip>
        <Tooltip arrow title={t('editor.writingLevel')} placement="right">
          <FabButton
            iconName="reading"
            onClick={() =>
              handleAction(PageActionType.change_page_reading_level, {
                change_percent: sliderStateActions.sliderValue,
              })
            }
          />
        </Tooltip>
        <Tooltip arrow title={t('editor.pageLength')} placement="right">
          <FabButton
            iconName="paragraphAdjustment"
            onClick={() => handleAction(PageActionType.change_page_length, { change_percent: sliderStateActions.sliderValue })}
          />
        </Tooltip>
        {!!currentPage && isExistedPage(currentPage) && (
          <Tooltip
            arrow
            title={currentPage.isPrivate ? t('editor.makePagePublic') : t('editor.makePagePrivate')}
            placement="right"
          >
            <FabButton iconName={currentPage.isPrivate ? 'public' : 'private'} onClick={onPublicToggle} />
          </Tooltip>
        )}
        <Tooltip arrow title={t('editor.exportPDF')} placement="right">
          <FabButton iconName="download" onClick={onExport} />
        </Tooltip>
        <Tooltip arrow title={t('editor.copy')} placement="right">
          <FabButton iconName="cards" onClick={onCopy} />
        </Tooltip>
      </FabMenu>
    </Stack>
  );

  const renderEditor = () => {
    if (isPagesLoading || (isCurrentPageLoading && !skipEditorUpdateRef.current)) {
      return (
        <Stack justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Stack>
      );
    }

    return (
      <>
        <TemplateContextProvider processStatus={templatePage?.isReady ? 'ready' : 'in-progress'}>
          <PagesEditor
            ref={lexicalEditorRef}
            sx={{ display: 'grid', gridTemplateRows: 'auto 1fr', m: EDITOR_SHEET_MARGIN_SPACING }}
            shellSx={{ height: `calc(100% + ${spacing(3)})`, mt: -3 }}
            placeholder={t('editor.placeholder')}
            isReadOnly={!!notNewPage && !notNewPage.isReady}
            initialState={currentPage?.content as SerializedLexicalNode[]}
            onChange={(content, markdown) => onContentChange({ ...currentPage!, content, markdown })}
            pluginsWithAnchor={({ anchorElem, setIsLinkEditMode }) => (
              <>
                <AskAiPlugin
                  anchorElem={anchorElem}
                  width={width}
                  minMaxHeight={minMaxHeight}
                  featureName={featureName}
                  aiMenuItems={aiMenuItems}
                  projectId={projectId ?? undefined}
                  askAi={askAi}
                  getSuggestions={getSuggestions}
                />
                <FloatingTextFormatToolbarPlugin anchorElem={anchorElem} setIsLinkEditMode={setIsLinkEditMode} />
              </>
            )}
          >
            <Box sx={getFadingShadowSxProps(7)}>
              <CellEditable
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                  p: 3,
                  pl: `${EDITOR_CONTENT_LEFT_PADDING}px`,
                  pr: 0,
                  ...typography.h1,
                  '&:hover, &:focus, &.Mui-focused ': {
                    '&.MuiInputBase-root': { borderRadius: 0, borderColor: 'transparent' },
                  },
                }}
                textAlign="left"
                value={pageTitle}
                onChangeValue={setPageTitle}
                onValidateValue={onTitleValidate}
              />
            </Box>
          </PagesEditor>
        </TemplateContextProvider>
        {renderEditorActions()}
      </>
    );
  };

  return (
    <Box
      sx={{
        display: 'grid',
        height: '100%',
        gridTemplateRows: isPagesLoading ? 'auto' : 'auto 1fr',
        gridTemplateColumns: '100%',
      }}
    >
      {!isPagesLoading && (
        <PagesDrawer
          slug={slug}
          currentPageId={currentPageId}
          currentPagePath={currentPagePath}
          pagesTree={pagesTree}
          onPage={onPage}
          onMove={onMove}
          onCreate={onCreate}
          onRename={onRename}
          onDelete={onDelete}
        />
      )}
      {renderEditor()}
    </Box>
  );
};

export default Pages;
