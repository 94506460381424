import { createContext } from 'react';
import { Checklist } from '@/api/generated';

export type ChecklistsManagerDialogMode = 'select' | 'select-and-ask-question' | 'default';
export type ChecklistsManagerDialogOptions = {
  showNewQuestionButton?: boolean;
  mode?: ChecklistsManagerDialogMode;
};

export type ChecklistsManagerDialogCloseData = {
  action?: 'askSingleQuestion';
  checklists?: Checklist[];
};

export type ChecklistsManagerDialogContextValue = {
  showChecklistsManagerDialog(options?: ChecklistsManagerDialogOptions): Promise<ChecklistsManagerDialogCloseData>;
};

export const ChecklistsManagerDialogContext = createContext<ChecklistsManagerDialogContextValue | null>(null);
