import { IconButton, IconButtonProps, Menu, MenuItem, MenuItemProps as MuiMenuItemProps, useTheme } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import Icon, { IconProps as IconPropsType } from '../Icon/Icon';

export interface MenuItemProps extends MuiMenuItemProps {
  id: string;
}

interface MoreActionsProps {
  menuItems: MenuItemProps[];
  id: string;
  isDisabled?: boolean;
  ButtonProps?: IconButtonProps;
  IconProps?: Omit<IconPropsType, 'name'>;
  disablePortal?: boolean;
}

const MoreActions: FC<MoreActionsProps> = ({
  menuItems,
  id,
  isDisabled = false,
  disablePortal = true,
  ButtonProps,
  IconProps,
}) => {
  const { palette, shape } = useTheme();
  const popupState = usePopupState({ popupId: id, variant: 'popover' });

  const blockLinkAction = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onOpen = (event: MouseEvent) => {
    blockLinkAction(event);
    popupState.toggle();
    popupState.setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        {...bindTrigger(popupState)}
        disableRipple
        disabled={isDisabled}
        onClick={(event: MouseEvent) => onOpen(event)}
        {...ButtonProps}
        sx={{ p: 0, '&, &:hover': { backgroundColor: 'transparent' }, ...ButtonProps?.sx }}
      >
        <Icon {...IconProps} name="treeDotsVertical" sx={{ color: palette.grey[900], ...IconProps?.sx }} />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        disablePortal={disablePortal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          zIndex: 2000,
          '.MuiList-root': { p: 0 },
          '.MuiMenuItem-root': {
            py: 2,
            '&:not(:last-child)': {
              borderBottom: `1px solid ${palette.custom.separationLine}`,
            },
          },
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: palette.background.default,
              boxShadow: 2,
              borderRadius: shape.borderRadius,
              mt: 1,
            },
          },
        }}
        onClick={blockLinkAction}
      >
        {menuItems.map(({ id: itemId, onClick, ...rest }) => (
          <MenuItem
            key={itemId}
            onClick={(...args) => {
              onClick?.(...args);
              popupState.close();
            }}
            {...rest}
          />
        ))}
      </Menu>
    </>
  );
};

export default MoreActions;
