import { PageResponse } from '@/api/generated';

import { EditedPage } from '@/containers/PagesEditor/types';

export const isExistedPage = (page: EditedPage): page is PageResponse => '_id' in page && !!page._id;

const getPageIdsLocalStorage = () => JSON.parse(localStorage.getItem('automatePageIds') ?? '{}') as Record<string, string>;

export const getPageIdFromLocalStorage = (projectSlug: string) => getPageIdsLocalStorage()[projectSlug];

export const savePageIdToLocalStorage = (projectSlug: string, pageId: string) => {
  const pageIdsStorage = getPageIdsLocalStorage();
  pageIdsStorage[projectSlug] = pageId;
  localStorage.setItem('automatePageIds', JSON.stringify(pageIdsStorage));
};
