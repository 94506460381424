import { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import ToolbarMenuItem, { ToolbarMenuItemProps } from '@/containers/PagesEditor/components/ToolbarMenuItem';

interface ToolbarColorMenuItemProps extends Omit<ToolbarMenuItemProps, 'color' | 'type'> {
  color: string;
  type: 'text' | 'background';
}

const ToolbarColorMenuItem: FC<ToolbarColorMenuItemProps> = ({ color, type, children, ...props }) => {
  const { palette } = useTheme();

  return (
    <ToolbarMenuItem {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 14,
          height: 14,
          p: 1,
          border: `1px solid ${palette.grey[50]}`,
          borderRadius: 1,
          fontSize: 'body1.fontSize',
          color: type === 'text' ? color : '',
          backgroundColor: type === 'background' ? color : '',
        }}
      >
        A
      </Box>
      {children}
    </ToolbarMenuItem>
  );
};

export default ToolbarColorMenuItem;
