import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/store';
import { PageMetadata, TemplatePageMetadata } from '@/api/generated';
import { EditedPage } from '@/containers/PagesEditor/types';
import {
  createNewPage,
  createPageFromTemplate,
  deletePage,
  loadCurrentPage,
  loadPages,
  updatePage,
  waitForPageReady,
} from '@/store/lexicalPagesThunks';
import { savePageIdToLocalStorage } from '@/components/Pages/utils';

type PagesProcessState = Record<string, boolean>;

export type LexicalPagesState = {
  currentPage?: EditedPage;
  isCurrentPageLoading?: boolean;
  currentPageLoadError: Error | null;
  pages: Record<string, (PageMetadata | TemplatePageMetadata)[]>;
  isPagesLoading: Record<string, boolean>;
  pagesProcessState: PagesProcessState;
};

type SetPageProcessingState = { id: string; state: boolean };
type UpdateCurrentPagePayload = EditedPage;

const initialState = {
  currentPage: undefined,
  isCurrentPageLoading: false,
  pagesProcessState: {},
  pages: {},
  isPagesLoading: {},
  currentPageLoadError: null,
} as LexicalPagesState;

const getCurrentPageId = () => {
  if (typeof window === 'undefined') return null;

  const params = new URLSearchParams(window.location.search);
  return params.get('pageId');
};

export const lexicalPagesSlice = createSlice({
  name: 'lexicalPages',
  initialState,
  reducers: {
    updatePageTitle: (state, action: PayloadAction<string>) => ({
      ...state,
      currentPage: { ...state.currentPage, name: action.payload },
    }),
    updateCurrentPage: (state, action: PayloadAction<UpdateCurrentPagePayload>) => ({
      ...state,
      currentPage: action.payload,
    }),
    setPageProcessingState: (state, action: PayloadAction<SetPageProcessingState>) => ({
      ...state,
      pagesProcessState: {
        ...state.pagesProcessState,
        [action.payload.id]: action.payload.state,
      },
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(loadPages.pending, (state: LexicalPagesState, action) => {
        const { slug } = action.meta.arg;
        state.isPagesLoading[slug] = true;
      })
      .addCase(loadPages.fulfilled, (state: LexicalPagesState, action) => {
        const { slug } = action.meta.arg;
        state.pages[slug] = action.payload;
        state.isPagesLoading[slug] = false;
      })
      .addCase(loadPages.rejected, (state: LexicalPagesState, action) => {
        const { slug } = action.meta.arg;
        state.isPagesLoading[slug] = false;
      });

    builder
      .addCase(loadCurrentPage.pending, (state: LexicalPagesState) => {
        state.isCurrentPageLoading = true;
        state.currentPageLoadError = null;
      })
      .addCase(loadCurrentPage.fulfilled, (state: LexicalPagesState, action) => {
        const { id, slug } = action.meta.arg;
        if (getCurrentPageId() !== id) return;

        state.currentPage = action.payload;
        state.isCurrentPageLoading = false;
        state.currentPageLoadError = null;
        savePageIdToLocalStorage(slug, id);
      })
      .addCase(loadCurrentPage.rejected, (state: LexicalPagesState, action) => {
        if (getCurrentPageId() !== action.meta.arg.id) return;
        state.currentPage = undefined;
        state.isCurrentPageLoading = false;
        state.currentPageLoadError = action.error as unknown as Error;
      });

    builder.addCase(createNewPage.fulfilled, (state: LexicalPagesState, action) => {
      const slug = action.meta.arg.slug;
      state.pages[slug] = [...(state.pages[slug] ?? []), action.payload as PageMetadata];
    });

    builder
      .addCase(updatePage.pending, (state: LexicalPagesState, action) => {
        const { id, slug, page } = action.meta.arg;
        state.pages[slug] = state.pages[slug]?.map(prevPage => (prevPage._id === id ? { ...prevPage, ...page } : prevPage));
      })
      .addCase(updatePage.fulfilled, (state: LexicalPagesState, action) => {
        const { id, slug } = action.meta.arg;
        state.pages[slug] = state.pages[slug]?.map(prevPage => (prevPage._id === id ? action.payload : prevPage));
      });

    builder.addCase(createPageFromTemplate.fulfilled, (state: LexicalPagesState, action) => {
      const { slug } = action.meta.arg;
      state.pages[slug] = [...(state.pages[slug] ?? []), action.payload];
    });

    builder.addCase(deletePage.pending, (state: LexicalPagesState, action) => {
      const { id, slug } = action.meta.arg;

      if (getCurrentPageId() === id) state.currentPage = undefined;
      state.pages[slug] = state.pages[slug]?.filter(prevPage => prevPage._id !== id);
    });

    builder
      .addCase(waitForPageReady.fulfilled, (state: LexicalPagesState, action) => {
        const { id, slug } = action.meta.arg;
        const templatePage = action.payload!;
        state.pagesProcessState[id] = false;

        const pageIndex = state.pages[slug].findIndex(prevPage => prevPage._id === id);
        if (pageIndex !== -1) state.pages[slug][pageIndex] = templatePage;

        const currentPage = state.currentPage;
        if (currentPage && '_id' in currentPage && currentPage._id === id) {
          state.currentPage = templatePage;
        }
      })
      .addCase(waitForPageReady.rejected, (state: LexicalPagesState, action) => {
        if (action.payload instanceof Error) return;
        const { id } = action.meta.arg;
        state.pagesProcessState[id] = false;
      });
  },
});

export const { updateCurrentPage, setPageProcessingState, updatePageTitle } = lexicalPagesSlice.actions;

export const selectPageProcessingState = (state: RootState, id: string) => state.lexicalPages.pagesProcessState[id];

export const selectCurrentPage = (state: RootState) => ({
  currentPage: state.lexicalPages.currentPage,
  isCurrentPageLoading: state.lexicalPages.isCurrentPageLoading,
});

export const selectPages = (slug: string) => (state: RootState) => ({
  pages: state.lexicalPages.pages[slug],
  isPagesLoading: state.lexicalPages.isPagesLoading[slug],
});
