import { $nodesOfType } from 'lexical';
import { AITemplateBlock } from '@/api/generated';
import { $createElementNodeFromMarkdown } from '@/containers/PagesEditor/utils/$createElementNodeFromMarkdown';
import { InlineAiContainerNode } from '@/containers/PagesEditor/nodes/InlineAiContainerNode';
import { $createInlineAiContentNode, $isInlineAiContentNode } from '@/containers/PagesEditor/nodes/InlineAiContentNode';

export const $updateInlineAiBlocksContent = (aiBlocks: AITemplateBlock[]) => {
  if (!aiBlocks?.length) return;

  const aiContainerNodes = $nodesOfType(InlineAiContainerNode);
  if (!aiContainerNodes.length) return;

  aiContainerNodes.forEach(node => {
    const aiBlock = aiBlocks.find(block => block._id === node.getUuid());
    if (!aiBlock?.content) return;

    const hasContent = node.getChildren().some(child => $isInlineAiContentNode(child));
    if (hasContent) return;

    const contentNode = $createInlineAiContentNode();
    const paragraph = $createElementNodeFromMarkdown(aiBlock.content!);
    contentNode.append(...paragraph.getAllTextNodes());
    node.append(contentNode);
  });
};
