import { QueryClient } from '@tanstack/react-query';
import { getGetAllTemplatePagesQueryKey, TemplatePage } from '@/api/generated';

type Params = {
  queryClient: QueryClient;
};

export const updateTemplatesCache = (
  { queryClient }: Params,
  cb: (prevTemplates?: TemplatePage[]) => TemplatePage[] | undefined,
) => {
  queryClient.setQueryData<TemplatePage[]>(getGetAllTemplatePagesQueryKey(), cb);
};
