import { $applyNodeReplacement, DecoratorNode, LexicalNode, NodeKey, type SerializedLexicalNode, Spread } from 'lexical';
import { AiAnswerType } from '@/containers/PagesEditor/types';
import AiBlock from '@/containers/PagesEditor/components/AiBlock/AiBlock';

type BlockSettings = { prompt: string; answerType: AiAnswerType };

export type SerializedAiNode = Spread<{ prompt: string; answerType: AiAnswerType }, SerializedLexicalNode>;

export class AiNode extends DecoratorNode<JSX.Element> {
  /** @internal */
  __prompt: string;

  /** @internal */
  __answerType: AiAnswerType;

  static getType() {
    return 'ai';
  }

  static clone(node: AiNode): AiNode {
    return new AiNode({ prompt: node.getPrompt(), answerType: node.getAnswerType() }, node.__key);
  }

  static importJSON(serializedNode: SerializedAiNode): AiNode {
    return $createAiNode({ prompt: serializedNode.prompt, answerType: serializedNode.answerType });
  }

  constructor(settings: BlockSettings, key?: NodeKey) {
    super(key);
    this.__prompt = settings.prompt;
    this.__answerType = settings.answerType;
  }

  getAnswerType(): AiAnswerType {
    return this.__answerType;
  }

  setAnswerType(answerType: AiAnswerType) {
    const writable = this.getWritable();
    writable.__answerType = answerType;
  }

  getPrompt(): string {
    return this.__prompt;
  }

  setPrompt(prompt: string) {
    const writable = this.getWritable();
    writable.__prompt = prompt;
  }

  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  updateDOM() {
    return false;
  }

  exportJSON(): SerializedAiNode {
    return {
      type: 'ai',
      version: 1,
      prompt: this.getPrompt(),
      answerType: this.getAnswerType(),
    };
  }

  remove(preserveEmptyParent?: boolean) {
    super.remove(preserveEmptyParent);
    this.getParent()?.remove();
  }

  decorate(): JSX.Element {
    return <AiBlock nodeKey={this.getKey()} initialPrompt={this.getPrompt()} initialAnswerType={this.getAnswerType()} />;
  }
}

export function $createAiNode(settings: BlockSettings) {
  return $applyNodeReplacement(new AiNode(settings));
}

export function $isAiNode(node: LexicalNode | null | undefined): node is AiNode {
  return node instanceof AiNode;
}
