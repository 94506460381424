import { $createTextNode, $nodesOfType } from 'lexical';
import { VariableTemplateBlock } from '@/api/generated';
import { VariableNode } from '@/containers/PagesEditor/nodes/VariableNode';

export const $updateVariableBlocksContent = (aiBlocks: VariableTemplateBlock[]) => {
  if (!aiBlocks?.length) return;

  const variableNodes = $nodesOfType(VariableNode);
  if (!variableNodes.length) return;

  variableNodes.forEach(node => {
    const variableBlock = aiBlocks.find(block => block._id === node.getUuid());
    if (!variableBlock?.content) return;

    const contentNode = $createTextNode(variableBlock.content);
    node.replace(contentNode);
  });
};
