import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Breadcrumbs, Button, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { RxDoubleArrowLeft, RxHamburgerMenu } from 'react-icons/rx';
import { CreateHandler, DeleteHandler, MoveHandler, NodeRendererProps, RenameHandler, Tree, TreeApi } from 'react-arborist';
import TreePageNode from '@/components/Pages/components/TreePageNode';
import { type TreePage } from '@/components/Pages/types';
import SearchInput from '@/views/Project/views/Navigator/components/SearchInput';
import useEditorClick from '@/containers/PagesEditor/hooks/useEditorClick';
import Icon from '@/components/Icon/Icon';
import Drawer from '@/components/Drawer';
import { getFadingShadowSxProps } from '@/utils/getFadingShadowSxProps';
import { toTemplatePages } from '@/services/linker';
import useRouteId from '@/hooks/useRouteId';
import { usePageFromTemplateDialog } from '@/hooks/usePageFromTemplateDialog';

interface PagesDrawerProps {
  slug: string;
  currentPageId?: string | null;
  currentPagePath?: string[];
  pagesTree?: TreePage[];
  onPage: (pageId: string) => void;
  onMove: MoveHandler<TreePage>;
  onCreate: CreateHandler<TreePage>;
  onRename: RenameHandler<TreePage>;
  onDelete: DeleteHandler<TreePage>;
}

const PagesDrawer: FC<PagesDrawerProps> = ({
  slug,
  currentPageId,
  currentPagePath,
  pagesTree,
  onPage,
  onMove,
  onCreate,
  onRename,
  onDelete,
}) => {
  const { t } = useTranslation('lexicalEditor');
  const treeRef = useRef<TreeApi<TreePage>>();
  const dndRootRef = useRef<HTMLDivElement | null>(null);
  const { palette, spacing } = useTheme();
  const [, setSearchParams] = useSearchParams();
  const routeId = useRouteId();
  const { showPageFromTemplateDialog } = usePageFromTemplateDialog();

  const [searchTerm, setSearchTerm] = useState('');
  const [isPagesMenuOpened, setIsPagesMenuOpened] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isParentMounted, setIsParentMounted] = useState(false);

  useEditorClick(() => setIsPagesMenuOpened(false));

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsParentMounted(true);
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  const handlePageChange = useCallback(
    (node: TreePage) => {
      setIsPagesMenuOpened(false);
      onPage(node.item.id);
    },
    [onPage],
  );

  const onAddToRoot = () => {
    treeRef.current?.create();
  };

  const handleCreate: CreateHandler<TreePage> = (...params) => {
    setSearchTerm('');
    return onCreate(...params);
  };

  const handleCreateFromTemplateClick = async () => {
    const result = await showPageFromTemplateDialog(slug);
    if (!result) return;

    setSearchParams(prevParams => {
      const nextParams = new URLSearchParams(prevParams);
      nextParams.set('pageId', result._id!);
      return nextParams;
    });
  };

  const TreePage = useCallback(
    (nodeProps: NodeRendererProps<TreePage>) => <TreePageNode {...nodeProps} onNameClick={handlePageChange} />,
    [handlePageChange],
  );

  const renderBreadcrumbs = () => {
    if (!currentPagePath?.length) return <Box />;

    return (
      <Breadcrumbs maxItems={2} key={currentPageId}>
        {currentPagePath.map((pathPart, index) => (
          <Box
            key={index}
            sx={{
              maxWidth: 180,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {pathPart}
          </Box>
        ))}
      </Breadcrumbs>
    );
  };

  return (
    <Box ref={dndRootRef} sx={{ position: 'relative' }}>
      <Box sx={{ display: 'inline-block', width: '100%', ...getFadingShadowSxProps(8) }}>
        <Stack direction="row" gap={1} alignItems="center" pr={0} p={3} width="100%">
          <Tooltip arrow title={t('tooltips.pagesLibrary')}>
            <IconButton className="drawer-toggle" onClick={() => setTimeout(() => setIsPagesMenuOpened(isOpened => !isOpened))}>
              {isPagesMenuOpened ? <RxDoubleArrowLeft /> : <RxHamburgerMenu />}
            </IconButton>
          </Tooltip>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            {renderBreadcrumbs()}

            {!import.meta.env.PROD && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Button
                  color="accent"
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: 99 }}
                  startIcon={<Icon name="aiDocs" />}
                  onClick={handleCreateFromTemplateClick}
                >
                  {t('createFromTemplate')}
                </Button>
                <Button
                  component={Link}
                  to={toTemplatePages({ from: routeId, fromSlug: slug })}
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: 99 }}
                >
                  {t('goToTemplates')}
                </Button>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>

      <Drawer sx={{ top: 90, ml: 3 }} isOpened={isPagesMenuOpened} onClose={() => setIsPagesMenuOpened(false)}>
        <Box sx={{ minWidth: 310, minHeight: 500 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1,
              borderBottom: `1px solid ${palette.custom.separationLine}`,
            }}
          >
            <Tooltip
              arrow
              title={t('tooltips.search')}
              open={openTooltip}
              onOpen={() => setOpenTooltip(true)}
              onClose={() => setOpenTooltip(false)}
            >
              <SearchInput
                value={searchTerm}
                onChange={event => {
                  setSearchTerm(event.target.value);
                  setOpenTooltip(false);
                }}
                isOpen
              />
            </Tooltip>
            <Tooltip arrow title={t('tooltips.createNewPage')} placement="left">
              <IconButton onClick={onAddToRoot}>
                <Icon name="plus" fontSize="small" htmlColor={palette.primary.dark} />
              </IconButton>
            </Tooltip>
          </Box>

          {!pagesTree?.length && <Box sx={{ p: 2, textAlign: 'center' }}>{t('noPages')}</Box>}
          {isParentMounted && (
            <Tree
              ref={treeRef}
              dndRootElement={dndRootRef.current}
              width={310}
              height={500}
              padding={parseInt(spacing(2))}
              indent={parseInt(spacing(2))}
              openByDefault={false}
              searchTerm={searchTerm}
              searchMatch={(node, term) => node.data.item.name.toLowerCase().includes(term.toLowerCase())}
              rowHeight={38}
              data={pagesTree}
              idAccessor={node => node.item.id}
              childrenAccessor="children"
              onMove={onMove}
              onCreate={handleCreate}
              onRename={onRename}
              onDelete={onDelete}
            >
              {TreePage}
            </Tree>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default PagesDrawer;
