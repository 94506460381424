import { useEffect, useRef, useState } from 'react';
import { Instance, IScrollMode, ViewState } from '@nutrient-sdk/viewer';

type Params = {
  instance?: Instance;
};

type Point = { x: number; y: number };

export const useRightMousePan = ({ instance }: Params) => {
  const [scrollMode, setScrollMode] = useState<IScrollMode>();
  const [pageIndex, setPageIndex] = useState(0);
  const pressedRightMouseCoords = useRef<Point | undefined>(undefined);
  const moveContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!instance) return;

    setScrollMode(instance.viewState.scrollMode);
    const onViewStateChange = (viewState: ViewState) => {
      setScrollMode(viewState.scrollMode);
      setPageIndex(viewState.currentPageIndex);
    };
    instance.addEventListener('viewState.change', onViewStateChange);

    return () => {
      instance.removeEventListener('viewState.change', onViewStateChange);
    };
  }, [instance]);

  useEffect(() => {
    if (!instance) return;

    const pagesBlocks = instance.contentDocument.querySelectorAll<HTMLDivElement>('.PSPDFKit-Page');

    if (!pagesBlocks.length) return;

    const stopPropagation = (event: MouseEvent) => {
      if (event.button !== 2) return;
      event.stopPropagation();
    };

    pagesBlocks.forEach(block => block.addEventListener('pointerdown', stopPropagation));
    pagesBlocks.forEach(block => block.addEventListener('pointerup', stopPropagation));

    return () => {
      pagesBlocks.forEach(block => block.removeEventListener('pointerdown', stopPropagation));
      pagesBlocks.forEach(block => block.removeEventListener('pointerup', stopPropagation));
    };
  }, [instance, pageIndex, scrollMode]);

  useEffect(() => {
    if (!instance) return;

    const pagesScroll = instance.contentDocument.querySelector<HTMLDivElement>('.PSPDFKit-Scroll');

    if (!pagesScroll) return;

    const onContextMenu = (event: MouseEvent) => event.preventDefault();

    const onMouseDown = (event: MouseEvent) => {
      if (event.button !== 2) return;

      event.stopPropagation();
      const pageElement = (event.target as HTMLElement).closest('.PSPDFKit-Page');

      if (!pageElement) return;

      moveContainerRef.current?.remove();
      // We are creating container to overlap other absolute positioned elements
      // that force us to calc relative coords on each mouse move.
      moveContainerRef.current = document.createElement('div');
      moveContainerRef.current.className = 'PSPDFKit-Page__move-container';
      moveContainerRef.current.style.touchAction = 'none';
      moveContainerRef.current.style.boxSizing = 'border-box';
      moveContainerRef.current.style.position = 'absolute';
      moveContainerRef.current.style.bottom = '0';
      moveContainerRef.current.style.left = '0';
      moveContainerRef.current.style.right = '0';
      moveContainerRef.current.style.top = '0';
      moveContainerRef.current.style.userSelect = 'none';
      pageElement.appendChild(moveContainerRef.current);

      const rect = moveContainerRef.current.getBoundingClientRect();
      pressedRightMouseCoords.current = { x: event.clientX - rect.left, y: event.clientY - rect.top };
      pagesScroll.classList.add('PSPDFKit-Scroll__dragged');
    };

    const onMouseUp = (event: MouseEvent) => {
      if (event.button === 2) event.stopPropagation();

      pagesScroll.classList.remove('PSPDFKit-Scroll__dragged');
      pressedRightMouseCoords.current = undefined;
      moveContainerRef.current?.remove();
    };

    const onMouseMove = (event: MouseEvent) => {
      if (!pressedRightMouseCoords.current) return;

      const xDiff = pressedRightMouseCoords.current.x - event.offsetX;
      const yDiff = pressedRightMouseCoords.current.y - event.offsetY;

      // 50 distance check is to prevent large jump when we in an end of scroll and we hover over an annotation.
      if (xDiff < 50) {
        pagesScroll.scrollLeft = pagesScroll.scrollLeft + xDiff;
      }
      if (yDiff < 50) {
        pagesScroll.scrollTop = pagesScroll.scrollTop + yDiff;
      }
    };

    document.body.addEventListener('mouseup', onMouseUp);
    pagesScroll.addEventListener('mousedown', onMouseDown);
    pagesScroll.addEventListener('mousemove', onMouseMove);
    pagesScroll.addEventListener('contextmenu', onContextMenu);

    return () => {
      document.body.removeEventListener('mouseup', onMouseUp);
      pagesScroll.removeEventListener('mousedown', onMouseDown);
      pagesScroll.removeEventListener('mousemove', onMouseMove);
      pagesScroll.removeEventListener('contextmenu', onContextMenu);
      moveContainerRef.current?.remove();
    };
  }, [instance, pageIndex, scrollMode]);
};
