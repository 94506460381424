import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/store';
import { TreeFileSystemNode } from '@/types';
import { createAppSelector } from './createAppSelector';

export type FileTreeState = {
  tree: {
    [slug: string]: TreeFileSystemNode[];
  };
  selectedNodes: {
    [slug: string]: undefined | { [key: TreeFileSystemNode['id']]: boolean };
  };
};

type SetFileTreePayload = {
  slug: string;
  fileTree: TreeFileSystemNode[];
};

type SetSelectedNodePayload = {
  slug: string;
  node: TreeFileSystemNode;
  forceSelected?: boolean;
};

type ClearSelectedNodesPayload = {
  slug: string;
};

type RemoveSelectedNodesIdsPayload = {
  slug: string;
  ids: TreeFileSystemNode['id'][];
};

const fileTreeSlice = createSlice({
  name: 'fileTree',
  initialState: {
    tree: {},
    selectedNodes: {},
  } as FileTreeState,
  reducers: {
    toggleSelectedNode: (state: FileTreeState, action: PayloadAction<SetSelectedNodePayload>) => {
      const nodeId = action.payload.node.id;
      const isSelected = action.payload.forceSelected ?? !!state.selectedNodes[action.payload.slug]?.[nodeId];

      const collectIds = (node: TreeFileSystemNode): TreeFileSystemNode['id'][] =>
        node.children?.reduce<TreeFileSystemNode['id'][]>(
          (acc, child) => [...acc, child.id, ...collectIds(child)],
          [node.id],
        ) || [node.id];

      const ids = collectIds(action.payload.node);

      return {
        ...state,
        selectedNodes: {
          ...state.selectedNodes,
          [action.payload.slug]: {
            ...state.selectedNodes[action.payload.slug],
            ...ids.reduce((acc, id) => ({ ...acc, [id]: !isSelected }), {}),
          },
        },
      };
    },
    setFileTree: (state: FileTreeState, action: PayloadAction<SetFileTreePayload>) => ({
      ...state,
      tree: {
        ...state.tree,
        [action.payload.slug]: action.payload.fileTree,
      },
    }),
    clearSelectedNodes: (state: FileTreeState, action: PayloadAction<ClearSelectedNodesPayload>) => ({
      ...state,
      selectedNodes: {
        ...state.selectedNodes,
        [action.payload.slug]: {},
      },
    }),
    removeSelectedNodeIds: (state: FileTreeState, action: PayloadAction<RemoveSelectedNodesIdsPayload>) => ({
      ...state,
      selectedNodes: {
        ...state.selectedNodes,
        [action.payload.slug]: Object.keys(state.selectedNodes[action.payload.slug] || {}).reduce(
          (acc, id) =>
            !action.payload.ids.includes(id) ? { ...acc, [id]: state.selectedNodes[action.payload.slug]?.[id] } : acc,
          {},
        ),
      },
    }),
  },
});

const { setFileTree, toggleSelectedNode, clearSelectedNodes, removeSelectedNodeIds } = fileTreeSlice.actions;

const EMPTY_STATE: TreeFileSystemNode[] = [];
export const selectProjectFileTree = (projectSlug?: string) => (state: RootState) =>
  projectSlug ? state.fileTree.tree[projectSlug] : EMPTY_STATE;

export const selectSelectedNodes = (state: RootState, projectSlug?: string) =>
  (projectSlug && state.fileTree.selectedNodes[projectSlug]) || {};

export const selectSelectedNodeIds = createAppSelector([selectSelectedNodes], selectedNodes =>
  Object.keys(selectedNodes).filter(id => selectedNodes[id]),
);

export const isNodeSelected =
  (projectSlug?: string, nodeId?: TreeFileSystemNode['id']) =>
  (state: RootState): boolean =>
    (projectSlug && nodeId && state.fileTree.selectedNodes[projectSlug]?.[nodeId]) || false;

export { setFileTree, toggleSelectedNode, clearSelectedNodes, removeSelectedNodeIds };
export default fileTreeSlice;
