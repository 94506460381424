import React, { FC, ReactNode, useRef } from 'react';
import { AutocompleteProps, Box, FilterOptionsState, InputAdornment, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import Icon from '@/components/Icon/Icon';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Checklist, Tag, TagType } from '@/api/generated';

type Option = Tag | Checklist | string;

interface CommonAutocompleteProps<
  Value = Tag | Checklist | string,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = true,
  FreeSolo extends boolean | undefined = true,
> extends Omit<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'popupIcon'> {
  placeholder: string;
  handleUpdate: (item: Tag | Checklist) => Promise<void>;
  renderInput: ReactNode;
}

const filter = createFilterOptions<Tag | Checklist>();

const CommonAutocomplete: FC<CommonAutocompleteProps> = ({ placeholder, handleUpdate, renderInput, ...rest }) => {
  const { palette, spacing, typography } = useTheme();
  const ref = useRef<HTMLInputElement | null>(null);

  const onFilterOption = (options: Option[], params: FilterOptionsState<Option>) => {
    const filtered = filter(
      options.filter((opt): opt is Tag | Checklist => typeof opt !== 'string'),
      params,
    );
    const { inputValue } = params;

    if (inputValue !== '' && !filtered.some(option => option.name === inputValue)) {
      filtered.push({ name: inputValue, type: TagType.query } as Tag | Checklist);
    }

    return filtered;
  };

  const optionNormalization = (option: Option): Tag | Checklist => (typeof option === 'string' ? { name: option } : option);

  const onChange = (option: Option) => handleUpdate(optionNormalization(option));

  const getOptionLabel = (option: Option) => (typeof option === 'string' ? option : option.name);

  return (
    <Autocomplete
      sx={{
        '& .MuiFormControl-root': { backgroundColor: palette.background.default, zIndex: 99999 },
        '& .MuiOutlinedInput-root': { p: spacing(0, 2), backgroundColor: 'transparent' },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0 !important',
          borderBottom: `1px solid ${palette.grey[200]} !important`,
        },
      }}
      ListboxProps={{
        sx: {
          maxHeight: `calc(340px - 16px)`,
          py: 0,
          '& :not(:last-child)': { borderBottom: `1px solid ${palette.grey[200]}` },
        },
      }}
      disablePortal={true}
      disableClearable
      onChange={(_, option) => onChange(option)}
      filterOptions={onFilterOption}
      clearOnBlur
      handleHomeEndKeys
      getOptionLabel={getOptionLabel}
      {...rest}
      renderOption={(props, option) => {
        const normalizedOption = typeof option === 'string' ? { name: option } : option;
        return (
          <Box
            component="li"
            sx={{
              fontSize: 'body3.fontSize',
              '&.MuiAutocomplete-option': {
                pl: 2,
                pr: 2.5,
                py: 1,
                color: palette.grey[800],
                '&:hover': {
                  color: palette.controls.selected,
                  fontWeight: 'fontWeightBold',
                  backgroundColor: palette.primary.main,
                },
              },
            }}
            {...props}
          >
            {normalizedOption.name}
          </Box>
        );
      }}
      freeSolo
      renderInput={params => (
        <>
          {renderInput}
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment sx={{ color: palette.grey[700] }} position="start">
                  <Icon name="search" fontSize="large" />
                </InputAdornment>
              ),
            }}
            placeholder={placeholder}
            inputRef={ref}
            inputProps={{
              ...params.inputProps,

              sx: {
                '&.MuiAutocomplete-input': { padding: `${spacing(0.5, 0, 1)} !important` },
                '&::placeholder': {
                  fontSize: 'body3.fontSize',
                  lineHeight: `${typography.body3.fontSize} !important`,
                  color: palette.grey[800],
                  opacity: 1,
                },
              },
            }}
          />
        </>
      )}
    />
  );
};

export default CommonAutocomplete;
