import { Box, IconButton, Input, InputAdornment, useTheme } from '@mui/material';
import { FC, ReactNode, useRef, useState } from 'react';
import Icon from '@/components/Icon/Icon';
import { useTranslation } from 'react-i18next';

export type SidebarSearchProps = {
  searchText: string;
  onSearchTextChange: (searchText: string) => void;
  onBackClick?: () => void;
  action?: ReactNode;
};

const SidebarSearch: FC<SidebarSearchProps> = ({ searchText, onSearchTextChange, onBackClick, action }) => {
  const { t } = useTranslation('common');
  const { palette, spacing } = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const isSearchActive = isSearchOpened || searchText;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: spacing(1.75, 1.5, 1.75, 2),
        borderBottom: `1px solid ${palette.custom.separationLine}`,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', height: 24 }}>
        {!isSearchActive && onBackClick && (
          <IconButton size="small" onClick={() => onBackClick()} disableRipple sx={{ mr: 1, p: 0 }}>
            <Icon name="doubleArrowLeft" />
          </IconButton>
        )}
        {isSearchActive ? (
          <Input
            inputRef={inputRef}
            placeholder={t('placeholders.searchPlaceholder')}
            autoFocus
            disableUnderline
            value={searchText}
            sx={{ width: '100%', input: { p: 0 } }}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ height: 20 }} onClick={() => inputRef.current?.focus()}>
                  <Icon name="search" />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => onSearchTextChange('')}>
                  <Icon name="x" fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
            onBlur={() => setIsSearchOpened(false)}
            onChange={event => onSearchTextChange(event.target.value)}
          />
        ) : (
          <IconButton size="small" sx={{ p: 0 }} onClick={() => setIsSearchOpened(true)}>
            <Icon name="search" />
          </IconButton>
        )}
      </Box>

      {!isSearchActive && action}
    </Box>
  );
};

export default SidebarSearch;
