import { Match, PostProjectsIdQueriesNewQuestionParams, Query, QuestionReceive } from '@/api/generated';
import { createContext } from 'react';

export type QueryQuestion = string;
export type QueryId = NonNullable<Query['_id']>;
export type SelectedQueryIds = { queryId: string; matchId?: string };

export type QueriesContextValue = {
  isLoading: boolean;
  isLoadingChecklist: boolean;
  queries: Query[];
  selectedQuery: Query | undefined;
  selectedMatch: Match | undefined;
  isMatchesLoading: boolean;
  selectedQueryIds?: SelectedQueryIds | null;
  setSelectedQueryIds: (ids: SelectedQueryIds | undefined) => void;
  toggleSelectedQueryIds: (id: QueryId) => void;
  createQuery: (data: QuestionReceive, params?: PostProjectsIdQueriesNewQuestionParams) => Promise<Query>;
  deleteQueries: (queryIds: QueryId[]) => Promise<unknown>;
  fetchChecklists: (ids?: string[]) => Promise<void>;
};

export const QueriesContext = createContext<QueriesContextValue | null>(null);
