import { Instance, ViewState } from '@nutrient-sdk/viewer';
import { useCallback } from 'react';

export const useSubscribeToViewStateChange = (instance?: Instance) =>
  useCallback(
    (handler: (viewState: ViewState, previousViewState?: ViewState) => void) => {
      if (!instance) return () => {};

      handler(instance.viewState);
      instance.addEventListener('viewState.change', handler);

      return () => {
        instance.removeEventListener('viewState.change', handler);
      };
    },
    [instance],
  );
