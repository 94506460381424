import { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';

interface CloudFileViewProps {
  type: 'folder' | 'file';
  name: string;
  onClick: () => void;
}

const CloudFileView: FC<CloudFileViewProps> = ({ type, name, onClick }) => {
  const { palette } = useTheme();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }} onClick={onClick}>
      <Icon name={type} htmlColor={palette.primary.contrastText} />
      <Box sx={{ fontSize: 'body2.fontSize' }}>{name}</Box>
    </Box>
  );
};

export default CloudFileView;
