import { IconButton, Menu, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Checklist,
  getGetOrganizationChecklistsOrgIdQueryKey,
  QueryQuestion,
  useCreateOrganizationChecklist,
  updateOrganizationChecklists,
} from '@/api/generated';
import { useQueryClient } from '@tanstack/react-query';
import { useOrganization } from '@/hooks/useOrganization';
import React, { FC, useEffect, useRef, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useAuth } from '@/hooks/useAuth';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import Icon from '@/components/Icon/Icon';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import CommonAutocomplete from '@/views/Project/views/Queries/components/CommonAutocomplete';

interface ChecklistAutocompleteProps {
  checklists?: Checklist[];
  onlyDuplicate?: boolean;
  selectedQuestions?: QueryQuestion[];
  setCurrentChecklistId: (currentChecklistId?: string) => void;
  currentChecklist?: Checklist;
  selectedIds: Set<string>;
}

const ChecklistAutocomplete: FC<ChecklistAutocompleteProps> = ({
  checklists,
  onlyDuplicate,
  selectedQuestions,
  setCurrentChecklistId,
  currentChecklist,
  selectedIds,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation('project');
  const queryClient = useQueryClient();
  const { organization } = useOrganization();
  const { currentUser } = useAuth();
  const [selectedChecklistId, setSelectedChecklistId] = useState<string>();
  const { mutateAsync: createChecklist, isPending: isChecklistCreating } = useCreateOrganizationChecklist();
  const popupState = usePopupState({ variant: 'popover', popupId: 'project-queries-addOrSearchChecklist' });
  const ref = useRef<HTMLInputElement | null>(null);
  const { showConfirmDialog } = useConfirmDialog();

  const onOpen = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    popupState.toggle();
    popupState.setAnchorEl(event.currentTarget);
    ref.current?.focus();
  };

  useEffect(() => {
    if (!checklists?.length) return;
    !selectedChecklistId && setSelectedChecklistId(checklists[0]._id);
  }, [selectedChecklistId, checklists]);

  const userChecklists = checklists?.filter(checklist => checklist.createdBy === currentUser._id);

  const updateOrCreateChecklist = async (selectedChangedChecklist: Checklist) => {
    try {
      const updatedSelectedChecklist = selectedChangedChecklist._id
        ? await updateOrganizationChecklists(organization.id, selectedChangedChecklist._id, {
            ...selectedChangedChecklist,
            queries: [...(selectedChangedChecklist.queries || []), ...(selectedQuestions || [])],
          })
        : await createChecklist({
            orgId: organization.id,
            data: { name: selectedChangedChecklist.name, queries: selectedQuestions },
          });

      queryClient.setQueryData(
        getGetOrganizationChecklistsOrgIdQueryKey(organization.id),
        selectedChangedChecklist._id
          ? checklists?.map(checklist => (checklist._id === updatedSelectedChecklist?._id ? updatedSelectedChecklist : checklist))
          : [...(checklists ?? []), updatedSelectedChecklist],
      );
      setCurrentChecklistId(updatedSelectedChecklist._id);
      enqueueSnackbar(
        t('queries.checklistsEditDialog.toasts.transferQuestions.success', { checklistName: updatedSelectedChecklist.name }),
        { autoHideDuration: 2000 },
      );
    } catch (error) {
      console.error(`Error while saving checklist, ${selectedChangedChecklist._id}`, error);
      enqueueSnackbar(t('queries.checklistsEditDialog.toasts.transferQuestions.failed'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const onChecklistChange = async (selectedChangedChecklist: Checklist) => {
    const result =
      onlyDuplicate ||
      (await showConfirmDialog(
        {
          title: t('queries.checklistsEditDialog.checklistAutocomplete.dialog.title'),
          description: t('queries.checklistsEditDialog.checklistAutocomplete.dialog.description'),
          buttons: [
            { label: t('queries.checklistsEditDialog.checklistAutocomplete.dialog.duplicate'), value: 'duplicate' },
            { label: t('queries.checklistsEditDialog.checklistAutocomplete.dialog.delete'), value: 'delete' },
          ],
        },
        { hideCancel: true },
      ));

    if (!result) return;

    if (result === 'delete') {
      if (!currentChecklist) return;
      try {
        const updatedQueries = currentChecklist.queries?.filter(query => !selectedIds.has(query?._id ?? ''));
        const updatedCurrentChecklist = await updateOrganizationChecklists(organization.id, currentChecklist._id ?? '', {
          ...currentChecklist,
          queries: updatedQueries ?? [],
        });

        queryClient.setQueryData(
          getGetOrganizationChecklistsOrgIdQueryKey(organization.id),

          checklists?.map(checklist => (checklist._id === updatedCurrentChecklist?._id ? updatedCurrentChecklist : checklist)),
        );
      } catch (error) {
        console.error(`Error while saving checklist, ${selectedChangedChecklist._id}`, error);
        enqueueSnackbar(t('queries.checklistsEditDialog.toasts.transferQuestions.failed'), {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        await updateOrCreateChecklist(selectedChangedChecklist);
      }
    } else {
      await updateOrCreateChecklist(selectedChangedChecklist);
    }
  };

  return (
    <>
      <Tooltip arrow title={t('queries.tooltip.transferQuestion')} disableInteractive>
        <IconButton disableRipple {...bindTrigger(popupState)} size="small" onClick={event => onOpen(event)}>
          <Icon name="moveTo" fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={popupState.anchorEl}
        sx={{
          '.MuiList-root': { p: 0 },
          '.MuiMenuItem-root': {
            py: 2,
            '&:not(:last-child)': {
              borderBottom: `1px solid ${palette.custom.separationLine}`,
            },
          },
        }}
        slotProps={{
          paper: {
            sx: {
              overflow: 'hidden',
              backgroundColor: palette.background.default,
              width: 300,
              maxHeight: 400,
              height: '100%',
              boxShadow: 1,
              borderRadius: 2,
              mt: 1,
              border: `1px solid ${palette.grey[200]}`,
            },
          },
        }}
      >
        <CommonAutocomplete
          componentsProps={{
            popper: { sx: { height: 'auto', overflow: 'hidden', transform: `translate(0, 66px) !important` } },
            paper: {
              sx: { background: 'transparent', boxShadow: 'none', maxHeight: '340px', borderRadius: 'unset', overflow: 'hidden' },
            },
          }}
          open={popupState.isOpen}
          renderInput={
            <Typography sx={{ color: palette.grey[400], fontSize: 'body3.fontSize', px: 2, py: 1 }}>
              {t('queries.checklistsEditDialog.checklistAutocomplete.title')}
            </Typography>
          }
          handleUpdate={onChecklistChange}
          placeholder={t('queries.checklistsEditDialog.checklistAutocomplete.placeholder')}
          options={userChecklists || []}
          loading={isChecklistCreating}
          disabled={isChecklistCreating}
        />
      </Menu>
    </>
  );
};

export default ChecklistAutocomplete;
