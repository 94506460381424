import { FC } from 'react';
import { ButtonBase, ButtonBaseProps, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GradiantPlusIcon from '@/assets/plus-circle.svg?react';
import { useProjectFormDialog } from '@/hooks/useProjectFormDialog';
import { CARD_HEIGHT, CARD_WIDTH } from '@/views/Projects/Documentations/constants';

interface CreateNewProjectButtonProps extends ButtonBaseProps {}

const CreateNewProjectButton: FC<CreateNewProjectButtonProps> = props => {
  const { palette } = useTheme();
  const { t } = useTranslation('projects');
  const { showProjectFormDialog } = useProjectFormDialog();

  return (
    <ButtonBase
      onClick={() => showProjectFormDialog()}
      disableRipple
      sx={{
        display: 'inline-flex !important',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 1,
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        p: 0,
        fontSize: 'body2.fontSize',
        color: palette.text.primary,
        boxShadow: 2,
        borderRadius: 2,
        textTransform: 'none',
      }}
      {...props}
    >
      <Stack alignItems="center" gap={1.25}>
        <GradiantPlusIcon style={{ width: 60, height: 60 }} />
        <Typography
          sx={{
            backgroundImage: palette.gradient.pelles,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            fontSize: 'body4.fontSize',
          }}
        >
          {t('documentations.recentlyViewed.newProject')}
        </Typography>
      </Stack>
    </ButtonBase>
  );
};

export default CreateNewProjectButton;
