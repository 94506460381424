import { AnnotationsUnion, IInteractionMode, Instance, List } from '@nutrient-sdk/viewer';
import { useEffect, useRef } from 'react';
import { Key } from 'ts-key-enum';
import { CountingMode } from '@/containers/DocumentEditor/types';

type Params = {
  instance?: Instance;
  editMode: CountingMode;
};

export const useKeepSelectingToolForPerimeterAndPolygon = ({ instance, editMode }: Params) => {
  const createdMeasurementTypeRef = useRef<IInteractionMode | null>(null);
  const presetRef = useRef<string | null>(null);

  useEffect(() => {
    presetRef.current = null;
    createdMeasurementTypeRef.current = null;
  }, [editMode]);

  useEffect(() => {
    if (!instance) return;

    const pageElement = instance.contentDocument.querySelector<HTMLDivElement>('.PSPDFKit-Page');

    const handleAnnotationCreate = (createdAnnotations: List<AnnotationsUnion>) => {
      const annotation = createdAnnotations.get(0);
      if (
        annotation?.isMeasurement?.() &&
        instance.viewState.interactionMode &&
        ['PERIMETER', 'POLYGON_AREA'].includes(instance.viewState.interactionMode)
      ) {
        presetRef.current = instance.currentAnnotationPreset ?? null;
        createdMeasurementTypeRef.current = instance.viewState.interactionMode;
      }
    };

    const handleSelectionChange = () => {
      if (!presetRef.current || !createdMeasurementTypeRef.current) return;
      const newPreset = presetRef.current;
      const newInteractionMode = createdMeasurementTypeRef.current;
      setTimeout(() => {
        presetRef.current = null;
        createdMeasurementTypeRef.current = null;
        instance.setCurrentAnnotationPreset(newPreset);
        instance.setViewState(currentViewState => currentViewState.set('interactionMode', newInteractionMode));
      }, 100);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code !== Key.Escape) return;
      presetRef.current = null;
      createdMeasurementTypeRef.current = null;
    };

    instance.addEventListener('annotations.create', handleAnnotationCreate);
    instance.addEventListener('annotationSelection.change', handleSelectionChange);
    pageElement?.addEventListener('keydown', handleKeyDown);
  }, [instance]);
};
