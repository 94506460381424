import { Components, Theme } from '@mui/material/styles';
import typography from '@/theme/typography';

const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '&.MuiChip-sizeSmall': {
        fontSize: theme.typography.body3.fontSize,
      },
      '&.MuiChip-filledWarning, &.MuiChip-filledPrimary, &.MuiChip-filledSecondary': {
        borderRadius: 8,
        padding: theme.spacing(0.5, 1.5),
        minWidth: 50,
        textTransform: 'capitalize',
        color: theme.palette.text.primary,
        lineHeight: 1.2,
      },
      '&.MuiChip-filledDefault': {
        backgroundColor: theme.palette.background.default,
        borderRadius: 40,
        padding: theme.spacing(0.5, 1.5),
        gap: 2,
        minWidth: 70,
      },
      '&.MuiChip-filledDefault, &.MuiChip-filledWarning, &.MuiChip-filledPrimary, &.MuiChip-filledSecondary': {
        height: 'auto',
        '& .MuiChip-icon': {
          marginLeft: 0,
          marginRight: 0,
          color: 'inherit',
        },
        '& .MuiChip-label': {
          fontSize: typography.body3.fontSize,
          fontWeight: typography.body3.fontWeight,
          padding: 0,
        },
      },
    }),
  },
};

export default MuiChip;
