import { useCheckIfAllDocumentsAreVectorized } from '@/api/generated';
import { useQueries } from './useQueries';
import { useProject } from './useProject';
import { QueriesActions } from '../views/Queries/enums';
import { usePopupDocumentsInProgress } from '@/views/Project/components/PopupDocumentsInProgress/usePopupDocumentsInProgress.hook';

export const useCreateQuestionsByChecklists = () => {
  const { fetchChecklists, setSelectedQueryIds } = useQueries();
  const { projectSlug } = useProject();
  const { refetch: refetchDocumentsStatus } = useCheckIfAllDocumentsAreVectorized(projectSlug);
  const { showPopupDocumentsInProgress } = usePopupDocumentsInProgress();

  const createQuestionsByChecklists = async (checklistIds?: string[]) => {
    if (!checklistIds || !checklistIds.length) return;

    const { data: { isReady = true } = {} } = await refetchDocumentsStatus();

    if (!isReady) {
      const isProceed = await showPopupDocumentsInProgress({
        action: QueriesActions.createQuery,
        onProceed: () => fetchChecklists(checklistIds),
      });
      return isProceed;
    }

    await fetchChecklists(checklistIds);

    setSelectedQueryIds(undefined);

    return true;
  };

  return {
    createQuestionsByChecklists,
  };
};
