import { DragEvent, FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createRangeSelection,
  $getNearestNodeFromDOMNode,
  $setSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  DRAGOVER_COMMAND,
  DROP_COMMAND,
  isHTMLElement,
} from 'lexical';
import { calculateZoomLevel, mergeRegister } from '@lexical/utils';
import { getBlockElement } from '@/containers/PagesEditor/utils/getBlockElem';
import { hideTargetLine, setTargetHorizontalLine, setTargetLine } from '@/containers/PagesEditor/utils/targetline';
import { INLINE_DRAG_DATA_FORMAT, DRAG_DATA_FORMAT } from '@/containers/PagesEditor/constants';
import { DraggableBlockData } from '@/containers/PagesEditor/types';
import { $createBlockAfterDrop } from '@/containers/PagesEditor/utils/$createBlockAfterDrop';
import DraggableTargetLine from '@/containers/PagesEditor/components/DraggableTargetLine';
import DraggableHorizontalTargetLine from '@/containers/PagesEditor/components/DraggableHorizontalTargetLine';
import caretFromPoint from 'shared/caretFromPoint';

interface DropBlocksPluginProps {
  anchorElem: HTMLDivElement;
}

const DropBlocksPlugin: FC<DropBlocksPluginProps> = ({ anchorElem }) => {
  const [editor] = useLexicalComposerContext();
  const targetLineRef = useRef<HTMLDivElement | null>(null);
  const targetHorizontalLineRef = useRef<HTMLDivElement | null>(null);
  const targetLineComponent = <DraggableTargetLine ref={targetLineRef} />;
  const targetHorizontalLineComponent = <DraggableHorizontalTargetLine ref={targetHorizontalLineRef} />;

  useEffect(() => {
    const isDraggableBlock = (event: DragEvent) =>
      event.dataTransfer.types.includes(DRAG_DATA_FORMAT) || event.dataTransfer.types.includes(INLINE_DRAG_DATA_FORMAT);

    const isInlineDraggableBlock = (event: DragEvent) => event.dataTransfer.types.includes(INLINE_DRAG_DATA_FORMAT);

    const parseDragData = (event: DragEvent) => {
      if (!isDraggableBlock(event)) return null;

      try {
        const format = isInlineDraggableBlock(event) ? INLINE_DRAG_DATA_FORMAT : DRAG_DATA_FORMAT;
        return JSON.parse(event.dataTransfer.getData(format)) as DraggableBlockData;
      } catch {
        return null;
      }
    };

    const onDragover = (event: DragEvent<HTMLDivElement>) => {
      if (!isDraggableBlock(event)) return false;

      const { pageX, pageY, target } = event;
      if (!isHTMLElement(target)) return false;

      if (isInlineDraggableBlock(event)) {
        const targetLineElem = targetHorizontalLineRef.current;
        if (!targetLineElem) return false;

        setTargetHorizontalLine({
          targetLineElem,
          anchorElem,
          mouseX: pageX / calculateZoomLevel(target),
          mouseY: pageY / calculateZoomLevel(target),
        });
      } else {
        const targetBlockElem = getBlockElement(anchorElem, editor, event, true);
        const targetLineElem = targetLineRef.current;
        if (targetBlockElem === null || targetLineElem === null) return false;

        setTargetLine({ targetLineElem, targetBlockElem, anchorElem, mouseY: pageY / calculateZoomLevel(target) });
      }

      // Prevent default event to be able to trigger onDrop events
      event.preventDefault();
      return true;
    };

    const $onDrop = (event: DragEvent<HTMLDivElement>) => {
      hideTargetLine(targetLineRef.current);
      hideTargetLine(targetHorizontalLineRef.current);
      if (!isDraggableBlock(event)) return false;

      const transferData = parseDragData(event);
      if (!transferData) return false;

      const { target, pageX, pageY } = event;
      if (!isHTMLElement(target)) return false;

      if (isInlineDraggableBlock(event)) {
        const caret = caretFromPoint(pageX / calculateZoomLevel(target), pageY / calculateZoomLevel(target));
        if (!caret) return false;

        const nodesToInsert = $createBlockAfterDrop(transferData.type, transferData.settings);
        if (!nodesToInsert) return false;

        const range = new Range();
        range.setStart(caret.node, caret.offset);
        range.setEnd(caret.node, caret.offset);

        const $selection = $createRangeSelection();
        $selection.applyDOMRange(range);
        $setSelection($selection);
        $selection.insertNodes(nodesToInsert);
        $setSelection(null);
      } else {
        const targetBlockElem = getBlockElement(anchorElem, editor, event, true);
        if (!targetBlockElem) return false;

        const targetNode = $getNearestNodeFromDOMNode(targetBlockElem);
        if (!targetNode) return false;

        const nodesToInsert = $createBlockAfterDrop(transferData.type, transferData.settings);
        if (!nodesToInsert) return false;

        nodesToInsert.reverse().forEach(nodeToInsert => {
          const targetBlockElemTop = targetBlockElem.getBoundingClientRect().top;
          if (pageY / calculateZoomLevel(target as Element) >= targetBlockElemTop) {
            targetNode.insertAfter(nodeToInsert);
          } else {
            targetNode.insertBefore(nodeToInsert);
          }
        });
      }

      return true;
    };

    const unsubscribe = mergeRegister(
      editor.registerCommand(
        DRAGOVER_COMMAND,
        event => onDragover(event as unknown as DragEvent<HTMLDivElement>),
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        DROP_COMMAND,
        event => $onDrop(event as unknown as DragEvent<HTMLDivElement>),
        COMMAND_PRIORITY_HIGH,
      ),
    );

    const onDragLeave = () => {
      hideTargetLine(targetLineRef.current);
      hideTargetLine(targetHorizontalLineRef.current);
    };
    editor.getRootElement()?.addEventListener('mouseleave', onDragLeave);

    return () => {
      unsubscribe();
      editor.getRootElement()?.addEventListener('dragleave', onDragLeave);
    };
  }, [anchorElem, editor, targetLineRef]);

  return createPortal(
    <>
      {targetHorizontalLineComponent}
      {targetLineComponent}
    </>,
    anchorElem,
  );
};

export default DropBlocksPlugin;
