import { createContext, ReactNode, useContext, useMemo, FC } from 'react';

export type ProcessStatus = 'edit' | 'in-progress' | 'ready';

type ContextValue = {
  processStatus: ProcessStatus;
};

interface TemplateContextProviderProps {
  processStatus: ProcessStatus;
  children: ReactNode;
}

const TemplateContext = createContext<ContextValue>({ processStatus: 'in-progress' });

export const TemplateContextProvider: FC<TemplateContextProviderProps> = ({ processStatus, children }) => {
  const contextValue = useMemo(() => ({ processStatus }), [processStatus]);
  return <TemplateContext.Provider value={contextValue}>{children}</TemplateContext.Provider>;
};

export const useTemplateContext = () => useContext(TemplateContext);
