import { useRef } from 'react';
import uniq from 'lodash/uniq';
import { getMicrosoftToken, Site } from '@/api/generated';
import { acceptedFileTypes } from '@/utils/consts';
import { CloudFilesDialogErrorCallback } from '@/contexts/CloudFilesDialogContext';
import { SherPointFile } from '@/types';

type Params = {
  onError: CloudFilesDialogErrorCallback;
  onFilesSelect: (files: SherPointFile[]) => void;
};

type PickerParams = {
  site?: Site;
};

const oneDriveUrl = 'https://onedrive.live.com/picker';

export const useMicrosoftPicker = ({ onFilesSelect, onError }: Params) => {
  const pickerRef = useRef<Window | null>(null);

  const openPicker = async (type: 'sharepoint' | 'onedrive', { site }: PickerParams = {}) => {
    try {
      const accessToken = await getMicrosoftToken();
      const channelId = crypto.randomUUID();
      pickerRef.current = window.open('', 'Picker', 'width=1080,height=680');

      const options = {
        sdk: '8.0',
        entry: type === 'sharepoint' ? { sharePoint: {} } : { oneDrive: {} },
        authentication: {
          enabled: false,
        },
        messaging: {
          origin: window.location.origin,
          channelId,
        },
        selection: {
          mode: 'multiple',
        },
        typesAndSources: {
          filters: uniq(Object.values(acceptedFileTypes).flat()),
        },
        commands: {
          pick: {
            action: 'select',
            select: {
              urls: { download: true },
            },
          },
        },
      };
      const queryString = new URLSearchParams({
        id: 'root',
        filePicker: JSON.stringify(options),
        locale: 'en-us',
      });
      const url = type === 'sharepoint' ? `${site?.web_url}/_layouts/15/FilePicker.aspx` : oneDriveUrl;
      const pickerOrigin = new URL(url).origin;

      const form = pickerRef.current!.document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', `${url}?${queryString}`);
      pickerRef.current!.document.body.append(form);

      const tokenInput = pickerRef.current!.document.createElement('input');
      tokenInput.setAttribute('type', 'hidden');
      tokenInput.setAttribute('name', 'access_token');
      tokenInput.setAttribute('value', accessToken as string);
      form.appendChild(tokenInput);
      form.submit();

      let port: MessagePort;

      const closePicker = () => pickerRef.current?.close();

      const messageListener = async (event: MessageEvent) => {
        const message = event.data;

        if (message.type !== 'command') return;

        port.postMessage({
          type: 'acknowledge',
          id: message.id,
        });

        const commandMessage = message.data;

        if (commandMessage.command === 'authenticate') {
          port.postMessage({
            type: 'result',
            id: message.id,
            data: { result: 'token' },
          });
          return;
        }

        if (commandMessage.command === 'close') {
          port.removeEventListener('message', messageListener);
          closePicker();
          return;
        }

        if (commandMessage.command === 'pick') {
          onFilesSelect(commandMessage.items);
          port.removeEventListener('message', messageListener);
          closePicker();
          return;
        }

        port.postMessage({
          type: 'result',
          id: message.id,
          data: {
            result: 'error',
            error: {
              code: 'unsupportedCommand',
            },
          },
        });
      };

      const initialListener = (event: MessageEvent) => {
        if (event.origin !== pickerOrigin) {
          return;
        }

        const message = event.data;
        if (message.type !== 'initialize' || message.channelId !== channelId) return;

        port = event.ports[0];
        port.postMessage({ type: 'activate' });
        port.addEventListener('message', messageListener);
        port.start();
        window.removeEventListener('message', initialListener);
      };

      window.addEventListener('message', initialListener);
    } catch (error) {
      onError({ error, type });
    }
  };

  return {
    openPicker,
    pickerRef,
  };
};
