import { ReactNode } from 'react';
import { Box, IconButton, SxProps, Typography, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import TextInput, { TextInputProps } from '@/components/TextInput/TextInput';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';

interface TextInputWithEditButtonProps<T extends FieldValues = FieldValues> extends TextInputProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
  isOpened: boolean;
  isEditable?: boolean;
  onOpenClick: () => void;
  containerSx?: SxProps;
}

const TextInputWithEditButton: <T extends FieldValues>(props: TextInputWithEditButtonProps<T>) => ReactNode = ({
  isOpened,
  isEditable = true,
  control,
  onOpenClick,
  name,
  labelSx,
  containerSx,
  ...props
}) => {
  const { palette } = useTheme();
  const { field } = useController({ control, name });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...containerSx }}>
      {isOpened ? (
        <TextInput autoFocus labelSx={{ fontWeight: 700, ...labelSx }} control={control} name={name} {...props} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: 'auto',
            overflow: 'hidden',
            '&:hover .TextInputWithEditButton__edit': { opacity: 1 },
          }}
        >
          <Typography sx={{ fontSize: 'h2.fontSize', fontWeight: 700, color: palette.controls.label }}>{field.value}</Typography>
          {isEditable && (
            <IconButton onClick={() => onOpenClick()} className="TextInputWithEditButton__edit" sx={{ opacity: 0 }}>
              <Icon fontSize="small" name="editWithUnderline" sx={{ width: 20, height: 20 }} />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TextInputWithEditButton;
