import { FC } from 'react';
import Icon, { IconNames, IconProps } from '@/components/Icon/Icon';
import { useTheme } from '@mui/material';
import { SORT_DIRECTION } from '@/enums/sort';

interface SortingArrowsProps extends Omit<IconProps, 'name'> {
  order: SORT_DIRECTION;
}

const SortingArrows: FC<SortingArrowsProps> = ({ order, ...props }) => {
  const { palette } = useTheme();
  const ICON_NAME_MAP: Record<SORT_DIRECTION, IconNames> = {
    [SORT_DIRECTION.DESC]: 'sortDescending',
    [SORT_DIRECTION.ASC]: 'sortAscending',
    [SORT_DIRECTION.NONE]: 'sortNone',
  };

  return <Icon name={ICON_NAME_MAP[order]} htmlColor={palette.primary.dark} {...props} />;
};

export default SortingArrows;
