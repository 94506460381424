import { ViewState, IViewState } from '@nutrient-sdk/viewer';
import { useEffect, useState } from 'react';
import { useSubscribeToViewStateChange } from '@/containers/DocumentEditor/internalHooks/useSubscribeToViewStateChange';

type Params = {
  isEnabled: boolean;
  subscribeToViewStateChange: ReturnType<typeof useSubscribeToViewStateChange>;
};

export const useInstanceViewState = <T extends keyof IViewState>(
  watchProps: T[],
  { isEnabled, subscribeToViewStateChange }: Params,
) => {
  const [props, setProps] = useState<Pick<IViewState, T>>();

  useEffect(
    () =>
      subscribeToViewStateChange((viewState: ViewState, prevViewState?: ViewState) => {
        const nextProps = watchProps.reduce<Pick<IViewState, T>>(
          (acc, prop) =>
            !props || !prevViewState || viewState[prop] !== prevViewState[prop] ? { ...acc, [prop]: viewState[prop] } : acc,
          props ?? ({} as IViewState),
        );

        setProps(prevProps => (prevProps === nextProps ? prevProps : nextProps));
      }),
    [isEnabled, subscribeToViewStateChange],
  );

  return props ?? ({} as IViewState);
};
