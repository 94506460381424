import { AnnotationBackendJSON } from '@nutrient-sdk/viewer';

export const removeClientDataFromAnnotations = (annotations: AnnotationBackendJSON[]) =>
  annotations.map(annotation => {
    if (annotation.customData) {
      delete annotation.customData.skipTimer;
      delete annotation.customData.skipSave;
      delete annotation.customData.skipDelete;
    }
    return annotation;
  });
