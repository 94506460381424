import { TableBody, TableCell, TableHead, TableRow, Table } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentVersionNode } from '@/views/Projects/components/ProjectFormDialog/types';
import { STATUS } from '@/utils/enums';
import { DocumentMetadata } from '@/api/generated';
import { VersionTableRow } from './VersionTableRow';

type VersionsTableProps = {
  onVersionClick: (documentId: string) => void;
  versionsNodes: DocumentVersionNode[];
  document: DocumentMetadata;
  deleteDocumentVersion: (documentVersionId: string) => Promise<void>;
  setAsMainDocumentVersion: (documentVersionId: string) => Promise<void>;
};

const VersionsTable: FC<VersionsTableProps> = props => {
  const { t } = useTranslation('projectUpdate');

  return (
    <Table
      size="small"
      sx={{
        mt: 3.5,
        width: '100%',
        tableLayout: 'fixed',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        borderSpacing: '20px',
        '& .MuiTableCell-root': {
          py: 0,
          px: 0.5,
          fontSize: 'body3.fontSize',
        },
        '& .spacer': {
          height: '.25rem',
        },
      }}
    >
      <TableHead>
        <TableRow
          sx={{
            '& .MuiTableCell-root': {
              color: 'grey.700',
              fontSize: 'body3.fontSize',
              fontWeight: 700,
              borderBottomColor: 'grey.100',
              pb: 0.625,
            },
          }}
        >
          <TableCell sx={{ width: 'auto' }}>{t('uploadFiles.tableFileName')}</TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap', width: '110px' }}>{t('uploadFiles.tableDate')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <VersionTableRow
          key="main"
          isMainVersion
          onClick={props.onVersionClick}
          canBeRemoved={false}
          data={{
            id: props.document._id!,
            lastModified: props.document.last_modified ?? '',
            filename: props.document.filename,
            file: undefined,
            progress: 0,
            status: STATUS.LOADED,
          }}
        />
        {props.versionsNodes
          .filter(version => version.id !== props.document._id)
          .map(versionNode => (
            <VersionTableRow
              key={versionNode.id}
              isDisabled={
                versionNode.status === STATUS.DELETING ||
                versionNode.status === STATUS.LOADING ||
                versionNode.status === STATUS.ERROR
              }
              isMainVersion={versionNode.id === props.document._id}
              onClick={() => versionNode.version && props.onVersionClick(versionNode.version.id)}
              data={versionNode}
              deleteDocumentVersion={props.deleteDocumentVersion}
              setAsMainDocumentVersion={props.setAsMainDocumentVersion}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default VersionsTable;
