import { ElementTransformer } from '@lexical/markdown';
import { LexicalNode } from 'lexical';
import { $createVariableNode, $isVariableNode, VariableNode } from '@/containers/PagesEditor/nodes/VariableNode';

export const VARIABLE_TRANSFORMER: ElementTransformer = {
  dependencies: [VariableNode],
  export: (node: LexicalNode) => {
    if (!$isVariableNode(node)) return null;
    return `<variable id="${node.getUuid()}"></variable>`;
  },
  regExp: /<variable id="(.+?)"><\/variable>/,
  replace: parentNode => {
    parentNode.append($createVariableNode({ type: 'name' }));
  },
  type: 'element',
};
