import Icon, { IconProps } from '@/components/Icon/Icon';
import { FC } from 'react';

const checklistIcons = [
  'drumRide',
  'worker',
  'crane',
  'dirtTruck',
  'cone',
  'west',
  'sledgeHammer',
  'block',
  'wreckingBall',
  'tools',
  'drill',
  'hammer',
  'evoted',
  'fi',
  'screwdriver',
  'miniCrane',
  'screw',
  'pipe',
  'hvac',
  'electricity',
  'weLoveUri',
] as const;

export type ChecklistIconNames = (typeof checklistIcons)[number];

interface ChecklistIconProps extends IconProps {
  name: ChecklistIconNames;
}

export const getRandomChecklistIconName = (): ChecklistIconNames =>
  checklistIcons[Math.floor(Math.random() * checklistIcons.length)];

export const ChecklistIcon: FC<ChecklistIconProps> = ({ name, ...props }) => <Icon name={name} {...props} />;
