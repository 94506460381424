import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import yaml from 'js-yaml';

const instance = i18n.use(HttpBackend).use(LanguageDetector).use(initReactI18next);

if (process.env.NO_HMR || !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  const { HMRPlugin: hmrPlugin } = await import('i18next-hmr/plugin');
  instance.use(new hmrPlugin({ vite: { client: true } }));
}

instance.init<HttpBackendOptions>({
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
  ns: ['common', 'project', 'projectUpdate', 'templates', 'editor', 'customToolbar', 'knowledgeBase', 'lexicalEditor'],
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.yml',
    parse: (data: string) => yaml.load(data) as Record<string, never>,
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br'],
  },
});

export default i18n;
