import { FC } from 'react';
import { Checkbox, CircularProgress, IconButton, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { Checklist } from '@/api/generated';
import SidebarGroup from '@/components/Sidebar/SidebarGroup';
import SidebarGroupItem from '@/components/Sidebar/SidebarGroupItem';

export type ChecklistGroupProps = {
  title: string;
  checklists?: Checklist[];
  currentChecklistId?: string;
  checkedChecklists?: string[];
  withDeleteButton?: boolean;
  onCheckItems?: (items: Array<{ checklistId: string; isChecked: boolean }>) => void;
  onChecklistClick: (id: string) => void;
  onDeleteChecklist: (id?: string) => void;
};

const ChecklistGroup: FC<ChecklistGroupProps> = ({
  title,
  currentChecklistId,
  checklists,
  onDeleteChecklist,
  onCheckItems,
  checkedChecklists,
  withDeleteButton = false,
  onChecklistClick,
}) => {
  const { palette } = useTheme();

  const isAllSelected =
    checklists?.reduce((acc, checklist) => {
      if (checkedChecklists?.includes(checklist._id as string)) {
        return acc + 1;
      }
      return acc;
    }, 0) === checklists?.length && !!checklists?.length;

  const onCheck = (id: string, isChecked: boolean) => {
    onCheckItems?.([{ checklistId: id, isChecked: isChecked }]);
  };

  const onCheckAll = (isChecked: boolean) => {
    if (!checklists) return;
    onCheckItems?.(
      checklists
        .filter(checklist => checklist.is_ready)
        .map(checklist => ({
          checklistId: checklist._id!,
          isChecked: isChecked,
        })),
    );
  };

  const renderLeft = (checklist: Checklist) => {
    if (!checklist.is_ready) return <CircularProgress size={16} sx={{ flexShrink: 0 }} />;

    return (
      <Checkbox
        data-testid="checkbox"
        size="medium"
        disableRipple
        sx={{ p: 0, '&:not(.Mui-checked) .MuiSvgIcon-root': { fill: palette.grey[300] } }}
        checked={!!checkedChecklists?.includes(checklist._id!)}
        onChange={event => onCheck(checklist._id!, event.target.checked)}
      />
    );
  };

  return (
    <SidebarGroup title={title} isChecked={isAllSelected} onCheck={onCheckAll}>
      {checklists?.map(checklist => (
        <SidebarGroupItem
          key={checklist._id}
          sx={{ pl: 3 }}
          title={`${checklist.name} (${(checklist.queries?.length ?? 0).toString()})`}
          isActive={currentChecklistId === checklist._id}
          onClick={() => onChecklistClick(checklist._id!)}
          left={renderLeft(checklist)}
          actions={
            withDeleteButton && (
              <IconButton sx={{ p: 0.5 }} onClick={() => onDeleteChecklist(checklist._id)}>
                <Icon name="bin" fontSize="xsmall" />
              </IconButton>
            )
          }
        />
      ))}
    </SidebarGroup>
  );
};

export default ChecklistGroup;
