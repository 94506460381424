import { FC, MouseEvent, KeyboardEvent, useEffect, useState } from 'react';
import { NodeRendererProps } from 'react-arborist';
import { Box, CircularProgress, IconButton, Input, Stack, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { FiFileText } from 'react-icons/fi';
import { BsThreeDots } from 'react-icons/bs';
import { Key } from 'ts-key-enum';
import { MenuItemProps } from '@/components/MoreActions/MoreActions';
import Actions from '@/views/Projects/components/Actions/Actions';
import { TreePage } from '@/components/Pages/types';
import Icon from '@/components/Icon/Icon';

interface TreePageNodeProps extends NodeRendererProps<TreePage> {
  onNameClick: (page: TreePage) => void;
}

const TreePageNode: FC<TreePageNodeProps> = ({ node, style, dragHandle, tree, onNameClick }) => {
  const { t } = useTranslation('lexicalEditor');
  const { palette } = useTheme();
  const actionsState = usePopupState({
    variant: 'popover',
    popupId: `tree-page-actions-${node.data.item.id}`,
  });
  const [pageName, setPageName] = useState(node.data.item.name);
  const isReady = !!node.data.item.page?.isReady;

  useEffect(() => {
    if (!node.isEditing) return;

    setPageName(node.data.item.name);
  }, [node.isEditing]);

  const onDelete = (event: MouseEvent) => {
    event.stopPropagation();
    tree.delete(node);
  };

  const onEdit = (event: MouseEvent) => {
    event.stopPropagation();
    tree.edit(node);
  };

  const onAdd = (event: MouseEvent) => {
    event.stopPropagation();
    tree.create({ parentId: node.id });
  };

  const onRootClick = (event: MouseEvent) => {
    if (node.data.item.isFolder) {
      event.stopPropagation();
      node.deselect();
    }
  };

  const onOpenClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (node.data.item.isFolder) {
      node.toggle();
      node.deselect();
    } else {
      onNameClick(node.data);
    }
  };

  const onToggleClick = (event: MouseEvent) => {
    event.stopPropagation();
    node.toggle();
  };

  const save = () => pageName.trim() && node.submit(pageName);

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.code == Key.Enter) save();
  };

  const onBlur = () => {
    save();
  };

  const menuItems: MenuItemProps[] = [
    { id: 'actions.delete', children: t('actions.delete'), onClick: onDelete },
    { id: 'actions.edit', children: t('actions.rename'), onClick: onEdit },
  ].filter(item => !!item);

  const elementBgColor = node.willReceiveDrop
    ? palette.grey[50]
    : node.isSelected || node.isFocused
      ? palette.grey[200]
      : 'inherit';

  const renderLeft = () => {
    if (!isReady) return <CircularProgress size={16} sx={{ flexShrink: 0 }} />;

    if (node.data.item.isFolder || node.children?.length) {
      return (
        <IconButton size="small" sx={{ flexShrink: 0, p: 0.3 }} onClick={onToggleClick}>
          <Icon
            name="arrowRight"
            fontSize="small"
            htmlColor={palette.primary.dark}
            sx={{
              flexShrink: 0,
              transform: `rotate(${node.isOpen ? 90 : 0}deg)`,
              transition: 'transform .1s ease-in-out',
            }}
          />
        </IconButton>
      );
    }

    return <FiFileText style={{ flexShrink: 0 }} color={palette.primary.dark} onClick={onOpenClick} />;
  };

  return (
    <Stack
      ref={dragHandle}
      style={style}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        height: '100%',
        borderTop: node.isDragging ? '1px dashed' : 'none',
        borderBottom: node.isDragging ? '1px dashed' : 'none',
        backgroundColor: elementBgColor,
        '&:hover': {
          backgroundColor: node.isSelected || node.isFocused ? '' : palette.grey[50],
          '.TreePageNode__actions': { opacity: 1 },
        },
      }}
      onClick={onRootClick}
    >
      <Stack direction="row" alignItems="center" gap={1} sx={{ minWidth: 0, pl: 2, cursor: 'pointer' }}>
        {renderLeft()}

        {node.isEditing ? (
          <Input
            autoFocus
            value={pageName}
            onChange={event => setPageName(event.target.value)}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          />
        ) : (
          <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={onOpenClick}>
            {node.data.item.name}
          </Box>
        )}
      </Stack>

      <Stack
        className="TreePageNode__actions"
        direction="row"
        alignItems="center"
        sx={{ opacity: actionsState.isOpen ? 1 : 0, pr: 2 }}
      >
        {isReady ? (
          <>
            <Actions
              sx={{ '&:hover': { backgroundColor: palette.primary.main } }}
              icon={<BsThreeDots color={palette.primary.dark} size={16} />}
              menuItems={menuItems}
              actionsState={actionsState}
            />

            <Tooltip arrow disableInteractive title={t('drawer.addPage')}>
              <IconButton size="small" sx={{ '&:hover': { backgroundColor: palette.primary.main } }} onClick={onAdd}>
                <Icon name="plus" fontSize="small" htmlColor={palette.primary.dark} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <IconButton size="small" sx={{ '&:hover': { backgroundColor: palette.primary.main } }} onClick={onDelete}>
            <Icon name="bin" fontSize="small" htmlColor={palette.primary.dark} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default TreePageNode;
