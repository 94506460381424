import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/store';

export type RefreshQueriesState = {
  refreshingIds: Record<string, boolean>;
};

export const refreshQueriesSlice = createSlice({
  name: 'refreshQueries',
  initialState: { refreshingIds: {} } as RefreshQueriesState,
  reducers: {
    addRefreshingIds: (state, action: PayloadAction<string[]>) => ({
      ...state,
      refreshingIds: { ...state.refreshingIds, ...action.payload.reduce((acc, id) => ({ ...acc, [id]: true }), {}) },
    }),
    removeRefreshingIds: (state, action: PayloadAction<string[]>) => ({
      ...state,
      refreshingIds: { ...state.refreshingIds, ...action.payload.reduce((acc, id) => ({ ...acc, [id]: false }), {}) },
    }),
  },
});

export const { addRefreshingIds, removeRefreshingIds } = refreshQueriesSlice.actions;

export const selectRefreshQueries = (state: RootState) => state.refreshQueries;
