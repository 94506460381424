import { type FC, type PropsWithChildren, useRef, useState } from 'react';
import ChecklistsManagerDialog from '@/views/Project/components/ChecklistsManager/ChecklistsManagerDialog';
import {
  ChecklistsManagerDialogCloseData,
  ChecklistsManagerDialogContext,
  ChecklistsManagerDialogContextValue,
  ChecklistsManagerDialogOptions,
} from '@/views/Project/components/ChecklistsManager/context/ChecklistsManagerDialogContext';
import { Checklist } from '@/api/generated';

export const ChecklistsManagerDialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const resolveRef = useRef<(result: ChecklistsManagerDialogCloseData) => void>(() => false);
  const [options, setOptions] = useState<ChecklistsManagerDialogOptions | undefined>();

  const contextValue: ChecklistsManagerDialogContextValue = {
    showChecklistsManagerDialog: (params?: ChecklistsManagerDialogOptions) => {
      setIsOpened(true);
      setOptions(params);
      return new Promise<ChecklistsManagerDialogCloseData>(resolve => (resolveRef.current = resolve));
    },
  };

  const onDialogClose = (action?: ChecklistsManagerDialogCloseData['action']) => {
    resolveRef.current({ action });
    setIsOpened(false);
  };

  const onSelect = (checklists: Checklist[]) => {
    resolveRef.current({ checklists });
    setIsOpened(false);
  };

  return (
    <ChecklistsManagerDialogContext.Provider value={contextValue}>
      <ChecklistsManagerDialog
        showNewQuestionButton={options?.showNewQuestionButton}
        mode={options?.mode ?? 'default'}
        isOpen={isOpened}
        onClose={onDialogClose}
        onSelect={onSelect}
      />
      {children}
    </ChecklistsManagerDialogContext.Provider>
  );
};
