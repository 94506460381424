import { Box, Button, CircularProgress, Dialog, Stack, useTheme } from '@mui/material';
import { BaseSyntheticEvent, FC, useCallback, useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { ProjectFull, getFilesystem } from '@/api/generated';
import DocumentUpload from '@/views/Projects/components/ProjectFormDialog/components/DocumentUpload';
import ProjectForm from '@/views/Projects/components/ProjectFormDialog/components/ProjectForm';
import { STATUS } from '@/utils/enums';
import { useFileSystemTree } from '@/views/Projects/components/ProjectFormDialog/hooks/useFileSystemTree';
import { useProjectForm } from '@/views/Projects/components/ProjectFormDialog/hooks/useProjectForm';
import { updateProjectCache } from '@/utils/updateProjectCache';
import VersionsUpload from '@/views/Projects/components/ProjectFormDialog/components/VersionsUpload';
import FilesTree from '@/views/Projects/components/ProjectFormDialog/components/FilesTree/FilesTree';
import { useFilesUpload } from '@/views/Projects/components/ProjectFormDialog/hooks/useDocumentsUpload';
import { TreeFileSystemNode } from '@/types';
import { useSearchParams } from 'react-router-dom';
import { NodeApi } from 'react-arborist';

interface ProjectFormDialogProps {
  isOpened: boolean;
  initialProject?: ProjectFull;
  onClose: (project?: ProjectFull) => void;
  onAfterSave: (project: ProjectFull) => void;
}

const ProjectFormDialog: FC<ProjectFormDialogProps> = ({ isOpened, initialProject, onClose, onAfterSave }) => {
  const queryClient = useQueryClient();
  const { palette } = useTheme();
  const { t } = useTranslation('projectUpdate');

  const treeNodesByIdRef = useRef<Record<string, TreeFileSystemNode>>({});
  const [, setSearchParams] = useSearchParams();

  const { projectRef, form, isSaving, resetFormValues, onSubmit, getOrCreateProject, onSaveField } = useProjectForm({
    initialProject,
    onClose,
    onAfterSave,
  });
  const name = form.watch('name');

  const {
    loadFileSystem,
    selectedDocument,
    selectedDocumentVersions,
    fileSystemNodes,
    importDriveFiles,
    uploadFiles,
    uploadVersion,
    onRename,
    onMove,
    onFolderCreate,
    onDelete,
    onFileOpen,
    onDocumentConvertToPage,
    onAddVersion,
    onVersionsClose,
    deleteDocumentVersion,
    setAsMainDocumentVersion,
    onDeleteSelected,
  } = useFileSystemTree({
    getOrCreateProject,
    getSlug: () => form.getValues('slug'),
    nodesByIdRef: treeNodesByIdRef,
    onClose,
  });

  const filesUploadProps = useFilesUpload({ uploadFiles });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClick, ...rootProps } = filesUploadProps.getRootProps();

  const isUploading = Object.entries(fileSystemNodes).some(([, node]) => node.status === STATUS.LOADING);

  useEffect(() => {
    if (!isOpened) return;
    projectRef.current = initialProject;
    resetFormValues(initialProject);
    loadFileSystem(initialProject);
  }, [isOpened, initialProject]);

  useEffect(() => {
    if (isOpened) setSearchParams({});
  }, [isOpened]);

  useEffect(() => {
    const slug = form.getValues('slug');
    if (!slug || isOpened) return;

    (async () => {
      // We update file system here on close to keep it in sync for documents in navigation.
      const fileSystem = await getFilesystem(slug);
      updateProjectCache({ queryClient, projectSlug: slug }, prevProject => {
        if (!prevProject) return;
        return { ...prevProject, filesystem: fileSystem };
      });
    })();
  }, [isOpened, initialProject]);

  const handleSaveField = useCallback(
    async (data: { name: string; slug?: string; address?: string }) => {
      // Transform data as needed before submitting
      await onSaveField({ target: { value: data.name } } as BaseSyntheticEvent);
    },
    [onSaveField],
  );

  const isSidebarOpen = !!(selectedDocument && selectedDocumentVersions);

  const onNodeClick = useCallback(
    (node: NodeApi<TreeFileSystemNode>) => {
      if (isSidebarOpen) return onAddVersion(node);
      onFileOpen(node.id);
    },
    [onFileOpen, isSidebarOpen, onAddVersion],
  );

  return (
    <Dialog
      component="form"
      fullWidth
      open={isOpened}
      onClose={() => onClose()}
      onSubmit={onSubmit}
      PaperProps={{ sx: { backgroundColor: palette.background.default, maxWidth: 1000 } }}
    >
      <FormProvider {...form}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          px={3}
          pt={2}
          pb={1}
          sx={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1002, backgroundColor: palette.background.default }}
        >
          <Button
            type="submit"
            variant="contained"
            disabled={!name?.trim()}
            sx={{ py: 0.1, minHeight: 26, borderRadius: 99, alignSelf: 'flex-end', selfAlign: 'flex-end' }}
          >
            {isUploading || isSaving ? <CircularProgress size={14} /> : t('details.save')}
          </Button>
        </Stack>
        <ProjectForm onSaveField={handleSaveField} />
        <Box sx={{ p: 3, pt: 0 }}>
          <Stack
            flex={1}
            data-folderid="root"
            sx={{
              minHeight: 0,
              pt: 3,
              mt: 2.5,
              borderTop: `1px solid ${palette.custom.separationLine}`,
              color: '#676767',
            }}
          >
            <Box sx={{ fontSize: 'h3.fontSize', fontWeight: 'bold', color: palette.grey['700'], mb: 2 }}>
              {t('details.projectFiles')}
            </Box>
            <DocumentUpload {...filesUploadProps} importDriveFiles={importDriveFiles} />

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                gap: 2.75,
                maxWidth: '100%',
                height: 464,
                minHeight: 0,
                mt: 1.5,
              }}
            >
              <Stack
                sx={{
                  flex: 1,
                  height: '100%',
                  minWidth: 0,
                  backgroundColor: palette.background.default,
                  border: `1px solid ${palette.secondary.main}`,
                  borderRadius: 2,
                  boxShadow: 3,
                  pt: 3,
                  px: isSidebarOpen ? 0 : 5,
                }}
              >
                <FilesTree
                  sx={{ width: '100%' }}
                  slug={form.getValues('slug')}
                  dropzoneRootProps={rootProps}
                  openFilesDialog={filesUploadProps.openWithId}
                  fileSystemNodes={fileSystemNodes}
                  treeNodesByIdRef={treeNodesByIdRef}
                  onRename={onRename}
                  onMove={onMove}
                  onFolderCreate={onFolderCreate}
                  onDelete={onDelete}
                  onNodeClick={onNodeClick}
                  onAddVersion={onAddVersion}
                  onDocumentConvertToPage={onDocumentConvertToPage}
                  onDeleteSelected={onDeleteSelected}
                  isSidebarOpen={isSidebarOpen}
                />
              </Stack>

              {isSidebarOpen && (
                <Stack
                  sx={{
                    width: '40%',
                    height: '100%',
                    backgroundColor: palette.background.default,
                    border: `1px solid ${palette.secondary.main}`,
                    borderRadius: 2,
                    boxShadow: 3,
                    pt: 0,
                    px: 3,
                  }}
                >
                  <VersionsUpload
                    document={selectedDocument}
                    versionsNodes={selectedDocumentVersions}
                    uploadVersion={uploadVersion}
                    importDriveFiles={importDriveFiles}
                    onVersionClick={onFileOpen}
                    onClose={onVersionsClose}
                    deleteDocumentVersion={deleteDocumentVersion}
                    setAsMainDocumentVersion={setAsMainDocumentVersion}
                  />
                </Stack>
              )}
            </Box>
          </Stack>
        </Box>
      </FormProvider>
    </Dialog>
  );
};

export default ProjectFormDialog;
