import { RefObject, useEffect } from 'react';
import PSPDFKit, {
  AnnotationsUnion,
  AnnotationBackendJSON,
  EventMap,
  Instance,
  List,
  MeasurementValueConfiguration,
} from '@nutrient-sdk/viewer';
import { AnnotationEventType, createOrganizationEquipment } from '@/api/generated';
import { isAutoCountingAnnotation } from '@/containers/DocumentEditor/utils/isAutoCountingAnnotation';
import { isAnnotationScaleHasBrokenValue } from '@/containers/DocumentEditor/utils/isAnnotationScaleHasBrokenValue';
import { useOrganization } from '@/hooks/useOrganization';

type Params = {
  instance?: Instance;
  scalesRef: RefObject<MeasurementValueConfiguration[]>;
  lastPrevConfigRef: RefObject<MeasurementValueConfiguration>;
  updateAnnotations: (eventType: AnnotationEventType, eventAnnotations: AnnotationBackendJSON[]) => void;
};

export const useAnnotationsEvent = ({ instance, scalesRef, lastPrevConfigRef, updateAnnotations }: Params) => {
  const { organization } = useOrganization();

  useEffect(() => {
    if (!instance) return;

    const onAnnotationsEvent = (eventType: AnnotationEventType) => async (eventAnnotations: List<AnnotationsUnion>) => {
      const eventAnnotationsWithoutLocal = eventAnnotations.filter(annotation => !annotation.customData?.isLocal);
      if (!eventAnnotationsWithoutLocal.size) return;

      const firstAnnotation = eventAnnotationsWithoutLocal.get(0);
      const skipTimer = firstAnnotation?.customData?.skipTimer as number | undefined;

      // skipSave or skipDelete is for data that is come/removed from the server.
      // We already have changes on the server, so don't need to send it back.
      if (eventType === 'create' && firstAnnotation?.customData?.skipSave) return;
      if (eventType === 'delete' && firstAnnotation?.customData?.skipDelete) return;

      const isCreatedRecentlyWithNecessaryUpdate = skipTimer && Date.now() - skipTimer < 500;
      if (eventType === 'update' && isCreatedRecentlyWithNecessaryUpdate) return;

      const isAutoCounting = isAutoCountingAnnotation(firstAnnotation);
      if (eventType === 'create' && isAutoCounting) {
        const { item: autoCountEquipment } = await createOrganizationEquipment(organization.id, {
          type: 'pieces',
          description: `Auto detecting item ${firstAnnotation.id}`,
        });
        instance?.update(
          firstAnnotation.set('customData', {
            specialType: 'autoAnnotationParent',
            groupAnnotationKey: autoCountEquipment.equipment_id,
          }),
        );
        return;
      }

      if (eventType === 'create' && firstAnnotation?.points && firstAnnotation.points.toJSON().length <= 2) return;

      if (
        eventType === 'update' &&
        firstAnnotation &&
        isAnnotationScaleHasBrokenValue(firstAnnotation) &&
        scalesRef.current &&
        lastPrevConfigRef.current
      ) {
        const measurementScale = new PSPDFKit.MeasurementScale(lastPrevConfigRef.current.scale);
        const fixedAnnotations = eventAnnotationsWithoutLocal.map(annotation =>
          annotation.set('measurementScale', measurementScale),
        );
        instance?.update(fixedAnnotations);
        return;
      }

      updateAnnotations(eventType, eventAnnotationsWithoutLocal.toArray().map(PSPDFKit.Annotations.toSerializableObject));
    };

    const handlersWithEvenTypes = Object.keys(AnnotationEventType).map(eventType => ({
      pspdfkitEventName: `annotations.${eventType}` as keyof EventMap,
      handler: (eventAnnotations: List<AnnotationsUnion>) => {
        setTimeout(() => {
          const cb = onAnnotationsEvent(eventType as AnnotationEventType);
          cb(eventAnnotations);
        });
      },
    }));

    handlersWithEvenTypes.forEach(({ pspdfkitEventName, handler }) => instance.addEventListener(pspdfkitEventName, handler));

    return () => {
      handlersWithEvenTypes.forEach(
        ({ pspdfkitEventName, handler }) => instance?.removeEventListener(pspdfkitEventName, handler),
      );
    };
  }, [instance, updateAnnotations]);
};
