import { $createParagraphNode } from 'lexical';
import { DraggableBlockSettings, DraggableBlockType } from '@/containers/PagesEditor/types';
import { $createTableNodeWithDimensions } from '@lexical/table';
import { $createListItemNode, $createListNode } from '@lexical/list';
import { $createVariableNode } from '@/containers/PagesEditor/nodes/VariableNode';
import { $createAiNode } from '@/containers/PagesEditor/nodes/AiNode';
import { $createAiContainerNode } from '@/containers/PagesEditor/nodes/AiContainerNode';
import { $createInlineAiContainerNode } from '@/containers/PagesEditor/nodes/InlineAiContainerNode';
import { $createInlineAiNode } from '@/containers/PagesEditor/nodes/InlineAiNode';

export const $createBlockAfterDrop = (type: DraggableBlockType, settings?: DraggableBlockSettings) => {
  switch (type) {
    case 'check':
    case 'number':
    case 'bullet': {
      const list = $createListNode(type);
      list.append($createListItemNode(), $createListItemNode());
      return [list];
    }
    case 'table':
      return [$createTableNodeWithDimensions(3, 5, true)];
    case 'ai': {
      if (!settings || settings.block !== 'ai') return null;

      if (settings.isInline) {
        const container = $createInlineAiContainerNode();
        const paragraph = $createParagraphNode();
        paragraph.append(container);
        container.append($createInlineAiNode({ prompt: '' }));

        return [paragraph];
      }

      const container = $createAiContainerNode();
      container.append($createAiNode({ prompt: '', answerType: settings.answerType }));
      return [container, $createParagraphNode()];
    }
    case 'variable':
      if (!settings || settings.block !== 'variable') return null;
      return [$createVariableNode(settings)];
    default:
      return null;
  }
};
