import { HeadingTagType } from '@lexical/rich-text';
import { ListType } from '@lexical/list';

export type ChangeableBlockType = ListType | HeadingTagType | 'code' | 'quote' | 'paragraph' | 'h4';
export const blockTypes = ['paragraph', 'h1', 'h2', 'h3', 'number', 'bullet', 'check', 'quote', 'code'] as const;

export const defaultTextColor = '#000';
export const defaultBgColor = '#fff';

export const textColors = {
  default: defaultTextColor,
  gray: '#787774',
  brown: '#9f6b53',
  orange: '#d9730d',
  yellow: '#cb912f',
  green: '#448361',
  blue: '#337ea9',
  purple: '#9065b0',
  pink: '#c14c8a',
  red: '#d44c47',
};

export const backgroundColors = {
  default: defaultBgColor,
  gray: '#f1f1ef',
  brown: '#f4eeee',
  orange: '#fbecdd',
  yellow: '#fbf3db',
  green: '#edf3ec',
  blue: '#e7f3f8',
  purple: 'rgba(244, 240, 247, 0.8)',
  pink: 'rgba(249, 238, 243, 0.8)',
  red: '#fdebec',
};
