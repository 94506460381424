import { Box, styled } from '@mui/material';

export const TabItem = styled(Box)<{ isActive: boolean }>(({ theme, isActive }) => ({
  height: 30,
  borderBottom: `2px solid ${isActive ? theme.palette.primary.dark : 'transparent'}`,
  color: isActive ? theme.palette.primary.dark : theme.palette.controls.text,
  '& > *': {
    fontWeight: isActive ? 700 : 400,
  },
  paddingTop: theme.spacing(0.5),
  marginBottom: -1,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}));

export const Divider = styled(Box)(({ theme }) => ({
  height: 12,
  width: 1,
  backgroundColor: theme.palette.custom.separationLine,
}));

export const Header = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[400],
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'stretch',
  padding: theme.spacing(0, 3),
}));

export const Footer = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[400],
  borderTop: `1px solid ${theme.palette.secondary.main}`,
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.5),
}));
