import { FC, ReactNode } from 'react';
import Icon, { IconNames, IconProps } from '@/components/Icon/Icon';
import { TreeFileSystemNode } from '@/types';
import { Box } from '@mui/material';
import { NodeApi } from 'react-arborist';

interface DocumentIconProps extends Omit<IconProps, 'name'> {
  node: NodeApi<TreeFileSystemNode>;
}

const DocumentIcon: FC<DocumentIconProps> = ({ node, ...props }) => {
  const hasVersions = !!node.data.versions && node.data.versions.length > 1;

  let icon: ReactNode;

  if (node.data.type === 'folder') {
    icon = <Icon {...props} name={node.isOpen ? 'folderOpen' : 'folder'} fontSize="medium" />;
  }

  if (node.data.type === 'file') {
    const iconName: IconNames = hasVersions ? 'version' : 'pdf';
    icon = <Icon {...props} name={iconName} fontSize="medium" />;
  }

  return (
    <Box
      sx={{
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: 24,
        height: 24,
      }}
    >
      {icon}
    </Box>
  );
};

export default DocumentIcon;
