import { forwardRef, useImperativeHandle, useRef } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type RoundedInputProps = Omit<TextFieldProps, 'ref'>;

const RoundedInput = forwardRef<HTMLDivElement | null, RoundedInputProps>(({ sx, inputRef: inputRefProp, ...props }, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useImperativeHandle(inputRefProp, () => inputRef.current);

  return (
    <TextField
      {...props}
      ref={ref}
      inputRef={inputRef}
      sx={{
        maxHeight: 300,
        width: '100%',
        '.MuiInputBase-root.MuiOutlinedInput-root': {
          borderRadius: 99,
          boxShadow: 1,
          overflow: 'hidden',
        },
        ...sx,
      }}
    />
  );
});

export default RoundedInput;
