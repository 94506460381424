import { FC, MouseEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Skeleton, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mergeRegister } from '@lexical/utils';
import { $getNodeByKey, COMMAND_PRIORITY_LOW, KEY_BACKSPACE_COMMAND, KEY_DELETE_COMMAND, NodeKey } from 'lexical';
import { AiAnswerType } from '@/containers/PagesEditor/types';
import Icon from '@/components/Icon/Icon';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import AiAnswerTypeSelect from '@/containers/PagesEditor/components/AiAnswerTypeSelect';
import RoundedMentionInput from '@/components/RoundedMentionInput/RoundedMentionInput';
import { $isAiNode } from '@/containers/PagesEditor/nodes/AiNode';
import { useTemplateContext } from '@/containers/PagesEditor/context/TemplateContext';

interface AiBlockProps {
  initialPrompt?: string;
  initialAnswerType?: AiAnswerType;
  nodeKey: NodeKey;
}

const AiBlock: FC<AiBlockProps> = ({ initialPrompt = '', initialAnswerType = 'long_block', nodeKey }) => {
  const { t } = useTranslation('templates');
  const { palette } = useTheme();
  const [editor] = useLexicalComposerContext();
  const [isSelected, setSelected] = useLexicalNodeSelection(nodeKey);
  const { processStatus } = useTemplateContext();

  const [isFocused, setIsFocused] = useState(false);
  const [prompt, setPrompt] = useState(initialPrompt);
  const [answerType, setAnswerType] = useState<AiAnswerType>(initialAnswerType);

  const handleRemove = useCallback(
    (event?: MouseEvent) => {
      event?.stopPropagation();
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if ($isAiNode(node)) {
          node.remove();
        }
      });
      return false;
    },
    [editor, nodeKey],
  );

  const handleIconClick = (event: MouseEvent) => {
    event.stopPropagation();
    setSelected(true);
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(KEY_DELETE_COMMAND, () => isSelected && handleRemove(), COMMAND_PRIORITY_LOW),
        editor.registerCommand(KEY_BACKSPACE_COMMAND, () => isSelected && handleRemove(), COMMAND_PRIORITY_LOW),
      ),
    [editor, isSelected, handleRemove, setSelected],
  );

  const handlePromptChange = (nextPrompt: string) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isAiNode(node)) {
        node.setPrompt(nextPrompt);
      }
    });
    setPrompt(nextPrompt);
  };

  const handleAnswerTypeChange = (nextType: AiAnswerType) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isAiNode(node)) {
        node.setAnswerType(nextType);
      }
    });
    setAnswerType(nextType);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if ((event.ctrlKey || event.metaKey) && event.code === 'KeyA') {
      // Prevents block selection on Ctrl + A.
      event.stopPropagation();
    }
  };

  if (processStatus === 'ready') return null;
  if (processStatus === 'in-progress')
    return <Skeleton variant="rectangular" width="100%" height={30} sx={{ my: 1, borderRadius: 1 }} />;

  return (
    <Box
      sx={{
        position: 'relative',
        my: 1,
        pb: 3.5,
        outline: '1px solid',
        outlineColor: isSelected ? palette.primary.light : 'transparent',
        border: `1px solid ${palette.primary.light}`,
        borderRadius: 2,
        '.AiBlock__remove': isSelected || isFocused ? { display: 'inline-flex' } : undefined,
        '&:hover': { '.AiBlock__remove': { display: 'inline-flex' } },
      }}
    >
      <IconButton sx={{ position: 'absolute', top: -14, left: -15, zIndex: 1 }} size="small" onClick={handleIconClick}>
        <Icon name="ai" fontSize="small" htmlColor={palette.primary.dark} />
      </IconButton>
      <IconButton
        className="AiBlock__remove"
        sx={{
          position: 'absolute',
          right: -6,
          top: -6,
          zIndex: 1,
          display: 'none',
          p: 0,
          width: 17,
          height: 17,
          '&, &:hover': { backgroundColor: palette.background.default },
        }}
        onClick={handleRemove}
      >
        <Icon name="x" sx={{ fontSize: 12 }} />
      </IconButton>
      <RoundedMentionInput
        placeholder={t('aiBlock.prompt.placeholder')}
        autoFocus
        minRows={2}
        ContainerProps={{ sx: { borderRadius: 0, border: 0, background: 'transparent', boxShadow: 'none' } }}
        style={{ width: '100%' }}
        minMaxHeight={100}
        value={prompt}
        featureName="canvas_create"
        // @ts-expect-error
        onKeyDown={handleKeyDown}
        onChange={(_, nextPrompt) => handlePromptChange(nextPrompt)}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <AiAnswerTypeSelect
        sx={{ position: 'absolute', bottom: 10, right: 10 }}
        value={answerType}
        onChange={handleAnswerTypeChange}
      />
    </Box>
  );
};

export default AiBlock;
