import { $applyNodeReplacement, ElementNode, LexicalNode, NodeKey, type SerializedElementNode, Spread } from 'lexical';

export type SerializedAiContainerNode = Spread<{ uuid: string }, SerializedElementNode>;

export class AiContainerNode extends ElementNode {
  /** @internal */
  __uuid: string;

  static getType() {
    return 'ai-container';
  }

  static clone(node: AiContainerNode): AiContainerNode {
    return new AiContainerNode(node.__uuid, node.__key);
  }

  static importJSON(node: SerializedAiContainerNode): AiContainerNode {
    return $createAiContainerNode(node.uuid);
  }

  constructor(uuid?: string, key?: NodeKey) {
    super(key);
    this.__uuid = uuid ?? crypto.randomUUID();
  }

  getUuid(): string {
    return this.__uuid;
  }

  setUuid(uuid: string) {
    const writable = this.getWritable();
    writable.__uuid = uuid;
  }

  createDOM(): HTMLElement {
    return document.createElement('div');
  }

  updateDOM() {
    return false;
  }

  isShadowRoot() {
    return true;
  }

  isInline() {
    return true;
  }

  canBeEmpty() {
    return false;
  }

  canInsertTextBefore() {
    return false;
  }

  canInsertTextAfter() {
    return false;
  }

  exportJSON(): SerializedAiContainerNode {
    return {
      ...super.exportJSON(),
      type: 'ai-container',
      version: 1,
      uuid: this.getUuid(),
    };
  }
}

export function $createAiContainerNode(uuid?: string) {
  return $applyNodeReplacement(new AiContainerNode(uuid));
}

export function $isAiContainerNode(node: LexicalNode | null | undefined): node is AiContainerNode {
  return node instanceof AiContainerNode;
}
