import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/store';

export type SelectQueriesState = {
  selectedIds: string[];
  isSelectMode: boolean;
};

export const selectQueriesSlice = createSlice({
  name: 'selectQueries',
  initialState: { selectedIds: [], isSelectMode: false } as SelectQueriesState,
  reducers: {
    setIsSelectMode: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSelectMode: action.payload,
    }),
    toggleSelectMode: state => ({
      ...state,
      isSelectMode: !state.isSelectMode,
    }),
    setSelectedIds: (state, action: PayloadAction<string[]>) => ({
      ...state,
      selectedIds: action.payload,
    }),
  },
});

export const { setIsSelectMode, setSelectedIds, toggleSelectMode } = selectQueriesSlice.actions;

export const selectSelectQueries = (state: RootState) => state.selectQueries;
