import { alpha, ButtonBase, ButtonProps, Stack, Typography, useTheme } from '@mui/material';
import Icon, { IconNames } from '@/components/Icon/Icon';
import { ElementType, FC } from 'react';
import { LinkProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface AppProps extends ButtonProps {
  label: string;
  isEnabled: boolean;
  iconName: IconNames;
  component?: ElementType;
  to?: LinkProps['to'];
}

const App: FC<AppProps> = ({ label, isEnabled, iconName, ...rest }) => {
  const { palette } = useTheme();
  const { t } = useTranslation('projects');

  return (
    <Stack sx={{ position: 'relative', overflow: 'hidden', width: '100%' }}>
      {!isEnabled && (
        <Typography
          sx={{
            backgroundColor: alpha(palette.primary.dark, 0.8),
            position: 'absolute',
            top: 13,
            left: -25,
            right: 0,
            fontSize: 'body4.fontSize',
            transform: 'rotate(-35.7deg)',
            textAlign: 'center',
            zIndex: 1,
          }}
        >
          {t('apps.comingSoon')}
        </Typography>
      )}
      <ButtonBase
        {...rest}
        disableRipple
        disabled={!isEnabled}
        title={label}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gap: 1,
          width: '100%',
          p: 0.75,
          opacity: isEnabled ? 1 : 0.5,
          '&:hover': {
            backgroundColor: palette.grey[50],
          },
        }}
      >
        <Icon name={iconName} fontSize="large" htmlColor={palette.text.primary} />
        <Typography sx={{ fontSize: 'body3.fontSize', color: palette.grey[900] }}>{label}</Typography>
      </ButtonBase>
    </Stack>
  );
};

export default App;
