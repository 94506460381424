import { Menu, styled } from '@mui/material';

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: 'none',
    borderRadius: 8,
    minWidth: 160,
  },
  '& .MuiList-root': {
    padding: 0,
  },
  '& .MuiMenuItem-root': {
    height: 46,
    borderBottom: `1px solid ${theme.palette.custom.separationLine}`,
    color: theme.palette.grey[900],
    '&:last-of-type': {
      borderBottom: 0,
    },
    '& svg': {
      marginRight: 8,
    },
  },
}));
