import { Button, Stack, Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckIfAllDocumentsAreVectorized } from '@/api/generated';
import DialogCloseButton from '@/components/Dialog/DialogCloseButton';
import Dialog from '@/components/Dialog/Dialog';
import { QueriesActions } from '@/views/Project/views/Queries/enums';
import { useProject } from '@/views/Project/hooks/useProject';

export interface PopupDocumentsInProgressProps {
  isOpened: boolean;
  action: QueriesActions;
  onClose: (result: boolean) => void;
  onProceed: () => Promise<void> | undefined;
}

const PopupDocumentsInProgress: FC<PopupDocumentsInProgressProps> = ({ isOpened, action, onClose, onProceed }) => {
  const { t } = useTranslation('project');
  const { palette, typography, shape } = useTheme();

  const { projectSlug } = useProject();
  const { data: { docs_in_progress: documentsInProgress } = {} } = useCheckIfAllDocumentsAreVectorized(projectSlug);

  const onClickProceed = async () => {
    await onProceed();
    onClose(true);
  };

  return (
    <Dialog width={500} open={isOpened} onClose={onClose}>
      <DialogCloseButton positioned onClick={() => onClose(false)} />
      <Stack alignItems="center" sx={{ width: '100%', height: '100%' }} justifyContent="space-between">
        <Typography fontWeight={typography.fontWeightBold} fontSize={typography.body1.fontSize} sx={{ py: 3 }} textAlign="center">
          {t(`queries.popupDocumentsInProgress.explanation.${action}`)}
        </Typography>

        <Box component="ul" m={0} p={0} sx={{ pb: 3, width: '100%', listStyle: 'none', textAlign: 'center' }}>
          {documentsInProgress?.map(({ id, name }) => (
            <Typography key={id} component="li" variant="body2">
              {name}
            </Typography>
          ))}
        </Box>
        <Stack direction="row" gap={2}>
          <Button
            variant="contained"
            sx={{
              borderRadius: shape.borderRadius,
              color: palette.primary.dark,
              backgroundColor: palette.primary.main,
              px: 5.5,
              py: 1.25,
              fontWeight: typography.fontWeightLight,
            }}
            onClick={() => onClose(false)}
          >
            {t('queries.popupDocumentsInProgress.button.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={onClickProceed}
            sx={{
              borderRadius: shape.borderRadius,
              color: palette.primary.dark,
              backgroundColor: palette.primary.main,
              px: 3.725,
              py: 1.25,
              fontWeight: typography.fontWeightLight,
            }}
          >
            {t(`queries.popupDocumentsInProgress.button.proceed.${action}`)}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default PopupDocumentsInProgress;
