import { useScript } from 'usehooks-ts';
import { useEffect, useRef } from 'react';
import { getGoogleToken } from '@/api/generated';
import { acceptedFileTypes } from '@/utils/consts';

type Params = {
  onAction: (picker: google.picker.Picker, response: google.picker.ResponseObject) => void;
};

export const useGooglePicker = ({ onAction }: Params) => {
  const isOpenedRef = useRef(false);
  const isLoadedRef = useRef(false);
  const pickerRef = useRef<google.picker.Picker | null>(null);
  const apiLoadStatus = useScript('https://apis.google.com/js/api.js');
  const gsiLoadStatus = useScript('https://accounts.google.com/gsi/client');

  const openPicker = async () => {
    if (!isLoadedRef.current) return;

    const oauthToken = await getGoogleToken();
    isOpenedRef.current = true;
    pickerRef.current?.dispose();
    pickerRef.current = new google.picker.PickerBuilder()
      .setOAuthToken(oauthToken as string)
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setCallback(response => onAction(pickerRef.current!, response))
      .setSelectableMimeTypes(Object.keys(acceptedFileTypes).join(','))
      .setDeveloperKey(import.meta.env.VITE_GOOGLE_API_KEY)
      .setAppId(import.meta.env.VITE_GOOGLE_API_APP_ID)
      .addView(google.picker.ViewId.DOCS)
      .build();
    pickerRef.current.setVisible(true);
  };

  useEffect(() => {
    if (apiLoadStatus !== 'ready' || gsiLoadStatus !== 'ready') return;

    (async () => {
      try {
        await Promise.all([
          new Promise(resolve => gapi.load('auth', () => resolve(true))),
          new Promise(resolve => gapi.load('picker', () => resolve(true))),
        ]);

        isLoadedRef.current = true;
        if (isOpenedRef.current) openPicker();
      } catch (error) {
        console.error('Error while loading gapi', error);
      }
    })();
  }, [apiLoadStatus, gsiLoadStatus]);

  return {
    error: apiLoadStatus === 'error' || gsiLoadStatus === 'error',
    openPicker,
    pickerRef,
  };
};
