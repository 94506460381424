import { getSessionToken } from '@descope/react-sdk';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import i18n from 'i18next';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';
import {
  automatePage,
  updatePage as updatePageFn,
  updatePages as updatePagesFn,
  deletePage as deletePageFn,
  TemplatePageMetadata,
  getPageById,
  GetPageById200,
  PageResponse,
  setPageIsPrivate,
  PagesUpdate,
  getAllPages,
  GetAllPages200Item,
  runTemplatePage,
  TemplatePage,
} from '@/api/generated';
import { selectPageProcessingState, setPageProcessingState, updateCurrentPage } from '@/store/lexicalPagesSlice';
import { EditedPage } from '@/containers/PagesEditor/types';

type PageStateSourceEventData = {
  status: 'in_progress' | 'success' | 'error';
  page: TemplatePageMetadata;
};

type KeyProps = { slug: string };
type LoadPagesPayload = KeyProps;
type LoadPagePayload = { id: string } & KeyProps;
type CreatePagePayload = { page: EditedPage } & KeyProps;
type CreatePageFromTemplatePayload = { templateId: string; instructions: string } & KeyProps;
type UpdatePagePayload = { id: string; page: EditedPage } & KeyProps;
type UpdatePagesPayload = { pages: PagesUpdate[] } & KeyProps;
type DeletePagePayload = { id: string } & KeyProps;
type ToggleIsPrivatePayload = { id: string; state: boolean } & KeyProps;
type WaitForPageReadyPayload = { id: string } & KeyProps;

export const loadPages = createAppAsyncThunk<GetAllPages200Item[], LoadPagesPayload, { rejectValue: AxiosError }>(
  'lexicalPages/loadPages',
  async ({ slug }, api) => {
    try {
      const pages = await getAllPages(slug);
      return api.fulfillWithValue(pages);
    } catch (error) {
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const loadCurrentPage = createAppAsyncThunk<GetPageById200, LoadPagePayload, { rejectValue: AxiosError }>(
  'lexicalPages/loadCurrentPage',
  async ({ id, slug }, api) => {
    try {
      const page = await getPageById(slug, id);
      return api.fulfillWithValue(page);
    } catch (error) {
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const createNewPage = createAppAsyncThunk<PageResponse, CreatePagePayload, { rejectValue: AxiosError }>(
  'lexicalPages/createNewPage',
  async ({ page, slug }, api) => {
    try {
      const createdPage = await automatePage(slug, page);
      return api.fulfillWithValue(createdPage);
    } catch (error) {
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const createPageFromTemplate = createAppAsyncThunk<
  TemplatePage,
  CreatePageFromTemplatePayload,
  { rejectValue: AxiosError }
>('lexicalPages/createPageFromTemplate', async ({ templateId, instructions, slug }, api) => {
  try {
    const createdPage = await runTemplatePage({ template_id: templateId, general_instructions: instructions }, { slug });
    api.dispatch(waitForPageReady({ id: createdPage._id!, slug }));
    enqueueSnackbar(i18n.t('templates:pageCreateDialog.success'));
    return api.fulfillWithValue(createdPage);
  } catch (error) {
    console.error('error while creating new page from a template', error);
    enqueueSnackbar(i18n.t('templates:pageCreateDialog.error'), { variant: 'error' });
    return api.rejectWithValue(error as AxiosError);
  }
});

export const updatePage = createAppAsyncThunk<PageResponse, UpdatePagePayload, { rejectValue: AxiosError }>(
  'lexicalPages/updatePage',
  async ({ id, page, slug }, api) => {
    try {
      const updatedPage = await updatePageFn(slug, id, page);
      return api.fulfillWithValue(updatedPage);
    } catch (error) {
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const updatePages = createAppAsyncThunk<undefined, UpdatePagesPayload, { rejectValue: AxiosError }>(
  'lexicalPages/updatePages',
  async ({ slug, pages }, api) => {
    try {
      const currentPage = api.getState().lexicalPages.currentPage;
      if (currentPage && '_id' in currentPage) {
        const updatedCurrentPage = pages.find(page => page.page_id === currentPage._id);
        updatedCurrentPage && api.dispatch(updateCurrentPage(updatedCurrentPage));
      }

      await updatePagesFn(slug, pages);
    } catch (error) {
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const deletePage = createAppAsyncThunk<undefined, DeletePagePayload, { rejectValue: AxiosError }>(
  'lexicalPages/deletePage',
  async ({ id, slug }, api) => {
    try {
      await deletePageFn(slug, id);
    } catch (error) {
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const toggleIsPrivate = createAppAsyncThunk<undefined, ToggleIsPrivatePayload, { rejectValue: AxiosError }>(
  'lexicalPages/toggleIsPrivate',
  async ({ id, slug, state }, api) => {
    try {
      await setPageIsPrivate(slug, id, { is_private: state });
      enqueueSnackbar(
        state ? i18n.t('project:pages.editor.makePagePrivateSuccess') : i18n.t('project:pages.editor.makePagePublicSuccess'),
      );
    } catch (error) {
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const waitForPageReady = createAppAsyncThunk<
  TemplatePageMetadata | undefined,
  WaitForPageReadyPayload,
  { rejectValue: PageStateSourceEventData | Error }
>('lexicalPages/waitForPageReady', ({ id }, api) => {
  const isPageProcessing = selectPageProcessingState(api.getState(), id);
  if (isPageProcessing) return api.rejectWithValue(new Error('Already checking'));

  api.dispatch(setPageProcessingState({ id, state: true }));

  return new Promise((resolve, reject) => {
    const sessionToken = getSessionToken();
    fetchEventSource(`${process.env.VITE_API_BASE_URL}/v1/page-templates/${id}/status`, {
      headers: { Authorization: `Bearer ${sessionToken}` },
      onmessage: async event => {
        if (!event.data) return;
        const eventData = JSON.parse(event.data) as PageStateSourceEventData;
        const { page, status } = eventData;

        if (status === 'error') return reject(eventData);
        if (status === 'success') return resolve(page);
      },
    });
  });
});
