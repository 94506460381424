import { FC, useMemo } from 'react';
import { LearningCenterItem } from '@/api/generated';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import Icon from '../Icon/Icon';
import { useTranslation } from 'react-i18next';

interface GridItemProps {
  data: LearningCenterItem;
  select: (item: LearningCenterItem) => void;
}

export const GridItem: FC<GridItemProps> = ({ data, select }) => {
  const { palette, shadows } = useTheme();
  const { t } = useTranslation('common');

  const videoId = useMemo(() => (data.url ? (data.url as string).split('v=')[1]?.split('&')[0] || '' : ''), [data.url]);
  const cover = useMemo(
    () => (data.image && data.image !== 'string' ? data.image : `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`),
    [data.image, videoId],
  );

  return (
    <Box
      sx={{
        backgroundColor: palette.background.default,
        borderRadius: 2,
        px: 3,
        py: 2,
        boxShadow: shadows[23],
        cursor: 'pointer',
        height: '100%',
      }}
      onClick={() => select(data)}
    >
      <Stack direction="row" alignItems="center" gap={1} sx={{ color: palette.controls.placeholder }}>
        <Icon name="play" />
        <Typography variant="body2">
          {data.duration?.minutes ? t('helpDialog.videoDuration.minutes', { minutes: data.duration?.minutes }) : ''}
          {data.duration?.seconds ? ` ${t('helpDialog.videoDuration.seconds', { seconds: data.duration?.seconds })}` : ''}
        </Typography>
      </Stack>
      <Box
        sx={{
          width: '100%',
          height: 112,
          objectFit: 'cover',
          borderRadius: 2,
          overflow: 'hidden',
          my: 1.25,
          backgroundColor: palette.grey[400],
        }}
      >
        <img
          width="100%"
          height="100%"
          style={{ objectFit: 'cover' }}
          src={cover}
          alt={data.name}
          loading="lazy"
          draggable={false}
        />
      </Box>
      <Typography
        color="text.secondary"
        fontWeight={500}
        mt={1.25}
        title={data.name}
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          maxHeight: '3rem',
        }}
      >
        {data.name}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        fontWeight={400}
        mt={1.5}
        title={data.description}
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          maxHeight: '3rem',
        }}
      >
        {data.description}
      </Typography>
    </Box>
  );
};
