import { Shadows } from '@mui/material';

// TODO remove similar shadows
export default [
  'none', // 0 none
  '0 0 16px 0 rgba(197, 197, 197, 0.80)', // 1 black drop around
  '0 4px 12px 0 #D0D0D0', // 2 black drop around
  '0 4.093px 16.372px 0 #D0D0D0', // 3 black drop around
  '0px 4.093px 20px 0px #C3C3C3', // 4 black drop around
  '0px 0px 5px 1px #5209e6', // 5 pink halo
  '0 0 0 0 rgba(0, 0, 0, 0)', // 6 black border
  '-17px 1px 5px -8px #fff inset', // 7 white inset from the right
  '-17px 1px 5px -8px #f5f5f5 inset', // 8 grey inset from the right
  '-0.47px 0.24px 1.42px 0px rgba(0, 0, 0, 0.25)', // 9 small grey mostly down and left
  '-17px 1px 5px -8px #fff', // 10 white from the left
  '3px 5px 5px 0px rgba(139,137,134,0.5)', // 11 grey mostly down and right
  '0 -0.48px 12.16px -4px #A884F3', // 12 small pink halo
  '0 2px 12.16px 0 #A884F3', // 13 pink halo
  '0 1.14px 9.12px 0 rgba(168,132,243,0.5)', // 14 small pink halo
  '0px 0px 8px 0px #C5C5C5', // 15 small grey drop around
  '0px 4px 16px 0 rgba(197, 197, 197, 0.80)', // 16 grey drop around
  '0 -19px 17px -8px #f5f5f5 inset', // 17 grey inset from bottom
  '0px 12px 24px 0 rgba(0, 72, 217, 0.10)',
  '0px 4px 16px 0 #ECEBEB',
  '0 -19px 17px -8px #fff inset', // 20 white inset from bottom
  '0 0 20px 0 #BA9DF5', // 21 pink halo
  '0 4px 12px 0 #DCCEFA', // 22 small grey drop around
  '0 10px 26px 0 rgba(197, 196, 195, 0.25)', // 23 small grey drop around
  '0 40px 104px 0 rgba(197, 196, 195, 0.25)', // 24 small grey drop around
] as Shadows;
