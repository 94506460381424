import { FC } from 'react';
import { Box, Button, ButtonProps, Tooltip } from '@mui/material';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import TagsSelectPopover from '@/components/TagsSelectPopover';
import TagView from '@/components/TagSelect/TagView/TagView';
import { Tag } from '@/api/generated';

interface TagSelectSizedProps extends ButtonProps {
  isDisabled?: boolean;
  visibleTagsLimit?: number;
  isHighlighted?: boolean;
  headerText?: string;
  tags?: Tag[];
  selectedTags: string[];
  onTagAdd: (name: string) => void;
  onTagDelete: (name: string) => void;
  onEnter?: (search: string) => void;
  addButtonText?: string;
  showAddButton?: boolean;
}

const TagSelectSized: FC<TagSelectSizedProps> = ({
  isDisabled,
  visibleTagsLimit,
  isHighlighted,
  headerText,
  tags = [],
  selectedTags,
  onTagAdd,
  onTagDelete,
  onEnter,
  addButtonText,
  showAddButton,
  className,
  variant = 'contained',
  size = '2xsmall',
  color = 'secondary',
}) => {
  const { t } = useTranslation('common');
  const popupState = usePopupState({ popupId: 'tags-select', variant: 'popover' });

  const limitedTags = visibleTagsLimit ? selectedTags.slice(0, visibleTagsLimit) : selectedTags;
  const moreTagThenLimit = visibleTagsLimit && selectedTags.length > visibleTagsLimit;

  const onTagToggle = (name: string) => {
    if (isDisabled) return;

    if (selectedTags.includes(name)) {
      onTagDelete(name);
    } else {
      onTagAdd(name);
    }
  };

  return (
    <Box className={className} sx={{ display: 'flex', gap: 0.5, alignItems: 'center', flexWrap: 'wrap' }}>
      {limitedTags.map(tagName => (
        <TagView
          size={size}
          variant={variant}
          color={color}
          key={tagName}
          tag={tagName}
          disableRipple
          isHighlighted={isHighlighted}
          onDelete={() => onTagToggle(tagName)}
          disabled={isDisabled}
          sx={{ maxWidth: 150 }}
        />
      ))}
      {(!isDisabled || showAddButton) && (
        <Tooltip arrow title={t('tagSelect.tooltip')} disableInteractive>
          <Button
            {...bindTrigger(popupState)}
            size={size}
            variant={variant}
            color={color}
            sx={{
              minWidth: size === '3xsmall' ? 15 : 24,
              px: (isDisabled || !showAddButton) && !addButtonText ? 0.2 : undefined,
            }}
          >
            {`+${moreTagThenLimit ? selectedTags.length - visibleTagsLimit : ''}`}
            {(!isDisabled || showAddButton) && addButtonText && ` ${addButtonText}`}
          </Button>
        </Tooltip>
      )}
      <TagsSelectPopover
        popupState={popupState}
        placeholder={t('tagSelect.searchPlaceholder')}
        headerText={headerText}
        tags={tags}
        tagViewSize={size}
        selectedTags={selectedTags}
        onTagToggle={onTagToggle}
        onEnter={onEnter}
      />
    </Box>
  );
};

export default TagSelectSized;
