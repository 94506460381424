import { Components, Theme } from '@mui/material/styles';

const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    popper: {
      zIndex: 2000,
    },
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.dark,
      fontSize: theme.typography.body2.fontSize,
      padding: theme.spacing(1),
    }),
    arrow: ({ theme }) => ({
      color: theme.palette.secondary.main,
    }),
  },
};
export default MuiTooltip;
