import { FC } from 'react';
import { ButtonBase, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@/components/Icon/Icon';

interface AskAiButtonProps {
  onClick: () => void;
}

const AskAiButton: FC<AskAiButtonProps> = ({ onClick }) => {
  const { t } = useTranslation('lexicalEditor');
  const { palette } = useTheme();

  return (
    <ButtonBase
      sx={{
        display: 'flex',
        gap: 0.5,
        p: 1,
        pl: 0.5,
        borderRadius: 1,
        letterSpacing: -0.3,
        fontWeight: 'fontWeightBold',
        whiteSpace: 'nowrap',
        color: palette.primary.dark,
      }}
      onClick={onClick}
    >
      <Icon name="ai" fontSize="small" />
      {t('editor.toolbar.askAi')}
    </ButtonBase>
  );
};

export default AskAiButton;
