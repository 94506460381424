import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { useQueryClient } from '@tanstack/react-query';
import { CreateHandler, DeleteHandler, MoveHandler, NodeApi, RenameHandler } from 'react-arborist';
import { enqueueSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { DocumentVersionNode, ImportDriveFilesFn, ImportParams } from '@/views/Projects/components/ProjectFormDialog/types';
import { STATUS } from '@/utils/enums';
import { format } from 'date-fns/format';
import cloneDeepWith from 'lodash/cloneDeepWith';
import { updateProjectCache } from '@/utils/updateProjectCache';
import {
  AutodeskFile,
  Document,
  DocumentMetadata,
  FileSystem,
  Folder,
  PageType,
  ProjectFull,
  automatePage,
  convertPdfToPages,
  createFoldersBatch,
  importAutodeskFile,
  importDocument,
  importMicrosoftFile,
  importSharepointFile,
  updateFilesystemBatch,
  deleteDocumentVersion,
  replaceMainVersion,
} from '@/api/generated';
import { useDeleteFilesAndFolders } from '@/hooks/useDeleteFilesAndFolders';
import { useRenameDocument } from '@/hooks/useRenameDocument';
import { constructFileSystemTree } from '@/utils/constructFileSystemTree';
import { convertFilSystemTreeToServerFileSystem } from '@/views/Projects/components/ProjectFormDialog/utils/convertFilSystemTreeToServerFileSystem';
import { removePdfExtension } from '@/views/Projects/components/ProjectFormDialog/utils/removePdfExtension';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { useEmptyDocumentProject } from '@/views/Project/hooks/useEmptyDocumentProject';
import { PROJECT_ROUTER_IDS, toProjectHomepage, toProjectPages } from '@/services/linker';
import { convertMarkdownToLexicalState } from '@/containers/PagesEditor/utils/convertMarkdownToLexicalState';
import { DocumentVersion, DriveFile, OneDriveFile, SherPointFile, TreeFileSystemNode } from '@/types';
import useRouteId from '@/hooks/useRouteId';
import { isUploadFileArray } from '@/views/Projects/components/ProjectFormDialog/utils/isUploadFileArray';
import { getRelatedFilesAndFolders } from '@/views/Projects/components/ProjectFormDialog/utils/getRelatedFilesAndFolders';
import { selectProjectUploadProgress, uploadFileThunk } from '@/store/uploadSlice';
import { useAppDispatch, useAppSelector } from '@/store';
import { AxiosError } from 'axios';
import { removeSelectedNodeIds, selectProjectFileTree, selectSelectedNodeIds, setFileTree } from '@/store/fileTreeSlice';
import { sortTreeNodes } from '@/views/Projects/components/ProjectFormDialog/utils/sortNodesChildrens';

type Params = {
  getOrCreateProject: () => Promise<{ slug: string }>;
  getSlug: () => string | undefined;
  nodesByIdRef: MutableRefObject<Record<string, TreeFileSystemNode>>;
  onClose?: () => void;
};

type RenameParams = {
  id: string;
  name: string;
};

const cloneCustomizer = (value: unknown) => (value instanceof File ? value : undefined);

export const useFileSystemTree = ({ getOrCreateProject, getSlug, nodesByIdRef: treeNodesByIdRef, onClose }: Params) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('projectUpdate');
  const { showConfirmDialog } = useConfirmDialog();
  const { setForceDropZoneView } = useEmptyDocumentProject();
  const currentRouterId = useRouteId();
  const [searchParams, setSearchParams] = useSearchParams();

  const [documents, setDocuments] = useState<DocumentMetadata[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<DocumentMetadata | null>(null);
  const [selectedDocumentVersions, setSelectedDocumentVersions] = useState<DocumentVersionNode[] | undefined>(undefined);

  const uploadProgressState = useAppSelector(selectProjectUploadProgress(getSlug()));
  const fileSystemRootNodes = useAppSelector(selectProjectFileTree(getSlug())) ?? [];

  const deleteFilesAndFolders = useDeleteFilesAndFolders(queryClient);
  const renameDocument = useRenameDocument(queryClient);

  const insertUploadingEntitiesIntoTree = useCallback(
    (nodesById: Record<string, TreeFileSystemNode>) => {
      if (!uploadProgressState) return;

      const entries = Object.entries(uploadProgressState).filter(([, { document, error }]) => !document && !error);
      const uploadingFiles = entries.filter(([, { versionedFileId }]) => !versionedFileId);
      const uploadingVersions = entries.filter(
        ([, { versionedFileId }]) => versionedFileId && selectedDocument?._id === versionedFileId,
      );

      if (uploadingVersions.length) {
        const loadingVersions = uploadingVersions.map(([id, uploadState]) => ({
          id,
          lastModified: format(Date.now(), 'P'),
          version: { id, version_number: 0, filename: uploadState.name } satisfies DocumentVersion,
          file: undefined,
          filename: uploadState.name,
          status: STATUS.LOADING,
          progress: uploadState.progress,
        }));
        setSelectedDocumentVersions(prevVersions => {
          if (!prevVersions) return loadingVersions;

          const nextVersions = [...prevVersions];
          loadingVersions.forEach(loadingVersion => {
            const childIndex = prevVersions.findIndex(child => child.id === loadingVersion.id);
            if (childIndex === -1) {
              nextVersions.push(loadingVersion);
            } else {
              nextVersions[childIndex] = loadingVersion;
            }
          });
          return nextVersions;
        });
      }

      uploadingFiles.forEach(([id, data]) => {
        nodesById[id] = {
          type: 'file' as const,
          id,
          name: removePdfExtension(data.name),
          file: undefined,
          parentId: data.parentId,
          order: data.order ?? 0,
          status: data.error ? STATUS.ERROR : STATUS.LOADING,
          progress: data.progress,
        };

        if (!data.parentId) return;

        nodesById[data.parentId].children = nodesById[data.parentId].children ?? [];
        const childIndex = nodesById[data.parentId].children!.findIndex(child => child.id === id);
        if (childIndex === -1) {
          nodesById[data.parentId].children!.push(nodesById[id]);
        } else {
          nodesById[data.parentId].children![childIndex] = nodesById[id];
        }
      });
    },
    [uploadProgressState, selectedDocument],
  );

  const loadFileSystem = async (project?: ProjectFull) => {
    setDocuments(project?.documents ?? []);
    updateFileSystemTree(project?.filesystem ?? { root: {} }, project?.documents ?? []);
  };

  const getRootNodes = (nodesById: Record<string, TreeFileSystemNode>) =>
    Object.entries(nodesById).reduce<TreeFileSystemNode[]>((acc, [, node]) => (node.parentId ? acc : [...acc, node]), []);

  const sortBy = searchParams.get('sortBy') ?? undefined;
  const sortDir = searchParams.get('sortDir') ?? undefined;

  const setTreeNodeByIdState = (nodesById: Record<string, TreeFileSystemNode>) => {
    const slug = getSlug();
    if (!slug) return;

    // Select only root nodes the rest should be inside in nodes children prop.
    const rootNodes = getRootNodes(nodesById);
    dispatch(setFileTree({ slug, fileTree: sortTreeNodes(rootNodes, sortBy, sortDir) }));
  };

  useEffect(() => {
    insertUploadingEntitiesIntoTree(treeNodesByIdRef.current);
    setTreeNodeByIdState(treeNodesByIdRef.current);
  }, [uploadProgressState, insertUploadingEntitiesIntoTree]);

  useEffect(() => {
    setTreeNodeByIdState(treeNodesByIdRef.current);
  }, [sortBy, sortDir]);

  const updateFileSystemTree = useCallback(
    (fileSystem: FileSystem, initialDocuments: DocumentMetadata[]) => {
      const documentsById = initialDocuments.reduce<Record<string, DocumentMetadata>>((acc, document) => {
        acc[document._id!] = document;
        return acc;
      }, {});

      treeNodesByIdRef.current = constructFileSystemTree(fileSystem, documentsById);
      insertUploadingEntitiesIntoTree(treeNodesByIdRef.current);
      setTreeNodeByIdState(treeNodesByIdRef.current);
    },
    [insertUploadingEntitiesIntoTree],
  );

  function createDocumentNodesFromFiles<T extends File | DriveFile>(
    files: T[],
    parentIdsMap: Map<T, string | undefined>,
    customNamesMap: Map<T, string>,
  ): TreeFileSystemNode<T>[] {
    const nodes = files.map(file => ({
      id: crypto.randomUUID(),
      type: 'file' as const,
      name: customNamesMap.get(file) ?? file.name!,
      order: 0,
      parentId: parentIdsMap.get(file),
      file,
      status: STATUS.LOADING,
      progress: 0,
    }));

    nodes.forEach(node => {
      treeNodesByIdRef.current[node.id] = node;
      if (node.parentId && treeNodesByIdRef.current[node.parentId]?.children) {
        treeNodesByIdRef.current[node.parentId].children!.push(node);
      }
    });
    setTreeNodeByIdState(treeNodesByIdRef.current);

    return nodes;
  }

  const addFolderIntoTree = ({ name = t('uploadFiles.newFolder'), parentId }: { name?: string; parentId?: string }) => {
    const id = crypto.randomUUID();
    const folderNode = {
      type: 'folder' as const,
      id,
      name,
      file: undefined,
      order: 0,
      parentId,
      status: STATUS.LOADED,
      progress: 0,
      children: [],
    };

    treeNodesByIdRef.current![id] = folderNode;
    if (parentId && treeNodesByIdRef.current![parentId].children) {
      treeNodesByIdRef.current[parentId].children.push(folderNode);
    }
    setTreeNodeByIdState(treeNodesByIdRef.current);

    return folderNode;
  };

  const addDocumentIntoTree = ({
    id,
    parentId,
    projectSlug,
    document,
  }: {
    id: string;
    parentId?: string;
    projectSlug: string;
    document: DocumentMetadata;
  }) => {
    updateProjectCache({ queryClient, projectSlug }, prevProject => ({
      ...prevProject!,
      documents: [...(prevProject!.documents || []), document],
    }));
    setDocuments(prevDocuments => [...prevDocuments, document]);

    delete treeNodesByIdRef.current[id];

    const mainVersion: DocumentVersion = {
      id: document._id!,
      last_modified: document.last_modified!,
      version_number: 0,
      filename: document.filename,
    };

    treeNodesByIdRef.current[document._id!] = {
      type: 'file' as const,
      id: document._id!,
      name: removePdfExtension(document.filename),
      order: 0,
      file: undefined,
      document,
      parentId,
      versions: [mainVersion],
      status: STATUS.LOADED,
      progress: 100,
    };
    if (parentId && treeNodesByIdRef.current[parentId]?.children) {
      treeNodesByIdRef.current[parentId].children = treeNodesByIdRef.current[parentId].children.filter(child => child.id !== id);
      treeNodesByIdRef.current[parentId].children.push(treeNodesByIdRef.current[document._id!]);
    }
    setTreeNodeByIdState(treeNodesByIdRef.current);
  };

  const replaceTreeNodeOnNewVersionOfDocument = (document: DocumentMetadata, currentDocumentId?: string) => {
    if (!currentDocumentId) return;
    const node = treeNodesByIdRef.current[currentDocumentId];
    treeNodesByIdRef.current[document._id!] = {
      type: 'file' as const,
      id: document._id!,
      name: removePdfExtension(document.filename),
      order: 0,
      file: undefined,
      versions: node.versions,
      document: document,
      parentId: node.parentId,
      status: STATUS.LOADED,
      progress: 100,
    };
    delete treeNodesByIdRef.current[currentDocumentId];
    setTreeNodeByIdState(treeNodesByIdRef.current);
  };

  const onDeleteDocumentVersion = async (documentVersionId: string) => {
    const slug = getSlug();
    if (!selectedDocument || !slug) return;
    const documentId = selectedDocument._id!;

    setSelectedDocumentVersions(
      currentState =>
        currentState?.map(version => {
          if (version.id === documentVersionId) {
            version.status = STATUS.DELETING;
          }
          return version;
        }),
    );

    await deleteDocumentVersion(slug, documentId, documentVersionId);
    // update documentId in URL if user has removed current document
    const openedDocumentId = searchParams.get('documentId');
    if (openedDocumentId === documentVersionId) {
      setSearchParams(currentParams => {
        const nextSearchParams = new URLSearchParams(currentParams);
        nextSearchParams.set('documentId', documentId);
        return nextSearchParams;
      });
    }

    treeNodesByIdRef.current[documentId].versions = treeNodesByIdRef.current[documentId]?.versions?.filter(
      version => version.id !== documentVersionId,
    );
    setSelectedDocumentVersions(
      treeNodesByIdRef.current[documentId].versions?.map(version => ({
        id: version.id,
        lastModified: version.last_modified!,
        filename: version.filename,
        version,
        file: undefined,
        status: STATUS.LOADED,
        progress: 100,
      })),
    );
  };

  const setAsMainDocumentVersion = async (documentVersionId: string) => {
    const slug = getSlug();
    if (!selectedDocument || !slug) return;
    const prevDocumentId = selectedDocument._id!;
    const nextDocumentId = documentVersionId;

    const documentVersion = await replaceMainVersion(slug, prevDocumentId, nextDocumentId);
    if (documentVersion) {
      setDocuments(currentState => [documentVersion, ...currentState.filter(document => document._id !== prevDocumentId)]);
      replaceTreeNodeOnNewVersionOfDocument(documentVersion, prevDocumentId);
      setSelectedDocument(documentVersion);
    }
  };

  async function uploadIntoVersions<T extends File | DriveFile>(
    files: T[],
    {
      importFn,
      versionedFileId,
    }: {
      importFn: (slug: string, node: DocumentVersionNode<T>) => Promise<DocumentMetadata | Document>;
      versionedFileId: string;
    },
  ) {
    const [file] = files;
    const nextVersionNode = {
      id: crypto.randomUUID(),
      lastModified: format(Date.now(), 'P'),
      filename: file.name ?? '...',
      file,
      progress: 0,
      status: STATUS.LOADING,
    } satisfies DocumentVersionNode<T>;
    setSelectedDocumentVersions(prevVersionsNodes => [nextVersionNode, ...(prevVersionsNodes ?? [])]);

    const currentProject = await getOrCreateProject();

    try {
      const uploadedDocumentVersion = await importFn(currentProject.slug, nextVersionNode);
      if (!uploadedDocumentVersion._id) {
        throw uploadedDocumentVersion;
      }

      const nextVersion: DocumentVersion = {
        id: uploadedDocumentVersion._id!,
        last_modified: uploadedDocumentVersion.last_modified!,
        version_number: 0,
        filename: uploadedDocumentVersion.filename,
      };

      replaceTreeNodeOnNewVersionOfDocument(uploadedDocumentVersion, versionedFileId);
      setSelectedDocument(uploadedDocumentVersion);
      setSelectedDocumentVersions(
        prevVersionsNodes =>
          prevVersionsNodes?.map(prevVersionNode =>
            prevVersionNode.id === nextVersionNode.id
              ? {
                  id: uploadedDocumentVersion._id!,
                  lastModified: uploadedDocumentVersion.last_modified!,
                  filename: uploadedDocumentVersion.filename,
                  version: nextVersion,
                  file: undefined,
                  progress: 100,
                  status: STATUS.LOADED,
                }
              : prevVersionNode,
          ),
      );

      treeNodesByIdRef.current[uploadedDocumentVersion._id!] = cloneDeepWith(
        treeNodesByIdRef.current[uploadedDocumentVersion._id!],
        cloneCustomizer,
      ) as TreeFileSystemNode;
      treeNodesByIdRef.current[uploadedDocumentVersion._id!].versions?.push(nextVersion);
    } catch (error) {
      setSelectedDocumentVersions(
        prevVersionsNodes =>
          prevVersionsNodes?.map(prevVersionNode =>
            prevVersionNode.id === nextVersionNode.id ? { ...prevVersionNode, status: STATUS.ERROR } : prevVersionNode,
          ),
      );
      console.error('Error while uploading document version', error);
    }
  }

  const deleteNodes = useCallback(
    async (nodes: TreeFileSystemNode[]) => {
      const slug = getSlug();
      if (!slug) return;

      const idsToDelete = nodes.map(({ id }) => id);
      const { folderIds, fileIds } = getRelatedFilesAndFolders(treeNodesByIdRef.current, idsToDelete);

      fileIds.forEach(id => {
        if (!treeNodesByIdRef.current[id]) return;
        treeNodesByIdRef.current[id].status = STATUS.DELETING;
      });
      setTreeNodeByIdState(treeNodesByIdRef.current);

      const updatedFileSystem = await deleteFilesAndFolders({ fileIds, folderIds, projectSlug: slug, updateUrl: true });
      setDocuments(prevDocuments => prevDocuments.filter(doc => !fileIds.includes(doc._id!)));
      updateFileSystemTree(updatedFileSystem, documents);

      if (selectedDocument?._id && fileIds.includes(selectedDocument._id)) setSelectedDocument(null);
    },
    [selectedDocument, documents, updateFileSystemTree],
  );

  async function uploadIntoTree<T extends File | DriveFile>(
    files: T[],
    {
      importFn,
      parentId,
    }: { importFn: (slug: string, node: TreeFileSystemNode<T>) => Promise<DocumentMetadata | Document>; parentId?: string },
  ) {
    const currentProject = await getOrCreateProject();

    const customNamesMap = new Map<T, string>();
    const filesParentIdsMap = files.reduce<Map<T, string | undefined>>((acc, file) => {
      acc.set(file, parentId);
      return acc;
    }, new Map());

    // This logic mostly is to create folders structure on drop.
    if (isUploadFileArray(files)) {
      const parentChildren = Object.values(treeNodesByIdRef.current).filter(node => node.parentId === parentId);
      const pathsMap = files.reduce<Record<string, File[]>>((acc, file) => {
        // @ts-expect-error
        const path = file.path.split('/').slice(1, -1).join('/');
        acc[path] = acc[path] ?? [];
        acc[path].push(file as File);
        return acc;
      }, {});

      const foldersToCreate = new Map<string, File[]>();
      const existingFolders = new Map<TreeFileSystemNode, File[]>();
      const existingFiles = new Map<TreeFileSystemNode, File>();

      orderBy(Object.entries(pathsMap), ['0']).forEach(([path, droppedFiles]) => {
        const pathParts = path.split('/');
        const folderName = pathParts[pathParts.length - 1];
        if (folderName === '') {
          parentChildren.forEach(node => {
            if (node.type !== 'file') return;
            const existedFile = droppedFiles.find(file => removePdfExtension(file.name) === node.name);
            if (existedFile) {
              existingFiles.set(node, existedFile);
            }
          });
        } else {
          if (pathParts.length === 1) {
            const existedFolderNode = parentChildren.find(node => node.type === 'folder' && node.name === folderName);
            if (existedFolderNode) {
              existingFolders.set(existedFolderNode, droppedFiles);
            } else {
              foldersToCreate.set(path, droppedFiles);
            }
          } else {
            foldersToCreate.set(path, droppedFiles);
          }
        }
      });

      const result =
        (existingFolders.size === 0 && existingFiles.size === 0) ||
        (await showConfirmDialog({
          title: t('uploadFiles.overrideFilesConfirm.title'),
          buttons: [
            { label: t('uploadFiles.overrideFilesConfirm.replace'), value: 'replace' },
            { label: t('uploadFiles.overrideFilesConfirm.keepBoth'), value: 'keepBoth' },
          ],
        }));
      if (!result) return;

      const pickNewNotExistedName = (nameBefore: string) => {
        const isPdf = nameBefore.toLowerCase().endsWith('.pdf');
        let nextIndex = 1;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const hasChildWithName = parentChildren.some(child => {
            if ((isPdf && child.type === 'folder') || (!isPdf && child.type === 'file')) return false;
            return isPdf && child.type === 'file'
              ? removePdfExtension(child.name) === `${removePdfExtension(nameBefore)}_${nextIndex}`
              : child.name === `${nameBefore}_${nextIndex}`;
          });
          if (!hasChildWithName) break;
          nextIndex++;
        }

        return nameBefore.toLowerCase().endsWith('pdf')
          ? `${removePdfExtension(nameBefore)}_${nextIndex}.pdf`
          : `${nameBefore}_${nextIndex}`;
      };

      if (result === 'replace') {
        const nodesToDelete: TreeFileSystemNode[] = [];
        existingFiles.forEach((droppedFiles, node) => {
          nodesToDelete.push(node);
        });
        existingFolders.forEach((droppedFiles, node) => {
          foldersToCreate.set(node.name, droppedFiles);
          nodesToDelete.push(node);
        });
        await deleteNodes(nodesToDelete);
      } else {
        existingFiles.forEach(file => {
          customNamesMap.set(file as T, pickNewNotExistedName(file.name));
        });
        existingFolders.forEach((droppedFiles, node) => {
          const newName = pickNewNotExistedName(node.name);
          foldersToCreate.set(newName, droppedFiles);
          foldersToCreate.forEach((anotherDroppedFiles, path) => {
            if (path.startsWith(`${node.name}/`)) {
              foldersToCreate.set(path.replace(node.name, newName), anotherDroppedFiles);
              foldersToCreate.delete(path);
            }
          });
        });
      }

      const foldersNodesToCreate: Folder[] = [];
      const createdFoldersNodes: Record<string, TreeFileSystemNode> = {};
      orderBy(Array.from(foldersToCreate.entries()), ['0']).forEach(([path, folderFiles]) => {
        const matches = path.match(/((?<parentPath>.*)\/)?(?<currentFolderName>.*)/);
        if (!matches?.groups) return;

        const { parentPath, currentFolderName } = matches.groups;
        const folderParentId = parentPath ? createdFoldersNodes[parentPath].id : parentId;
        const folderNode = addFolderIntoTree({ name: currentFolderName, parentId: folderParentId });
        createdFoldersNodes[path] = folderNode;
        foldersNodesToCreate.push({
          id: folderNode.id,
          isFolder: true,
          name: currentFolderName,
          order: 0,
          parentId: folderParentId,
        });
        folderFiles.forEach(file => filesParentIdsMap.set(file as T, folderNode.id));
      });
      await createFoldersBatch(currentProject.slug, foldersNodesToCreate);
    }

    const newDocumentNodes = createDocumentNodesFromFiles(files, filesParentIdsMap, customNamesMap);
    newDocumentNodes.map(async node => {
      try {
        const response = await importFn(currentProject.slug, node);
        if (!response._id) {
          throw response;
        }

        addDocumentIntoTree({
          id: node.id,
          projectSlug: currentProject.slug,
          parentId: node.parentId,
          document: response as DocumentMetadata,
        });
      } catch (error) {
        console.error('Error while adding to the file system tree', error);
        treeNodesByIdRef.current[node.id].status = STATUS.ERROR;
        setTreeNodeByIdState(treeNodesByIdRef.current);
      }
    });
  }

  const importSharePointFiles = async (files: SherPointFile[], { versionedFileId }: ImportParams) => {
    if (versionedFileId) {
      return uploadIntoVersions(files, {
        versionedFileId,
        importFn: (slug, node) =>
          importSharepointFile(
            slug,
            {
              file_url: node.file['@content.downloadUrl'],
              file_name: node.file.name,
            },
            { versioned_file_id: versionedFileId },
          ) as Promise<DocumentMetadata>,
      });
    }

    return uploadIntoTree(files, {
      importFn: (slug, node) =>
        importSharepointFile(slug, {
          file_url: node.file['@content.downloadUrl'],
          file_name: node.file.name,
        }) as Promise<DocumentMetadata>,
    });
  };

  const importOneDriveFiles = async (files: OneDriveFile[], { versionedFileId }: ImportParams) => {
    if (versionedFileId) {
      return uploadIntoVersions(files, {
        versionedFileId,
        importFn: (slug, node) =>
          importMicrosoftFile(
            slug,
            {
              file_url: node.file['@microsoft.graph.downloadUrl'],
              file_name: node.file.name,
            },
            { versioned_file_id: versionedFileId },
          ) as Promise<DocumentMetadata>,
      });
    }

    return uploadIntoTree(files, {
      importFn: (slug, node) =>
        importMicrosoftFile(slug, {
          file_url: node.file['@microsoft.graph.downloadUrl'],
          file_name: node.file.name,
        }) as Promise<DocumentMetadata>,
    });
  };

  const importGoogleFiles = async (files: google.picker.DocumentObject[], { versionedFileId }: ImportParams) => {
    if (versionedFileId) {
      return uploadIntoVersions(files, {
        versionedFileId,
        importFn: (slug, node) =>
          importDocument(
            slug,
            {
              file_id: node.file.id,
              file_name: node.file.name!,
              mime_type: node.file.mimeType!,
            },
            { versioned_file_id: versionedFileId },
          ) as Promise<DocumentMetadata>,
      });
    }

    return uploadIntoTree(files, {
      importFn: (slug, node) =>
        importDocument(slug, {
          file_id: node.file.id,
          file_name: node.file.name!,
          mime_type: node.file.mimeType!,
        }) as Promise<DocumentMetadata>,
    });
  };

  const importAutodeskFiles = async (files: AutodeskFile[], { versionedFileId }: ImportParams) => {
    if (versionedFileId) {
      return uploadIntoVersions(files, {
        versionedFileId,
        importFn: (slug, node) =>
          importAutodeskFile(slug, node.file, { versioned_file_id: versionedFileId }) as Promise<DocumentMetadata>,
      });
    }

    return uploadIntoTree(files, {
      importFn: (slug, node) => importAutodeskFile(slug, node.file) as Promise<DocumentMetadata>,
    });
  };

  const importDriveFiles: ImportDriveFilesFn = async (files, { type, versionedFileId }) => {
    if (type === 'sharepoint') {
      return await importSharePointFiles(files as SherPointFile[], { versionedFileId });
    }
    if (type === 'onedrive') {
      return await importOneDriveFiles(files as OneDriveFile[], { versionedFileId });
    }
    if (type === 'google') {
      return await importGoogleFiles(files as google.picker.DocumentObject[], { versionedFileId });
    }
    if (type === 'autodesk') {
      return await importAutodeskFiles(files as AutodeskFile[], { versionedFileId });
    }
  };

  const uploadVersion = async (files: File[]) => {
    if (!selectedDocument) return;

    return uploadIntoVersions(files, {
      versionedFileId: selectedDocument._id!,
      importFn: async (slug, node) => {
        const action = await dispatch(
          uploadFileThunk({ id: node.id, slug, file: node.file, versionedFileId: selectedDocument._id! }),
        );
        if (action.payload instanceof AxiosError) {
          throw action.payload;
        }
        return action.payload as Document;
      },
    });
  };

  const uploadFiles = async (files: File[], parentId?: string) =>
    uploadIntoTree(files, {
      parentId,
      importFn: async (slug, node) => {
        const action = await dispatch(
          uploadFileThunk({ id: node.id, slug, file: node.file, parentId: node.parentId, order: node.order }),
        );
        if (action.payload instanceof AxiosError) {
          throw action.payload;
        }
        return action.payload as Document;
      },
    });

  const onFolderCreate: CreateHandler<TreeFileSystemNode> = async ({ parentId }) => {
    const currentProject = await getOrCreateProject();

    const folderNode = addFolderIntoTree({ parentId: parentId ?? undefined });
    await createFoldersBatch(currentProject.slug, [
      {
        id: folderNode.id,
        isFolder: true,
        name: folderNode.name,
        order: 0,
        parentId: folderNode.parentId,
      },
    ]);

    return { id: folderNode.id! };
  };

  const onDelete: DeleteHandler<TreeFileSystemNode> = useCallback(
    async ({ nodes }) => {
      const title =
        nodes.length > 1
          ? t('uploadFiles.confirmDelete.title')
          : nodes[0].data.type === 'folder'
            ? t('uploadFiles.confirmDelete.folderTitle')
            : t('uploadFiles.confirmDelete.fileTitle');

      const result = await showConfirmDialog({
        title,
        confirm: t('uploadFiles.confirmDelete.confirm'),
        cancel: t('uploadFiles.confirmDelete.cancel'),
      });

      if (result) await deleteNodes(nodes.map(({ data }) => data));
    },
    [deleteNodes],
  );

  const selectedNodesIds = useAppSelector(state => selectSelectedNodeIds(state, getSlug()));

  const onDeleteSelected = useCallback(async () => {
    const slug = getSlug();
    if (!slug) return;

    const nodes = selectedNodesIds.map(id => treeNodesByIdRef.current[id]);

    const title =
      nodes.length > 1
        ? t('uploadFiles.confirmDelete.title')
        : nodes[0].type === 'folder'
          ? t('uploadFiles.confirmDelete.folderTitle')
          : t('uploadFiles.confirmDelete.fileTitle');

    const result = await showConfirmDialog({
      title,
      confirm: t('uploadFiles.confirmDelete.confirm'),
      cancel: t('uploadFiles.confirmDelete.cancel'),
    });

    if (result) {
      dispatch(removeSelectedNodeIds({ slug, ids: selectedNodesIds }));
      await deleteNodes(nodes);
    }
  }, [deleteNodes, selectedNodesIds, showConfirmDialog, dispatch, t, getSlug, treeNodesByIdRef]);

  const onMove: MoveHandler<TreeFileSystemNode> = ({ dragIds, parentId, index }) => {
    const slug = getSlug();
    if (!slug) return;

    const nodeTreeClone = cloneDeepWith(treeNodesByIdRef.current, cloneCustomizer) as Record<string, TreeFileSystemNode>;
    let nextFileSystemRootNodes = getRootNodes(nodeTreeClone);
    let nextParentChildren = parentId ? nodeTreeClone[parentId].children! : nextFileSystemRootNodes;
    const insertPosition = index === 0 ? 'first' : index === nextParentChildren.length ? 'last' : 'after';
    const elementIdToInsertAfter = insertPosition === 'after' ? nextParentChildren[index]?.id : undefined;

    dragIds
      .reverse()
      .map(dragId => nodeTreeClone[dragId])
      .forEach(node => {
        const prevParentId = node.parentId;
        const isPutInSameFolder = prevParentId === (parentId ?? undefined);
        node.parentId = parentId ?? undefined;

        if (prevParentId && nodeTreeClone[prevParentId].children) {
          nodeTreeClone[prevParentId].children = nodeTreeClone[prevParentId]!.children!.filter(child => child.id !== node.id);
          nodeTreeClone[prevParentId].children.forEach((child, i) => (child.order = i));
          if (isPutInSameFolder) nextParentChildren = nodeTreeClone[prevParentId].children;
        } else {
          nextFileSystemRootNodes = nextFileSystemRootNodes.filter(child => child.id !== node.id);
          if (isPutInSameFolder) nextParentChildren = nextFileSystemRootNodes;
        }

        const indexToInsert =
          insertPosition === 'first'
            ? 0
            : insertPosition === 'last'
              ? nextParentChildren.length
              : nextParentChildren.findIndex(child => child.id === elementIdToInsertAfter);
        nextParentChildren.splice(indexToInsert, 0, node);
      });

    nextFileSystemRootNodes.forEach((node, i) => (node.order = i));
    nextParentChildren.forEach((node, i) => (node.order = i));
    treeNodesByIdRef.current = nodeTreeClone;
    setTreeNodeByIdState(nodeTreeClone);

    const nextFileSystem = convertFilSystemTreeToServerFileSystem(nodeTreeClone);
    updateFilesystemBatch(slug, nextFileSystem);
  };

  const renameFile = async ({ id, name }: RenameParams) => {
    const slug = getSlug();
    if (!slug) return;

    try {
      treeNodesByIdRef.current[id].name = name;
      setTreeNodeByIdState(treeNodesByIdRef.current);

      const updatedDocument = await renameDocument({ projectSlug: slug, documentId: id, name: `${name}.pdf` });

      setDocuments(prevDocuments =>
        prevDocuments.map(prevDocument =>
          id === prevDocument._id ? { ...prevDocument, document: updatedDocument } : prevDocument,
        ),
      );
      treeNodesByIdRef.current[id].name = removePdfExtension(updatedDocument.filename);
      treeNodesByIdRef.current[id].document = updatedDocument;
      setTreeNodeByIdState(treeNodesByIdRef.current);
    } catch (error) {
      enqueueSnackbar(t('uploadFiles.updateNameToasts.failed'), { variant: 'error' });
      console.error('Error while renaming document', error);
    }
  };

  const renameFolder = async ({ id, name }: RenameParams) => {
    const slug = getSlug();
    if (!slug) return;

    const folder = treeNodesByIdRef.current[id];
    folder.name = name;
    setTreeNodeByIdState(treeNodesByIdRef.current);

    updateFilesystemBatch(slug, convertFilSystemTreeToServerFileSystem({ [id]: folder }));
  };

  const onRename: RenameHandler<TreeFileSystemNode> = ({ id, name, node }) => {
    if (node.data.name === name) return;

    if (node.data.type === 'folder') {
      renameFolder({ id, name });
    } else {
      renameFile({ id, name });
    }
  };

  const onFileOpen = useCallback(
    (documentId: string) => {
      const slug = getSlug();
      if (!slug) return;

      setForceDropZoneView(false);

      if (PROJECT_ROUTER_IDS.includes(currentRouterId)) {
        setSearchParams(prevParams => {
          const nextParams = new URLSearchParams(prevParams);
          nextParams.set('documentId', documentId);
          return nextParams;
        });
      } else {
        navigate(toProjectHomepage({ projectSlug: slug, documentId }));
      }
    },
    [currentRouterId],
  );

  const onDocumentConvertToPage = useCallback(
    async (documentId: string) => {
      const slug = getSlug();
      if (!slug) return;

      const result = await showConfirmDialog({
        title: t('uploadFiles.convert.confirm.title'),
        description: (
          <Trans
            i18nKey="uploadFiles.convert.confirm.description"
            components={{ strong: <Box component="strong" sx={{ fontWeight: 'fontWeightBold' }} /> }}
          >
            {t('uploadFiles.convert.confirm.description')}
          </Trans>
        ),
      });
      if (!result) return;

      try {
        const { name, markdown } = await convertPdfToPages(slug, { document_id: documentId });
        const lexicalState = await convertMarkdownToLexicalState(markdown ?? '');

        const page = await automatePage(slug, {
          content: lexicalState.root.children,
          markdown,
          name,
          page_type: PageType.page,
          parentId: 'root',
        });

        const currentDocumentId = searchParams.get('documentId') ?? undefined;
        navigate(toProjectPages({ projectSlug: slug, documentId: currentDocumentId, pageId: page._id }));
        onClose?.();
      } catch (error) {
        console.error('An error occurred while converting file to a page');
        enqueueSnackbar(t('uploadFiles.convert.error'), { variant: 'error' });
      }
    },
    [onClose],
  );

  const onAddVersion = useCallback(
    (node: NodeApi<TreeFileSystemNode>) => {
      if (!node.data.document || !treeNodesByIdRef.current) return;
      setSelectedDocument(node.data.document);

      const versions = (treeNodesByIdRef.current[node.data.document._id!]?.versions ?? []).map(version => ({
        id: version.id,
        lastModified: version.last_modified!,
        version,
        file: undefined,
        filename: version.filename,
        status: STATUS.LOADED,
        progress: 100,
      }));
      const loadingVersions = Object.entries(uploadProgressState ?? [])
        .filter(([, { document, error, versionedFileId }]) => !document && !error && versionedFileId === node.data.document!._id)
        .map(([id, uploadState]) => ({
          id,
          lastModified: format(Date.now(), 'P'),
          version: { id, version_number: 0, filename: uploadState.name } satisfies DocumentVersion,
          file: undefined,
          filename: uploadState.name,
          status: STATUS.LOADING,
          progress: uploadState.progress,
        }));
      setSelectedDocumentVersions(versions.concat(loadingVersions));
    },
    [uploadProgressState],
  );

  const onVersionsClose = () => {
    setSelectedDocument(null);
    setSelectedDocumentVersions(undefined);
  };

  return {
    selectedDocument,
    selectedDocumentVersions,
    loadFileSystem,
    setDocuments,
    treeNodesByIdRef,
    fileSystemNodes: fileSystemRootNodes,
    importDriveFiles,
    uploadFiles,
    uploadVersion,
    onRename,
    onMove,
    onFolderCreate,
    onDelete,
    onFileOpen,
    onAddVersion,
    onVersionsClose,
    onDocumentConvertToPage,
    deleteDocumentVersion: onDeleteDocumentVersion,
    setAsMainDocumentVersion,
    onDeleteSelected,
  };
};
