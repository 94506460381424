import { InputAdornment, TextFieldProps, TextField, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@/components/Icon/Icon';

interface SearchInputProps extends TextFieldProps<'standard'> {}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(({ sx, ...props }, ref) => {
  const { t } = useTranslation('common');
  const { palette } = useTheme();

  return (
    <TextField
      placeholder={t('placeholders.searchPlaceholder')}
      {...props}
      inputRef={ref}
      type="search"
      sx={{
        width: '100%',
        ...sx,
        '.MuiInputBase-root': {
          border: `1px solid ${palette.grey[600]}`,
          borderRadius: 999,
          backgroundColor: 'transparent',
          px: 0.8,
          py: 0.1,
          '& .MuiInputAdornment-root': {
            mr: 0.6,
          },
          '& .MuiInputBase-input': {
            p: 0,
            fontSize: 'body2.fontSize',
            '&::placeholder': { color: palette.grey[800] },
          },
          // @ts-expect-error
          ...(sx ? sx['.MuiInputBase-root'] : undefined),
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{ color: palette.controls.text }} position="start">
            <Icon name="search" fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
});

export default SearchInput;
