import { TreeFileSystemNode } from '@/types';
import { orderBy } from 'lodash';

export const sortTreeNodes = (
  nodes: TreeFileSystemNode[],
  sortBy: string | undefined = 'order',
  sortOrder: string | undefined = 'asc',
): TreeFileSystemNode[] => {
  const sortedNodes = orderBy(nodes, ['type', sortBy], ['desc', sortOrder as boolean | 'asc' | 'desc']);
  return sortedNodes.map(
    (node): TreeFileSystemNode => ({ ...node, children: node.children?.length ? sortTreeNodes([...node.children]) : [] }),
  );
};
