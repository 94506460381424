import { FC } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { Global } from '@emotion/react';
import { ROUTER_IDS, toProjectHomepage } from '@/services/linker';
import useQueryString from '@/hooks/useQueryString';
import { combineProviders } from '@/utils/combineComponents';
import useRouteId from '@/hooks/useRouteId';
import AnnotationsProvider from '@/views/Project/providers/AnnotationsProvider';
import DocumentsProvider from '@/views/Project/providers/DocumentsProvider';
import LayoutStateProvider from '@/views/Project/providers/LayoutStateProvider';
import ProjectProvider from '@/views/Project/providers/ProjectProvider';
import QueriesProvider from '@/views/Project/providers/QueriesProvider';
import EditorContextProvider from '@/containers/DocumentEditor/providers/EditorContextProvider';
import useHeaderHeight from '@/hooks/useHeaderHeight';
import Header from '@/views/Project/views/Header/Header';
import { Box } from '@mui/material';
import { useProjectFormDialog } from '@/hooks/useProjectFormDialog';
import { useUrlAction } from '@/views/Project/views/Homepage/utils/useUrlAction';
import { HomepageActions } from '@/views/Project/views/Header/types';
import { ChecklistsManagerDialogProvider } from '@/views/Project/components/ChecklistsManager/context/ChecklistsManagerDialogProvider';
import { PopupDocumentsInProgressProvider } from '@/views/Project/components/PopupDocumentsInProgress/PopupDocumentsInProgressProvider';

const Providers = combineProviders([
  ProjectProvider,
  DocumentsProvider,
  QueriesProvider,
  AnnotationsProvider,
  LayoutStateProvider,
  EditorContextProvider,
  PopupDocumentsInProgressProvider,
  ChecklistsManagerDialogProvider,
]);

const ProjectPageContainer: FC = () => {
  const { projectSlug } = useParams();
  const { documentId } = useQueryString();
  const routeId = useRouteId();
  const headerHeight = useHeaderHeight();
  const { showProjectFormDialog } = useProjectFormDialog();

  if (!projectSlug) throw 'Missing projectSlug';

  useUrlAction(HomepageActions.OPEN_EDIT_PROJECT_DIALOG, () => showProjectFormDialog(projectSlug));

  if (routeId === ROUTER_IDS.PROJECT) {
    return <Navigate replace to={toProjectHomepage({ projectSlug, documentId })} />;
  }

  return (
    <Providers>
      <Global styles={{ body: { overflow: 'hidden' } }} />
      <Box
        sx={{
          position: 'sticky',
          top: `${headerHeight}px`,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <Outlet context={{ sx: { flex: '1 1 auto' } }} />
      </Box>
    </Providers>
  );
};
export default ProjectPageContainer;
