import { Checkbox, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@/components/Icon/Icon';
import SortingArrows from '@/views/Projects/components/SortingArrows';
import { SORT_DIRECTION } from '@/enums/sort';
import { useSearchParams } from 'react-router-dom';
import { FC, MutableRefObject, useMemo } from 'react';
import { TreeFileSystemNode } from '@/types';
import { useAppDispatch, useAppSelector } from '@/store';
import { selectSelectedNodeIds, toggleSelectedNode } from '@/store/fileTreeSlice';

type FilesTreeHeaderProps = {
  slug?: string;
  fileSystemNodes: TreeFileSystemNode[];
  treeNodesByIdRef: MutableRefObject<Record<string, TreeFileSystemNode>>;
};

const SORT_DIRECTIONS = [SORT_DIRECTION.DESC, SORT_DIRECTION.ASC, SORT_DIRECTION.NONE];

export const FilesTreeHeader: FC<FilesTreeHeaderProps> = ({ slug, fileSystemNodes, treeNodesByIdRef }) => {
  const { palette } = useTheme();
  const { t } = useTranslation('projectUpdate');
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedNodesIds = useAppSelector(state => selectSelectedNodeIds(state, slug));
  const isSelectedAll = useMemo(
    () => Object.keys(treeNodesByIdRef.current).length === selectedNodesIds.length,
    [selectedNodesIds, treeNodesByIdRef],
  );

  const sortDir = (searchParams.get('sortDir') as SORT_DIRECTION | null) ?? SORT_DIRECTION.NONE;
  const sortBy = searchParams.get('sortBy') ?? undefined;

  const onSortBy = (key: string) => () => {
    const updatedParams = new URLSearchParams(searchParams);

    if (sortBy === key) {
      const currentIndex = SORT_DIRECTIONS.findIndex(dir => dir === sortDir);
      const nextSortDir = SORT_DIRECTIONS[currentIndex + 1] ?? SORT_DIRECTIONS[0];
      updatedParams.set('sortDir', nextSortDir);
      if (nextSortDir === SORT_DIRECTION.NONE) {
        updatedParams.delete('sortBy');
        updatedParams.delete('sortDir');
      }
    } else {
      updatedParams.set('sortDir', SORT_DIRECTION.DESC);
      updatedParams.set('sortBy', key);
    }

    setSearchParams(updatedParams);
  };

  const onSelectAll = () =>
    slug && fileSystemNodes.forEach(node => dispatch(toggleSelectedNode({ slug, node, forceSelected: isSelectedAll })));

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: '100%',
        px: 3,
        gap: 1,
        minHeight: 50,
        borderBottom: `1px solid ${palette.secondary.main}`,
      }}
    >
      <Checkbox
        checked={isSelectedAll}
        onChange={onSelectAll}
        size="small"
        sx={{ p: 0.75, mr: -1 }}
        icon={<Icon name="unCheckSquare" fontSize="medium" htmlColor={palette.grey[600]} />}
        checkedIcon={<Icon name="checkSquare1" fontSize="medium" htmlColor={palette.grey[900]} />}
      />

      <Stack direction="row" alignItems="center" flex={1} sx={{ width: '100%', px: 4, pr: 1, py: 1 }}>
        <Typography variant="body2" fontWeight={700} textTransform="uppercase" color={palette.grey[700]}>
          {t('filesTable.name')}
        </Typography>
        <Tooltip arrow title={t(`filesTable.tooltips.sort.name`)} placement="top">
          <IconButton size="small" onClick={onSortBy('name')}>
            <SortingArrows order={sortBy === 'name' ? sortDir : SORT_DIRECTION.NONE} htmlColor={palette.text.primary} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ width: 80, py: 1 }}>
        <Typography variant="body2" fontWeight={700} textTransform="uppercase" color={palette.grey[700]}>
          {t('filesTable.type')}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ width: 80, py: 1 }}>
        <Typography variant="body2" fontWeight={700} textTransform="uppercase" color={palette.grey[700]}>
          {t('filesTable.version')}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ width: 100, py: 1 }}>
        <Typography variant="body2" fontWeight={700} textTransform="uppercase" color={palette.grey[700]}>
          {t('filesTable.created')}
        </Typography>
        <Tooltip arrow title={t(`filesTable.tooltips.sort.created`)} placement="top">
          <IconButton size="small" onClick={onSortBy('document.last_modified')}>
            <SortingArrows order={sortBy === 'last_modified' ? sortDir : SORT_DIRECTION.NONE} htmlColor={palette.text.primary} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
