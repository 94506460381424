import { useGetLearningCenterItems, type LearningCenterItemCategory } from '@/api/generated';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';

export const useLearningCenter = () => {
  const [selectedCategory, setSelectedCategory] = useState<LearningCenterItemCategory | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading } = useGetLearningCenterItems();

  const setSearchQueryDebounced = useMemo(() => debounce((value: string) => setSearchQuery(value), 300), [setSearchQuery]);

  const categories = useMemo(
    () =>
      data?.reduce((acc, item) => {
        item.category?.forEach(category => {
          if (!acc.includes(category)) {
            acc.push(category);
          }
        });
        return acc;
      }, [] as LearningCenterItemCategory[]),
    [data],
  );

  const filteredLearningCenterItems = useMemo(
    () => (selectedCategory ? data?.filter(item => item.category?.includes(selectedCategory)) : data),
    [data, selectedCategory],
  );

  const filteredLearningCenterItemsBySearch = useMemo(
    () =>
      searchQuery.length > 0
        ? filteredLearningCenterItems?.filter(
            item =>
              item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.description?.toLowerCase().includes(searchQuery.toLowerCase()),
          )
        : filteredLearningCenterItems,
    [filteredLearningCenterItems, searchQuery],
  );

  return {
    learningCenterItems: filteredLearningCenterItemsBySearch,
    setSearchQuery: setSearchQueryDebounced,
    isLoading,
    selectedCategory,
    setSelectedCategory,
    categories,
    searchQuery,
  };
};
