import {
  MeasurementPrecision,
  MeasurementScaleUnitFrom,
  MeasurementScaleUnitTo,
  MeasurementValueConfiguration,
} from '@nutrient-sdk/viewer';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import DialogCloseButton from '@/components/Dialog/DialogCloseButton';
import Dialog from '@/components/Dialog/Dialog';
import TextInput from '@/components/TextInput/TextInput';
import DialogHeader from '@/components/Dialog/DialogHeader';
import SelectControl from '@/components/SelectControl';
import {
  allPrecisionOptions,
  intPrecisionOptions,
  intPrecisions,
  intPrecisionUnits,
  unitFromOptions,
  unitToOptions,
} from '@/containers/DocumentEditor/utils/constants';
import { replaceFractionWithDecimal } from '@/containers/DocumentEditor/utils/replaceFractionWithDecimal';

export interface ScaleSelectDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onAddScaleConfig: (config: MeasurementValueConfiguration) => void;
}

const validationSchema = z.object({
  precision: z.enum([
    MeasurementPrecision.WHOLE,
    MeasurementPrecision.ONE,
    MeasurementPrecision.TWO,
    MeasurementPrecision.THREE,
    MeasurementPrecision.FOUR,
    MeasurementPrecision.HALVES,
    MeasurementPrecision.QUARTERS,
    MeasurementPrecision.EIGHTHS,
    MeasurementPrecision.SIXTEENTHS,
  ]),
  scale: z.object({
    fromValue: z.coerce
      .number()
      .positive()
      .transform(Number)
      .or(
        z
          .string()
          .regex(/(\d+)\/(\d+)/)
          .transform(replaceFractionWithDecimal),
      ),
    toValue: z.coerce
      .number()
      .positive()
      .transform(Number)
      .or(
        z
          .string()
          .regex(/(\d+)\/(\d+)/)
          .transform(replaceFractionWithDecimal),
      ),
    unitFrom: z.enum([
      MeasurementScaleUnitFrom.INCHES,
      MeasurementScaleUnitFrom.MILLIMETERS,
      MeasurementScaleUnitFrom.CENTIMETERS,
      MeasurementScaleUnitFrom.POINTS,
    ]),
    unitTo: z.enum([
      MeasurementScaleUnitTo.INCHES,
      MeasurementScaleUnitTo.MILLIMETERS,
      MeasurementScaleUnitTo.CENTIMETERS,
      MeasurementScaleUnitTo.POINTS,
      MeasurementScaleUnitTo.FEET,
      MeasurementScaleUnitTo.METERS,
      MeasurementScaleUnitTo.YARDS,
      MeasurementScaleUnitTo.KILOMETERS,
      MeasurementScaleUnitTo.MILES,
    ]),
  }),
});

const ScaleSelectDialog: FC<ScaleSelectDialogProps> = ({ isOpen, onClose, onAddScaleConfig }) => {
  const { t } = useTranslation('editor');
  const { control, handleSubmit, watch, getValues, setValue } = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      precision: 'threeDp',
      scale: { fromValue: 1, toValue: 1, unitFrom: 'in', unitTo: 'in' },
    },
  });

  const unitTo = watch('scale.unitTo');
  const precisionOptions = intPrecisionUnits.includes(unitTo) ? intPrecisionOptions : allPrecisionOptions;

  useEffect(() => {
    if (intPrecisionUnits.includes(unitTo) && !(intPrecisions as string[]).includes(getValues('precision'))) {
      setValue('precision', 'threeDp');
    }
  }, [unitTo]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      width="auto"
      component="form"
      data-testid="scale-select-dialog"
      onSubmit={handleSubmit(onAddScaleConfig)}
    >
      <DialogCloseButton positioned onClick={onClose} />
      <DialogHeader title={t('scalesSelectDialog.title')} />
      <Stack gap={2}>
        <Stack direction="row" gap={2}>
          <TextInput
            label={t('scalesSelectDialog.fromValueLabel')}
            name="scale.fromValue"
            id="scale-fromValue"
            control={control}
            translate="editor"
            width={120}
          />
          <SelectControl
            options={unitFromOptions}
            label={t('scalesSelectDialog.unitLabel')}
            name="scale.unitFrom"
            id="scale-unitFrom"
            control={control}
            disableClearable
            sx={{ width: 100 }}
          />
        </Stack>

        <Stack direction="row" gap={2}>
          <TextInput
            label={t('scalesSelectDialog.toValueLabel')}
            name="scale.toValue"
            id="scale-toValue"
            control={control}
            translate="editor"
            width={120}
          />
          <SelectControl
            options={unitToOptions}
            label={t('scalesSelectDialog.unitLabel')}
            name="scale.unitTo"
            id="scale-unitTo"
            control={control}
            disableClearable
            sx={{ width: 100 }}
          />
        </Stack>

        <SelectControl
          options={precisionOptions}
          label={t('scalesSelectDialog.precisionLabel')}
          name="precision"
          id="scale-precision"
          control={control}
          disableClearable
          sx={{ width: 120 }}
        />

        <Button variant="contained" type="submit" data-testid="scale-submit">
          {t('scalesSelectDialog.submit')}
        </Button>
      </Stack>
    </Dialog>
  );
};

export default ScaleSelectDialog;
