import { forwardRef, ReactNode } from 'react';
import { MenuList, Paper, SxProps, useTheme } from '@mui/material';

interface AiMenuProps {
  children: ReactNode;
  sx?: SxProps;
}

const AiMenu = forwardRef<HTMLDivElement, AiMenuProps>(({ children, sx }, ref) => {
  const { palette } = useTheme();

  return (
    <Paper ref={ref} sx={{ alignSelf: 'flex-start', minWidth: 200, overflow: 'hidden', boxShadow: 2, ...sx }}>
      <MenuList
        sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          '.MuiMenuItem-root': {
            py: 2,
            '&:not(:last-child)': { borderBottom: `1px solid ${palette.custom.separationLine}` },
          },
        }}
      >
        {children}
      </MenuList>
    </Paper>
  );
});

export default AiMenu;
