import { Components, ComponentsVariants, Theme } from '@mui/material/styles';
import { setOfStylesForSize } from './MuiButton';

const sizeVariants: ComponentsVariants<Theme>['MuiIconButton'] = [
  {
    props: { size: '2xsmall' },
    style: setOfStylesForSize['2xsmall'],
  },
  {
    props: { size: '3xsmall' },
    style: setOfStylesForSize['3xsmall'],
  },
  {
    props: { size: 'xsmall' },
    style: setOfStylesForSize['xsmall'],
  },
];

const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  variants: [
    ...sizeVariants,
    {
      props: { color: 'primary' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.dark,
        '&.MuiIconButton-colorSecondary': {
          boxShadow: theme.shadows[3],
          '&.MuiIconButton-sizeLarge': { padding: theme.spacing(2) },
          '&:hover': {
            color: theme.palette.text?.primary,
            boxShadow: theme.shadows[1],
          },
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          boxShadow: theme.shadows[5],
        },
      }),
    },
    {
      props: { color: 'secondary' },
      style: ({ theme }) => ({
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
          boxShadow: 'none',
        },
      }),
    },
    {
      props: { size: '3xsmall', color: 'secondary' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        '.MuiButton-icon': {
          fill: theme.palette.grey[900],
          color: theme.palette.grey[900],
        },
      }),
    },
  ],
};
export default MuiIconButton;
