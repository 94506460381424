import { Instance, ViewState } from '@nutrient-sdk/viewer';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

type Params = {
  instance?: Instance;
  page: number;
  onPage: (page: number) => void;
};

export const usePageChangeEvent = ({ instance, page, onPage }: Params) => {
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!instance) return;
    const isChangePage = page < instance.totalPageCount && page !== instance.viewState.currentPageIndex;
    isChangePage && instance.setViewState(viewState => viewState.set('currentPageIndex', page));

    const onPagesChange = (viewState: ViewState) => {
      onPage(viewState.currentPageIndex);
      setSearchParams(
        prevParams => {
          const nextParams = new URLSearchParams(prevParams);
          nextParams.set('page', (viewState.currentPageIndex + 1).toString());
          return nextParams;
        },
        { replace: true },
      );
    };
    instance.addEventListener('viewState.change', onPagesChange);
    return () => instance.removeEventListener('viewState.change', onPagesChange);
  }, [instance, page, onPage]);
};
