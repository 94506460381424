import ConfirmThumbButton from '@/components/Pages/components/ConfirmThumbButton';
import SliderThumb from '@/components/Pages/components/SliderThumb';
import { ClickAwayListener, Slider, Tooltip, useTheme } from '@mui/material';
import { FC } from 'react';
import { PageActionType } from '@/api/generated';
import { useTranslation } from 'react-i18next';

interface SliderWithConfirmProps {
  isConfirmButtonShowed: boolean;
  sliderValue: number;
  onClickAway: () => void;
  onValueChangeCommitted: () => void;
  onValueChange: (event: Event, newValue: number | number[]) => void;
  isSliderActionDataLoading: boolean;
  onConfirmSelection: () => Promise<void>;
  type: PageActionType;
}

const SliderWithConfirm: FC<SliderWithConfirmProps> = ({
  isConfirmButtonShowed,
  sliderValue,
  onValueChangeCommitted,
  onValueChange,
  isSliderActionDataLoading,
  onConfirmSelection,
  onClickAway,
  type,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation('project');

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Slider
        orientation="vertical"
        step={1}
        defaultValue={0}
        onChange={onValueChange}
        onChangeCommitted={onValueChangeCommitted}
        value={sliderValue}
        valueLabelDisplay="auto"
        marks
        min={-4}
        max={4}
        sx={{
          '&.MuiSlider-root': {
            height: '100%',
            p: 0,
            color: palette.text.secondary,
            '& .MuiSlider-mark': { height: 4, width: 4, borderRadius: 999, backgroundColor: palette.grey[500] },
            '& .MuiSlider-rail, & .MuiSlider-track': { backgroundColor: 'transparent', border: 'none' },
            '& .MuiSlider-thumb': {
              height: 30,
              width: 30,
              input: { display: isConfirmButtonShowed ? 'none' : 'block' },
            },
          },
        }}
        slots={{
          thumb: props =>
            isConfirmButtonShowed ? (
              <ConfirmThumbButton {...props} isLoading={isSliderActionDataLoading} onConfirm={onConfirmSelection} />
            ) : (
              <SliderThumb {...props} type={type} />
            ),
          valueLabel: props => (
            <Tooltip
              arrow
              title={
                type === PageActionType.change_page_length
                  ? t(`editor.marksLabel.pageLength.${props.value}`)
                  : t(`editor.marksLabel.writingLevel.${props.value}`)
              }
              placement="right"
            >
              {props.children}
            </Tooltip>
          ),
        }}
      />
    </ClickAwayListener>
  );
};

export default SliderWithConfirm;
