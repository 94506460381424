import { SliderThumb, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { FC, HTMLAttributes } from 'react';
import { LoadingButton } from '@mui/lab';

interface ConfirmThumbButtonProps extends HTMLAttributes<unknown> {
  onConfirm: () => Promise<void>;
  isLoading: boolean;
}

const ConfirmThumbButton: FC<ConfirmThumbButtonProps> = ({ children, onConfirm, isLoading, ...other }) => {
  const { palette } = useTheme();

  return (
    <SliderThumb {...other}>
      {children}
      <LoadingButton
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        sx={{
          position: 'absolute',
          zIndex: 1,
          color: palette.text.secondary,
          boxShadow: 2,
          backgroundColor: palette.background.paper,
          borderRadius: '99999px',
          width: 30,
          height: 30,
          minWidth: 'auto',
          p: 1,
          '&:hover': { backgroundColor: palette.primary.light },
        }}
        onClick={onConfirm}
      >
        <Icon name="checked" fontSize="small" />
      </LoadingButton>
    </SliderThumb>
  );
};

export default ConfirmThumbButton;
